import classnames from 'classnames';

import { RELIC_SET_TYPES } from '../../../constants.js';

import './RelicSetTypeSelect.scss';

function RelicSetTypeSelect({
    selectedRelicSetType,
    setSelectedRelicSetType,
    search,
    setSearch,
    setLoading
}) {
    const onClick = type => {
        setSearch('');
        if (setLoading) {
            setLoading(true);
        }
        setSelectedRelicSetType(type);
    }

    return (
        <div className="relic-set-type-select">
            <div className="relic-set-type-select-options">
                <button
                    className={classnames({
                        'relic-set-type-select-option': true,
                        'relic-set-type-select-option-selected': selectedRelicSetType === RELIC_SET_TYPES.RELICS
                    })}
                    onClick={() => { onClick(RELIC_SET_TYPES.RELICS) }}
                >
                    {RELIC_SET_TYPES.RELICS}
                </button>
                <button
                    className={classnames({
                        'relic-set-type-select-option': true,
                        'relic-set-type-select-option-last': true,
                        'relic-set-type-select-option-selected': selectedRelicSetType === RELIC_SET_TYPES.PLANARS
                    })}
                    onClick={() => { onClick(RELIC_SET_TYPES.PLANARS) }}
                >
                    {RELIC_SET_TYPES.PLANARS}
                </button>
            </div>
            <div className="relic-search-input-wrapper">
                <input
                    className="relic-search-input"
                    type="search"
                    placeholder="Search..."
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </div>
        </div>
    );
}

export default RelicSetTypeSelect;
