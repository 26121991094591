import BREAK from './relics_break.webp';
import DOT from './relics_dot.webp';
import FIRE from './relics_fire.webp';
import FOLLOWUP from './relics_followup.webp';
import GUARD from './relics_guard.webp';
import HEAL from './relics_heal.webp';
import HP from './relics_hp.webp';
import ICE from './relics_ice.webp';
import IMAGINARY from './relics_imaginary.webp';
import MUSKETEER from './relics_musketeer.webp';
import PHYSICAL from './relics_physical.webp';
import QUANTUM from './relics_quantum.webp';
import SHIELD from './relics_shield.webp';
import SPEED from './relics_speed.webp';
import LIGHTNING from './relics_lightning.webp';
import WIND from './relics_wind.webp';
import PIONEER from './relics_pioneer.webp';
import WATCHMAKER from './relics_watchmaker.webp';
import CAVALRY from './relics_cavalry.webp';
import VALOROUS from './relics_valorous.webp';

import BREAK_HEAD from './relics_break_head.webp';
import DOT_HEAD from './relics_dot_head.webp';
import FIRE_HEAD from './relics_fire_head.webp';
import FOLLOWUP_HEAD from './relics_followup_head.webp';
import GUARD_HEAD from './relics_guard_head.webp';
import HEAL_HEAD from './relics_heal_head.webp';
import HP_HEAD from './relics_hp_head.webp';
import ICE_HEAD from './relics_ice_head.webp';
import IMAGINARY_HEAD from './relics_imaginary_head.webp';
import MUSKETEER_HEAD from './relics_musketeer_head.webp';
import PHYSICAL_HEAD from './relics_physical_head.webp';
import QUANTUM_HEAD from './relics_quantum_head.webp';
import SHIELD_HEAD from './relics_shield_head.webp';
import SPEED_HEAD from './relics_speed_head.webp';
import LIGHTNING_HEAD from './relics_lightning_head.webp';
import WIND_HEAD from './relics_wind_head.webp';
import PIONEER_HEAD from './relics_pioneer_head.webp';
import WATCHMAKER_HEAD from './relics_watchmaker_head.webp';
import CAVALRY_HEAD from './relics_cavalry_head.webp';
import VALOROUS_HEAD from './relics_valorous_head.webp';

import BREAK_HANDS from './relics_break_hands.webp';
import DOT_HANDS from './relics_dot_hands.webp';
import FIRE_HANDS from './relics_fire_hands.webp';
import FOLLOWUP_HANDS from './relics_followup_hands.webp';
import GUARD_HANDS from './relics_guard_hands.webp';
import HEAL_HANDS from './relics_heal_hands.webp';
import HP_HANDS from './relics_hp_hands.webp';
import ICE_HANDS from './relics_ice_hands.webp';
import IMAGINARY_HANDS from './relics_imaginary_hands.webp';
import MUSKETEER_HANDS from './relics_musketeer_hands.webp';
import PHYSICAL_HANDS from './relics_physical_hands.webp';
import QUANTUM_HANDS from './relics_quantum_hands.webp';
import SHIELD_HANDS from './relics_shield_hands.webp';
import SPEED_HANDS from './relics_speed_hands.webp';
import LIGHTNING_HANDS from './relics_lightning_hands.webp';
import WIND_HANDS from './relics_wind_hands.webp';
import PIONEER_HANDS from './relics_pioneer_hands.webp';
import WATCHMAKER_HANDS from './relics_watchmaker_hands.webp';
import CAVALRY_HANDS from './relics_cavalry_hands.webp';
import VALOROUS_HANDS from './relics_valorous_hands.webp';

import BREAK_BODY from './relics_break_body.webp';
import DOT_BODY from './relics_dot_body.webp';
import FIRE_BODY from './relics_fire_body.webp';
import FOLLOWUP_BODY from './relics_followup_body.webp';
import GUARD_BODY from './relics_guard_body.webp';
import HEAL_BODY from './relics_heal_body.webp';
import HP_BODY from './relics_hp_body.webp';
import ICE_BODY from './relics_ice_body.webp';
import IMAGINARY_BODY from './relics_imaginary_body.webp';
import MUSKETEER_BODY from './relics_musketeer_body.webp';
import PHYSICAL_BODY from './relics_physical_body.webp';
import QUANTUM_BODY from './relics_quantum_body.webp';
import SHIELD_BODY from './relics_shield_body.webp';
import SPEED_BODY from './relics_speed_body.webp';
import LIGHTNING_BODY from './relics_lightning_body.webp';
import WIND_BODY from './relics_wind_body.webp';
import PIONEER_BODY from './relics_pioneer_body.webp';
import WATCHMAKER_BODY from './relics_watchmaker_body.webp';
import CAVALRY_BODY from './relics_cavalry_body.webp';
import VALOROUS_BODY from './relics_valorous_body.webp';

import BREAK_BOOTS from './relics_break_boots.webp';
import DOT_BOOTS from './relics_dot_boots.webp';
import FIRE_BOOTS from './relics_fire_boots.webp';
import FOLLOWUP_BOOTS from './relics_followup_boots.webp';
import GUARD_BOOTS from './relics_guard_boots.webp';
import HEAL_BOOTS from './relics_heal_boots.webp';
import HP_BOOTS from './relics_hp_boots.webp';
import ICE_BOOTS from './relics_ice_boots.webp';
import IMAGINARY_BOOTS from './relics_imaginary_boots.webp';
import MUSKETEER_BOOTS from './relics_musketeer_boots.webp';
import PHYSICAL_BOOTS from './relics_physical_boots.webp';
import QUANTUM_BOOTS from './relics_quantum_boots.webp';
import SHIELD_BOOTS from './relics_shield_boots.webp';
import SPEED_BOOTS from './relics_speed_boots.webp';
import LIGHTNING_BOOTS from './relics_lightning_boots.webp';
import WIND_BOOTS from './relics_wind_boots.webp';
import PIONEER_BOOTS from './relics_pioneer_boots.webp';
import WATCHMAKER_BOOTS from './relics_watchmaker_boots.webp';
import CAVALRY_BOOTS from './relics_cavalry_boots.webp';
import VALOROUS_BOOTS from './relics_valorous_boots.webp';

export const IMG_RELICS = {
    BREAK,
    DOT,
    FIRE,
    FOLLOWUP,
    GUARD,
    HEAL,
    HP,
    ICE,
    IMAGINARY,
    MUSKETEER,
    PHYSICAL,
    QUANTUM,
    SHIELD,
    SPEED,
    LIGHTNING,
    WIND,
    PIONEER,
    WATCHMAKER,
    CAVALRY,
    VALOROUS,
};

export const IMG_RELICS_HEAD = {
    BREAK: BREAK_HEAD,
    DOT: DOT_HEAD,
    FIRE: FIRE_HEAD,
    FOLLOWUP: FOLLOWUP_HEAD,
    GUARD: GUARD_HEAD,
    HEAL: HEAL_HEAD,
    HP: HP_HEAD,
    ICE: ICE_HEAD,
    IMAGINARY: IMAGINARY_HEAD,
    MUSKETEER: MUSKETEER_HEAD,
    PHYSICAL: PHYSICAL_HEAD,
    QUANTUM: QUANTUM_HEAD,
    SHIELD: SHIELD_HEAD,
    SPEED: SPEED_HEAD,
    LIGHTNING: LIGHTNING_HEAD,
    WIND: WIND_HEAD,
    PIONEER: PIONEER_HEAD,
    WATCHMAKER: WATCHMAKER_HEAD,
    CAVALRY: CAVALRY_HEAD,
    VALOROUS: VALOROUS_HEAD,
}

export const IMG_RELICS_HANDS = {
    BREAK: BREAK_HANDS,
    DOT: DOT_HANDS,
    FIRE: FIRE_HANDS,
    FOLLOWUP: FOLLOWUP_HANDS,
    GUARD: GUARD_HANDS,
    HEAL: HEAL_HANDS,
    HP: HP_HANDS,
    ICE: ICE_HANDS,
    IMAGINARY: IMAGINARY_HANDS,
    MUSKETEER: MUSKETEER_HANDS,
    PHYSICAL: PHYSICAL_HANDS,
    QUANTUM: QUANTUM_HANDS,
    SHIELD: SHIELD_HANDS,
    SPEED: SPEED_HANDS,
    LIGHTNING: LIGHTNING_HANDS,
    WIND: WIND_HANDS,
    PIONEER: PIONEER_HANDS,
    WATCHMAKER: WATCHMAKER_HANDS,
    CAVALRY: CAVALRY_HANDS,
    VALOROUS: VALOROUS_HANDS,
}

export const IMG_RELICS_BODY = {
    BREAK: BREAK_BODY,
    DOT: DOT_BODY,
    FIRE: FIRE_BODY,
    FOLLOWUP: FOLLOWUP_BODY,
    GUARD: GUARD_BODY,
    HEAL: HEAL_BODY,
    HP: HP_BODY,
    ICE: ICE_BODY,
    IMAGINARY: IMAGINARY_BODY,
    MUSKETEER: MUSKETEER_BODY,
    PHYSICAL: PHYSICAL_BODY,
    QUANTUM: QUANTUM_BODY,
    SHIELD: SHIELD_BODY,
    SPEED: SPEED_BODY,
    LIGHTNING: LIGHTNING_BODY,
    WIND: WIND_BODY,
    PIONEER: PIONEER_BODY,
    WATCHMAKER: WATCHMAKER_BODY,
    CAVALRY: CAVALRY_BODY,
    VALOROUS: VALOROUS_BODY,
}

export const IMG_RELICS_BOOTS = {
    BREAK: BREAK_BOOTS,
    DOT: DOT_BOOTS,
    FIRE: FIRE_BOOTS,
    FOLLOWUP: FOLLOWUP_BOOTS,
    GUARD: GUARD_BOOTS,
    HEAL: HEAL_BOOTS,
    HP: HP_BOOTS,
    ICE: ICE_BOOTS,
    IMAGINARY: IMAGINARY_BOOTS,
    MUSKETEER: MUSKETEER_BOOTS,
    PHYSICAL: PHYSICAL_BOOTS,
    QUANTUM: QUANTUM_BOOTS,
    SHIELD: SHIELD_BOOTS,
    SPEED: SPEED_BOOTS,
    LIGHTNING: LIGHTNING_BOOTS,
    WIND: WIND_BOOTS,
    PIONEER: PIONEER_BOOTS,
    WATCHMAKER: WATCHMAKER_BOOTS,
    CAVALRY: CAVALRY_BOOTS,
    VALOROUS: VALOROUS_BOOTS,
}
