import React, { useEffect, useState } from 'react';

import {
    RELIC_SET_TYPES,
    RELIC_SETS,
    PLANAR_SETS,
    RELIC_SLOTS,
    RELIC_STATS,
    PAGES
} from '../../constants.js';
import { getRelicMainStatsBySlot } from '../../helpers.js';
import { generateRelicResults } from './helpers.js';

import RelicSetTypeSelect from './RelicSetTypeSelect';
import RelicSetSelect from './RelicSetSelect';
import RelicPieceSelect from './RelicPieceSelect';
import RelicStatInput from './RelicStatInput';
import Results from './Results';

import './RelicSalvage.scss';

function RelicSalvage() {
    const [selectedRelicSetType, setSelectedRelicSetType] = useState(RELIC_SET_TYPES.RELICS);
    const [selectedRelicSet, setSelectedRelicSet] = useState(RELIC_SETS.ICE);
    const [selectedRelicSlot, setSelectedRelicSlot] = useState(RELIC_SLOTS.HEAD);
    const [selectedMainstat, setSelectedMainstat] = useState(getRelicMainStatsBySlot(selectedRelicSlot)[0])
    const [selectedSubstats, setSelectedSubstats] = useState([
        RELIC_STATS.NONE,
        RELIC_STATS.NONE,
        RELIC_STATS.NONE,
        RELIC_STATS.NONE
    ]);
    const [allowRainbow, setAllowRainbow] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [results, setResults] = useState(null);
    const [search, setSearch] = useState('');

    const onSubmit = () => {
        let newValidationErrors = []
        if (selectedSubstats.includes(selectedMainstat)) {
            newValidationErrors = [...newValidationErrors, 'Substat cannot match the Main Stat.'];
        }

        if (selectedSubstats.filter((e, i) => e !== RELIC_STATS.NONE && selectedSubstats.indexOf(e) !== i).length > 0) {
            newValidationErrors = [...newValidationErrors, 'Duplicate substat.'];
        }

        if (!selectedSubstats.filter(e => e !== RELIC_STATS.NONE).length) {
            newValidationErrors = [...newValidationErrors, 'No substats selected.']
        }

        setValidationErrors(newValidationErrors);
        if (newValidationErrors.length) {
            return;
        }

        setResults(
            generateRelicResults(
                selectedRelicSetType,
                selectedRelicSet,
                selectedRelicSlot,
                selectedMainstat,
                selectedSubstats,
                allowRainbow,
            )
        );
    };

    useEffect(() => {
        if (selectedRelicSetType === RELIC_SET_TYPES.RELICS) {
            setSelectedRelicSet(RELIC_SETS.ICE);
            setSelectedRelicSlot(RELIC_SLOTS.HEAD);
        }

        if (selectedRelicSetType === RELIC_SET_TYPES.PLANARS) {
            setSelectedRelicSet(PLANAR_SETS.SSS);
            setSelectedRelicSlot(RELIC_SLOTS.ORB);
        }
    }, [selectedRelicSetType])

    useEffect(() => {
        setResults(null);
    }, [
        selectedRelicSetType,
        selectedRelicSet,
        selectedRelicSlot,
        selectedMainstat,
        selectedSubstats,
        allowRainbow,
    ]);

    return (
        <div className="app-page">
            <div className="app-page-left">
                <RelicSetTypeSelect
                    selectedRelicSetType={selectedRelicSetType}
                    setSelectedRelicSetType={setSelectedRelicSetType}
                    search={search}
                    setSearch={setSearch}
                />
                <RelicSetSelect
                    selectedRelicSetType={selectedRelicSetType}
                    selectedRelicSet={selectedRelicSet}
                    setSelectedRelicSet={setSelectedRelicSet}
                    search={search}
                />
            </div>
            <div className="app-page-right">
                <h1 className="relic-salvage-title">{PAGES.RELIC_SALVAGE.title}</h1>
                <h3 className="relic-salvage-subtitle">Which characters could use this piece?</h3>
                <RelicPieceSelect
                    selectedRelicSetType={selectedRelicSetType}
                    selectedRelicSlot={selectedRelicSlot}
                    setSelectedRelicSlot={setSelectedRelicSlot}
                    selectedRelicSet={selectedRelicSet}
                />
                <RelicStatInput
                    selectedRelicSet={selectedRelicSet}
                    selectedRelicSlot={selectedRelicSlot}
                    selectedMainstat={selectedMainstat}
                    setSelectedMainstat={setSelectedMainstat}
                    selectedSubstats={selectedSubstats}
                    setSelectedSubstats={setSelectedSubstats}
                    allowRainbow={allowRainbow}
                    setAllowRainbow={setAllowRainbow}
                    onSubmit={onSubmit}
                    validationErrors={validationErrors}
                />
                <Results
                    results={results}
                    selectedMainstat={selectedMainstat}
                    selectedSubstats={selectedSubstats}
                />
            </div>
        </div>
    );
}

export default RelicSalvage;
