
import classnames from 'classnames';

import { PLANAR_SETS } from '../../../../constants.js';
import { getPlanarImgById } from '../../../../helpers.js';

import '../RelicSetSelect.scss';

function RelicSetSelectPlanars({
    selectedRelicSet,
    setSelectedRelicSet,
    search
}) {
    return (
        <div className='relic-set-select-relics'>
            {
                Object.keys(PLANAR_SETS)
                    .filter(e => PLANAR_SETS[e].toLowerCase().includes(search.toLowerCase()))
                    .map(e => (
                        <button
                            className="relic-set-select-relic-wrapper"
                            onClick={() => { setSelectedRelicSet(PLANAR_SETS[e]) }}
                            key={e}
                        >
                            <img
                                width="100px"
                                height="100px"
                                alt={e}
                                src={getPlanarImgById(e)}
                                className={classnames({
                                    'relic-set-select-relic': true,
                                    'relic-set-select-relic-selected': selectedRelicSet === PLANAR_SETS[e]
                                })}
                            />
                        </button>
                    ))
            }
        </div>
    );
}

export default RelicSetSelectPlanars;