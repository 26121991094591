import './About.scss';

function About() {
    return (
        <div className="about">
            <div>
                <div>
                    HSR Tools is created and maintained by Feeed.
                </div>
                <div>
                    Socials:{' '}
                    <a target="_blank" rel="noreferrer" href="https://www.reddit.com/user/Feeed3/">Reddit</a>{' | '}
                    <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCggl1MWRo37kfV7S7RgLP9w">Youtube</a>
                </div>
                <div className="about-margintop">
                    Please feel free to shoot me a DM if any information is not accurate, or if you encounter any bugs.
                </div>
            </div>

            <div className="about-margintop">
                HSR Tools uses a variety of sources to compile the information and provide accurate information. In particular, I would like to credit:
                <ul>
                    <li>
                        <a target="_blank" rel="noreferrer" href="https://www.prydwen.gg/">Prydwen.gg</a>
                    </li>
                    <li>
                        <a target="_blank" rel="noreferrer" href="https://hsr.keqingmains.com/">KQM</a>
                    </li>
                    <li>
                        <a target="_blank" rel="noreferrer" href="https://honkai-star-rail.fandom.com/wiki/Honkai:_Star_Rail_Wiki">Honkai: Star Rail Wiki</a>
                    </li>
                    <li>
                        <a target="_blank" rel="noreferrer" href="https://www.mobilemeta.gg/honkai-starrail/app/relic-scorer">MobileMeta.gg</a>
                    </li>
                    <li>
                        <a target="_blank" rel="noreferrer" href="https://hsr-statistics.com/">HSR Statistics</a>
                    </li>
                    <li>
                        <a target="_blank" rel="noreferrer" href="https://www.youtube.com/@guobacertified">GuobaCertified</a>
                    </li>
                    <li>
                        <a target="_blank" rel="noreferrer" href="https://www.youtube.com/@mrpokkee">MrPokke</a> and the CN community
                    </li>
                    <li>
                        The users of:
                        <ul>
                            <li><a target="_blank" rel="noreferrer" href="https://www.reddit.com/r/HonkaiStarRail">/r/HonkaiStarRail</a></li>
                            <li>/r/{`<x>`}Mains subreddits</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div>
                Thank you for your contributions and for making sites like mine possible!
            </div>
        </div>
    );
}

export default About;
