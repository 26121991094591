import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip'

import classnames from 'classnames';

import Calculator from '../../components/Calculator';
import Input from '../../components/Input';
import HelpIcon from '../../components/HelpIcon';
import CalculatorSubmit from '../../components/Calculator/CalculatorSubmit';

import './BronyaSpeedCalculator.scss';

const DEFAULT_BRONYA_TALENT_LV = '10';
const BRONYA_TALENT_VALUES = {
    '1': 15,
    '2': 16.5,
    '3': 18,
    '4': 19.5,
    '5': 21,
    '6': 22.5,
    '7': 24.4,
    '8': 26.3,
    '9': 28.1,
    '10': 30,
    '11': 31.5,
    '12': 33
}

const BRONYA_PLAYSTYLES_KEYS = {
    SLOW: 'SLOW',
    HYPERSPEED: 'HYPERSPEED',
    SP_POSITIVE: 'SP_POSITIVE'
}

const BRONYA_PLAYSTYLES = {
    [BRONYA_PLAYSTYLES_KEYS.SLOW]: 'Slow Skillspam',
    [BRONYA_PLAYSTYLES_KEYS.HYPERSPEED]: 'Fast Alternating',
    [BRONYA_PLAYSTYLES_KEYS.SP_POSITIVE]: 'SP Positive',
}

function BronyaSpeedCalculator() {
    const [activeCalc, _setActiveCalc] = useState(0);
    const [dpsBaseSpeed, setDPSBaseSpeed] = useState('');
    const [dpsTotalSpeed, setDPSTotalSpeed] = useState('');
    const [bronyaTotalSpeed, setBronyaTotalSpeed] = useState('');
    const [bronyaE2, setBronyaE2] = useState(false);
    const [bronyaTalentLv, setBronyaTalentLv] = useState(DEFAULT_BRONYA_TALENT_LV);
    const [bronyaPlaystyle, setBronyaPlaystyle] = useState(BRONYA_PLAYSTYLES_KEYS.SLOW)
    const [resultUpper, setResultUpper] = useState(null);
    const [resultLower, setResultLower] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!bronyaE2) {
            setDPSBaseSpeed('');
        }
    }, [bronyaE2, setDPSBaseSpeed]);

    useEffect(() => {
        if (bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.SLOW) {
            setBronyaTalentLv(DEFAULT_BRONYA_TALENT_LV);
        }
    }, [bronyaPlaystyle, setBronyaTalentLv]);

    const resetResults = () => {
        setResultUpper(null);
        setResultLower(null);
        setError(null);
    }

    const resetForm = () => {
        setDPSTotalSpeed('');
        setBronyaTotalSpeed('');
        resetResults();
    }

    const resetFormFull = () => {
        setBronyaPlaystyle(BRONYA_PLAYSTYLES_KEYS.SLOW)
        setDPSBaseSpeed('');
        setDPSTotalSpeed('');
        setBronyaTotalSpeed('');
        setBronyaE2(false);
        setBronyaTalentLv(DEFAULT_BRONYA_TALENT_LV);
        resetResults();
    }

    const setActiveCalc = val => {
        resetForm();
        _setActiveCalc(val);
    }

    const onChange = (e, setFunction) => {
        resetResults();

        const val = e.target.value;
        setFunction(val.length ? Number(val) : val);
    }

    const onChangeRadio = (e, setFunction) => {
        resetResults();

        setFunction(e.target.checked);
    }

    const getBronyaPlaystyleTooltip = () => {
        if (bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.SLOW) {
            return `
                <span class="tooltip">
                    <span class="color-yellow">Slow Skillspam ("-1 Bronya")</span><br />
                    Does more damage, but costs more SP.<br />
                    <br />
                    The turn order will look like:<br />
                    <span class="color-yellow">DPS → Bronya (Skill) → DPS → Repeat</span>
                </span>
            `;
        }

        if (bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.HYPERSPEED) {
            return `
                <span class="tooltip">
                    <span class="color-yellow">Fast Alternating ("Hyperspeed")</span><br />
                    Does medium damage and does not generate nor consume SP.<br />
                    <br />
                    The turn order will look like:<br />
                    <span class="color-yellow">Bronya (Skill) → DPS → Bronya (Basic) → DPS → Repeat</span>
                </span>
            `;
        }

        if (bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.SP_POSITIVE) {
            return `
                <span class="tooltip">
                    <span class="color-yellow">SP Positive</span><br />
                    Does the least damage, but generates SP.<br />
                    <br />
                    The turn order will look like:<br />
                    <span class="color-yellow">Bronya (Skill) → DPS → Bronya (Basic) → DPS → Bronya (Basic) → DPS → Repeat</span><br />
                    <br />
                    This playstyle is listed as "<a href="https://hsr.keqingmains.com/bronya/#Fast_Double_Alternating" target="_blank">Fast
                    Double Alternating</a>" in the KQM Guide.
                </span>
            `;
        }
    }

    const calculate = () => {
        // Errors
        if (activeCalc === 1 && bronyaE2) {
            if (bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.HYPERSPEED && dpsBaseSpeed > dpsTotalSpeed) {
                setError('Total Speed cannot be lower than Base Speed');

                return;
            }
        }

        // Calc for DPS
        if (activeCalc === 0) {
            if (bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.SLOW) {
                // for DPS, Slow, pre-E2
                if (!bronyaE2) {
                    setResultLower((bronyaTotalSpeed + 0.1).toFixed(1));

                    return;
                }

                // for DPS, Slow, E2
                const requiredExtraSpeed = (bronyaTotalSpeed + 0.1) - (dpsBaseSpeed * (1 + BRONYA_TALENT_VALUES[bronyaTalentLv] / 100));
                const roundedRequiredExtraSpeed = Math.ceil(requiredExtraSpeed * 10) / 10;
                const requiredSpeed = dpsBaseSpeed + roundedRequiredExtraSpeed;
                setResultLower(requiredSpeed.toFixed(1));

                return;
            }

            if (bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.HYPERSPEED) {
                // for DPS, Hyperspeed, pre-E2
                if (!bronyaE2) {
                    const requiredSpeed = (bronyaTotalSpeed + 0.1) / (2 - (BRONYA_TALENT_VALUES[bronyaTalentLv] / 100));
                    const roundedRequiredSpeed = Math.ceil(requiredSpeed * 10) / 10;
                    setResultLower(roundedRequiredSpeed.toFixed(1));

                    return;
                }

                // for DPS, Hyperspeed, E2
                const buffedSpeed = (bronyaTotalSpeed + 0.1) / (2 - (BRONYA_TALENT_VALUES[bronyaTalentLv] / 100));
                const requiredSpeed = buffedSpeed - 0.3 * dpsBaseSpeed;
                const roundedRequiredSpeed = Math.ceil(requiredSpeed * 10) / 10;
                setResultLower(roundedRequiredSpeed.toFixed(1));

                return;
            }

            if (bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.SP_POSITIVE) {
                // for DPS, SP Positive, pre-E2
                if (!bronyaE2) {
                    const requiredSpeed = bronyaTotalSpeed / (1.5 - (BRONYA_TALENT_VALUES[bronyaTalentLv] / 100));
                    setResultLower(((Math.ceil(requiredSpeed * 10) / 10) + 0.1).toFixed(1));

                    return;
                }

                // for DPS, SP Positive, E2
                const a = dpsBaseSpeed;
                const b = bronyaTotalSpeed;
                const t = BRONYA_TALENT_VALUES[bronyaTalentLv] / 100;

                const qa = -3 + (2 * t);
                const qb = (2 * b) - (0.9 * a) + (0.6 * a * t);
                const qc = 0.3 * a * b;
                const sqrt = (qb * qb) - (4 * qa * qc);

                const quadratic = ((-1 * qb) - Math.sqrt(sqrt)) / (2 * qa);
                const roundedRequiredSpeed = (Math.ceil(quadratic * 10) / 10) + 0.1;

                setResultLower(roundedRequiredSpeed.toFixed(1));

                return;
            }
        }

        // Calc for Bronya
        if (bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.SLOW) {
            // for Bronya, Slow, pre-E2
            if (!bronyaE2) {
                setResultUpper((dpsTotalSpeed - 0.1).toFixed(1));

                return;
            }

            // for Bronya, Slow, E2
            const bronyaSpeed = (Math.ceil((dpsTotalSpeed + dpsBaseSpeed * 0.3) * 10) / 10) - 0.1;
            setResultUpper(bronyaSpeed.toFixed(1));

            return;
        }

        if (bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.HYPERSPEED) {
            // for Bronya, Hyperspeed, pre-E2
            if (!bronyaE2) {
                const bronyaSpeed = (Math.ceil((dpsTotalSpeed * (2 - BRONYA_TALENT_VALUES[bronyaTalentLv] / 100)) * 10) / 10) - 0.1;
                setResultUpper(bronyaSpeed.toFixed(1));

                return;
            }

            // for Bronya, Hyperspeed, E2
            const bronyaSpeed = (Math.ceil((((dpsTotalSpeed + 0.3 * dpsBaseSpeed) * (2 - BRONYA_TALENT_VALUES[bronyaTalentLv] / 100))) * 10) / 10) - 0.1;
            setResultUpper(bronyaSpeed.toFixed(1));

            return;
        }

        if (bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.SP_POSITIVE) {
            // for Bronya, SP Positive, pre-E2
            if (!bronyaE2) {
                const bronyaSpeed = Math.ceil((dpsTotalSpeed * (3 / 2 - BRONYA_TALENT_VALUES[bronyaTalentLv] / 100) - 1) * 10) / 10;
                setResultUpper(bronyaSpeed.toFixed(1));

                return;
            }

            // for Bronya, SP Positive, E2
            const buffedSpeed = dpsTotalSpeed + dpsBaseSpeed * 0.3;
            const bronyaSpeed = Math.ceil(((((buffedSpeed * dpsTotalSpeed) / (buffedSpeed + dpsTotalSpeed)) * (3 - 2 * BRONYA_TALENT_VALUES[bronyaTalentLv] / 100)) - 1) * 10) / 10;
            setResultUpper(bronyaSpeed.toFixed(1));

            return;
        }
    }

    const isCalculateDisabled = () => {
        if (activeCalc === 0) {
            if (bronyaE2) {
                return !(
                    bronyaTotalSpeed !== '' &&
                    dpsBaseSpeed !== ''
                );
            }

            return !(bronyaTotalSpeed !== '');
        }

        if (!bronyaE2) {
            return !(dpsTotalSpeed !== '');
        }

        return !(
            dpsTotalSpeed !== '' &&
            dpsBaseSpeed !== ''
        );
    };

    return (
        <Calculator
            title='Bronya Speed Calculator'
            description={
                <div>
                    <div>The formulas used by this calculator are used as listed by the <a href="https://hsr.keqingmains.com/bronya" target="_blank" rel="noreferrer">KQM Bronya Guide</a>.</div><br />
                    <div>Please choose whether to calculate:</div>
                </div>}
            descriptionListItems={[
                `What your DPS Speed should be relative to Bronya`,
                `What your Bronya Speed should be relative to your DPS`
            ]}
            buttonLabels={[
                'Calculate DPS Speed',
                'Calculate Bronya Speed',
            ]}
            activeCalc={activeCalc}
            setActiveCalc={setActiveCalc}
        >
            <Input
                hidden={activeCalc === 1}
                label={<>Bronya <span className="color-green">Total</span> Speed</>}
                id='bronya-total-speed'
                type='number'
                onChange={e => { onChange(e, setBronyaTotalSpeed) }}
                placeholder=''
                value={bronyaTotalSpeed}
                tooltipContent={`
                    <span class="tooltip">
                        The amount of Speed <span class="color-green">with relics</span>
                        and <span class="color-green">with buffs</span>.
                    <span>
                `}
            />

            <Input
                hidden={activeCalc === 0}
                label={<>DPS <span className="color-green">Total</span> Speed (no E2)</>}
                id='dps-total-speed'
                type='number'
                onChange={e => { onChange(e, setDPSTotalSpeed) }}
                value={dpsTotalSpeed}
                tooltipContent={`
                    <span class="tooltip">
                        The amount of Speed <span class="color-green">with relics</span>
                        and <span class="color-green">with non-Bronya buffs</span>.<br />
                        <br />
                        This value should be provided
                        <span class="color-red">without considering Bronya's E2</span>,
                        as that is automatically accounted for by the calculator.
                    <span>
                `}
            />

            <div className="calculator-input-wrapper">
                <label htmlFor='bronya-playstyle'>
                    Bronya Playstyle
                </label>
                <div className="calculator-input">
                    <select
                        className="calculator-input-field bronya-playstyle-input-field"
                        name='bronya-playstyle'
                        id='bronya-playstyle'
                        value={bronyaPlaystyle}
                        onChange={e => {
                            resetResults();
                            setBronyaPlaystyle(e.target.value)
                        }}
                    >
                        {Object.keys(BRONYA_PLAYSTYLES).map(
                            e => (
                                <option key={`bronya-playstyle-${e}`} value={e}>
                                    {BRONYA_PLAYSTYLES[e]}
                                </option>
                            )
                        )}
                    </select>
                    <HelpIcon
                        id={`bronya-playstyle-tooltip`}
                        content={getBronyaPlaystyleTooltip()}
                    />
                    <Tooltip id={`bronya-playstyle-tooltip`} openOnClick />
                </div>
            </div>

            <div className={classnames({
                "calculator-input-wrapper": true,
                "hidden": bronyaPlaystyle === BRONYA_PLAYSTYLES_KEYS.SLOW
            })}>
                <label htmlFor='bronya-talent'>
                    Bronya Talent Level
                </label>
                <div className="calculator-input">
                    <select
                        className="calculator-input-field"
                        name='bronya-talent'
                        id='bronya-talent'
                        value={bronyaTalentLv}
                        onChange={e => {
                            resetResults()
                            setBronyaTalentLv(e.target.value)
                        }}
                    >
                        {Object.keys(BRONYA_TALENT_VALUES).map(
                            e => (
                                <option key={`bronya-talent-${e}`} value={e}>
                                    {e}
                                </option>
                            )
                        )}
                    </select>
                    <HelpIcon
                        id={`bronya-talent-tooltip`}
                        content={`
                            <span class="tooltip">
                                The level of Bronya's <span class="color-green">Talent</span>.<br />
                                <br />
                                This value can be viewed by looking at Bronya's <span class="color-green">Traces</span>.
                            </span>
                        `}
                    />
                    <Tooltip id={`bronya-talent-tooltip`} openOnClick />
                </div>
            </div>

            <Input
                hidden={false}
                label='Bronya E2'
                id='bronya-e2'
                type='checkbox'
                onChange={e => { onChangeRadio(e, setBronyaE2) }}
                value={bronyaE2}
                tooltipContent={`
                    <span class="tooltip">
                        Whether or not Bronya's <span class="color-green">second Eidolon</span> is activated.
                    </span>
                `}
            />

            <Input
                hidden={!bronyaE2}
                label={<>DPS <span className="color-yellow">Base</span> Speed</>}
                id='dps-base-speed'
                type='number'
                onChange={e => { onChange(e, setDPSBaseSpeed) }}
                placeholder=''
                value={dpsBaseSpeed}
                tooltipContent={`
                    <span class="tooltip">
                        The amount of Speed your DPS has <span class="color-red">without relics</span> and <span class="color-red">without buffs</span>.<br />
                        <br />
                        This value is easily obtainable in the "More Stats" section of the Character Details screen.<br />
                        <br />
                        Speed will be listed as, for example, "<span class="color-green">96</span>+25"; the value on the <span class="color-green">left</span> is their Base Speed.
                    </span>
                `}
            />

            <CalculatorSubmit
                calculate={calculate}
                isCalculateDisabled={isCalculateDisabled}
                resetForm={resetFormFull}
            />

            {error && (
                <div className="bronya-calc-error">
                    {error}
                </div>
            )}

            {(resultUpper || resultLower) && !error && (
                <div className="bronya-calc-result">
                    {resultLower && (
                        <div className="bronya-calc-result-main">
                            {resultLower <= 95 && (
                                <div style={{ marginBottom: '12px' }}>
                                    <span className="color-red">This setup is likely not possible.<br />Increase Bronya's Total Speed.</span>
                                </div>
                            )}
                            <div>
                                DPS <span className="color-green">Total</span> Speed{activeCalc === 0 && bronyaE2 ? '*' : ''} must be as close to
                            </div>
                            <span className="bronya-calc-result-speed color-green">{resultLower}</span>
                            <div>
                                as possible, without going under.
                            </div>
                        </div>
                    )}
                    {resultUpper && (
                        <div className="bronya-calc-result-main">
                            {resultUpper >= 180 && (
                                <div style={{ marginBottom: '12px' }}>
                                    <span className="color-red">This setup cannot realistically come close to being optimal.<br />Decrease your DPS Total Speed.</span>
                                </div>
                            )}
                            <div>
                                Bronya's <span className="color-green">Total</span> Speed must be as close to
                            </div>
                            <span className="bronya-calc-result-speed color-green">{resultUpper}</span>
                            <div>
                                as possible, without going over.
                            </div>
                        </div>
                    )}
                    {activeCalc === 0 && bronyaE2 && (
                        <div className="bronya-calc-result-sub">
                            * The amount of Speed with relics and non-Bronya buffs (i.e. consider everything except for Bronya's E2)<br />
                        </div>
                    )}
                </div>
            )}
        </Calculator>

    );
}

export default BronyaSpeedCalculator;