import { RELIC_WEIGHTS } from '../../../character_build_data.js';
import { PATHS } from '../../../constants.js';
import {
    getRelicImgByName,
    getPlanarImgByName,
    getCharacterPortraitByName,
    getKey
} from '../../../helpers';

import CollapsibleSeparator from '../../../components/CollapsibleSeparator';

import './CharacterInfo.scss';

function CharacterInfo({ data }) {
    const LIGHTCONE_DATA = require(`../../../lib/lightcones/${data.path.toLowerCase()}.js`)[`LIGHTCONES_${getKey(PATHS, data.path)}`];
    const getLCColor = rarity => {
        if (rarity === 5) {
            return 'color-yellow';
        }

        if (rarity === 4) {
            return 'color-purple';
        }

        return 'color-blue';
    }

    const groupSubstatsByPriority = substats => {
        const weights = Object.values(RELIC_WEIGHTS);
        const sortedWeights = weights.sort((a, b) => b - a);

        return sortedWeights.map(e => {
            const equalWeight = substats.filter(ss => ss.weight === e);
            return equalWeight.map(ew => ew.substat).join(' / ');
        }).filter(e => e.length > 0);
    }

    return (
        <div className="character-info-wrapper">
            <div className="character-info">
                <div className="character-name">
                    <div className="character-name-name">
                        {data.character}
                    </div>
                    <div className="character-name-path">
                        <b>Path</b>: {data.path}
                    </div>
                    <div className="character-name-element">
                        <b>Element</b>: {data.element}
                    </div>
                    <img
                        width="160px"
                        height="160px"
                        src={getCharacterPortraitByName(data.character)}
                        alt={data.character}
                    />
                </div>
                <CollapsibleSeparator title="Relic Sets">
                    <div className="character-relic-wrapper">
                        {data.relicSets.filter((item, index) => data.relicSets.indexOf(item) === index).map(e => (
                            <div className="character-relic" key={`relicSets-${e}`}>
                                <img
                                    width="100px"
                                    height="100px"
                                    src={getRelicImgByName(e)}
                                    alt={e}
                                />
                                <div className="character-relic-text">
                                    {e} {!data['2pc']?.includes(e) && !data['4pc']?.includes(e) ? '(4pc / 2pc)' : ''}{data['2pc']?.includes(e) ? '(2pc)' : ''}{data['4pc']?.includes(e) ? '(4pc)' : ''}
                                </div>
                            </div>
                        ))}
                    </div>
                </CollapsibleSeparator>
                <CollapsibleSeparator title="Planar Ornaments">
                    <div className="character-relic-wrapper">
                        {data.planarSets.map(e => (
                            <div className="character-relic" key={`planarSets-${e}`}>
                                <img
                                    width="100px"
                                    height="100px"
                                    src={getPlanarImgByName(e)}
                                    alt={e}
                                />
                                <div className="character-relic-text">{e}</div>
                            </div>
                        ))}
                    </div>
                </CollapsibleSeparator>
                <CollapsibleSeparator title="Main Stats">
                    <div className="character-stats">
                        {
                            data.mainStats.map(e => (
                                <div className="character-stat" key={`character-stat-${e.slot}`}>
                                    <div className="character-stat-slot">
                                        {`${e.slot}: `}
                                    </div>
                                    <div className="character-stat-stat">
                                        {e.stats.join(', ')}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </CollapsibleSeparator>
                <CollapsibleSeparator title="Substat Priority">
                    <div className="character-stats">
                        {groupSubstatsByPriority(data.substats).map((e, i) => (
                            <div className="character-stat" key={e}>
                                <div className="character-stat-slot">
                                    {`${i + 1}.`}
                                </div>
                                <div className="character-stat-stat">
                                    {e}
                                </div>
                            </div>
                        ))}
                    </div>
                </CollapsibleSeparator>
                <CollapsibleSeparator title="Light Cones">
                    <div className="character-relic-wrapper">
                        {data.lightcones.map(e => {
                            const E_DATA = LIGHTCONE_DATA.find(e2 => e2.name === e);
                            return (
                                <div className="character-relic" key={`relicSets-${e}`}>
                                    <img
                                        width="85px"
                                        height="100px"
                                        src={E_DATA?.image}
                                        alt={e}
                                        style={{ padding: '0 7.5px' }}
                                    />
                                    <div className="character-relic-text">
                                        <span className={getLCColor(E_DATA?.rarity)}>{e} ({E_DATA.rarity}✦)</span><br />
                                        <span>{E_DATA?.type}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </CollapsibleSeparator>
            </div>
        </div>
    );
}

export default CharacterInfo;
