import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { CHARACTER_BUILD_DATA } from '../../character_build_data.js'
import { CHARACTERS, PAGES, PATHS } from '../../constants.js';
import { getCharacterPortraitByName, getKey } from '../../helpers.js';
import { LIGHTCONE_IDS } from '../../lib/lightcones/lightcone_helpers.js';

import './LightconeAssigner.scss';

const getLCOptions = _path =>
    Object.keys(LIGHTCONE_IDS).filter(e => e.includes(`${_path}_`));

const getCharacterOptions = _lc =>
    CHARACTER_BUILD_DATA.filter(e => e.lightcones.includes(LIGHTCONE_IDS[_lc]))

function LightconeAssigner() {
    const navigate = useNavigate();

    const [path, setPath] = useState(Object.keys(PATHS)[0]);
    const [lc, setLC] = useState(getLCOptions(path)[0]);
    const lcDropdown = useRef(lc);

    useEffect(() => {
        const newOption = getLCOptions(path)[0];
        setLC(newOption);
        lcDropdown.current.value = newOption
    }, [path]);

    const LIGHTCONE_DATA = require(`../../lib/lightcones/${path.toLowerCase()}.js`)[`LIGHTCONES_${path}`];

    return (
        <div className="lc-assigner-wrapper">
            <h1 className="lc-assigner-title">{PAGES.LIGHTCONE_ASSIGNER.title}</h1>
            <h3 className="lc-assigner-subtitle">Which characters could use this Light Cone?</h3>
            <div className="relic-stat-input" style={{ width: '300px' }}>
                <div className="relic-stat-input-inputs">
                    <div className="relic-stat-input-input">
                        <label htmlFor="lc-assigner-path">Path:</label>
                        <select
                            name="lc-assigner-path"
                            id="lc-assigner-path"
                            onChange={e => setPath(e.target.value)}
                        >
                            {Object.keys(PATHS).map(e => (
                                <option
                                    key={`lc-assigner-path-${PATHS[e].toLowerCase()}`}
                                    value={e}
                                >
                                    {PATHS[e]}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="relic-stat-input-input">
                        <label htmlFor="lc-assigner-lc">Light&nbsp;Cone:</label>
                        <select
                            name="lc-assigner-lc"
                            id="lc-assigner-lc"
                            onChange={e => setLC(e.target.value)}
                            ref={lcDropdown}
                        >
                            {
                                getLCOptions(path).map(e => (
                                    <option
                                        key={`lc-assigner-lc-${e}`}
                                        value={e}
                                    >
                                        {LIGHTCONE_IDS[e]}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            <img
                width="85px"
                height="100px"
                src={LIGHTCONE_DATA.find(e => e.name === LIGHTCONE_IDS[lc])?.image}
                alt={LIGHTCONE_IDS[lc]}
            />
            <div className="lc-assigner-results">
                {
                    getCharacterOptions(lc).length
                        ? getCharacterOptions(lc).map(e => {
                            const characterKey = getKey(CHARACTERS, e.character).toLowerCase();

                            return (
                                <button
                                    className="relic-set-select-relic-wrapper"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => { navigate(`/${PAGES.CHARACTER_BUILDS.link}/${characterKey}`) }}
                                    key={`lc-assigner-result-${characterKey}`}
                                >
                                    <img
                                        width="100px"
                                        height="100px"
                                        src={getCharacterPortraitByName(e.character)}
                                        alt={e.character}
                                    />
                                    <div className="lc-assigner-result-name-wrapper">
                                        <div className="lc-assigner-result-name">{e.character}</div>
                                    </div>
                                </button>
                            )
                        })
                        : (<div className="lc-assigner-no-result">This Light Cone is not recommended on any character.</div>)
                }
            </div>
        </div>
    );
}

export default LightconeAssigner;