export const PAGES = {
    HOME: {
        link: 'home',
        title: 'Home',
    },
    RELIC_SALVAGE: {
        link: 'relic-assigner',
        title: 'Relic Assigner'
    },
    CHARACTER_BUILDS: {
        link: 'characters',
        title: 'Character Builds'
    },
    ABOUT: {
        link: 'about',
        title: 'About'
    },
    EHR_CALCULATOR: {
        link: 'ehr-calculator',
        title: 'EHR Calculator'
    },
    BRONYA_SPEED_CALCULATOR: {
        link: 'bronya-speed-calculator',
        title: 'Bronya SPD Calculator',
    },
    PRIVACY_POLICY: {
        link: 'privacy-policy',
        title: 'Privacy Policy',
    },
    DAMAGE_CALCULATOR: {
        link: 'damage-calculator',
        title: 'Damage Calculator',
    },
    SPEED_DECIMAL_CHECKER: {
        link: 'speed-decimal-checker',
        title: 'Speed Decimal Checker',
    },
    PULL_CHANCE_CALCULATOR: {
        link: 'pull-chance-calculator',
        title: 'Pull Chance Calculator',
    },
    LIGHTCONE_ASSIGNER: {
        link: 'light-cone-assigner',
        title: 'Light Cone Assigner',
    },
    SET_ASSIGNER: {
        link: 'set-assigner',
        title: 'Set Assigner',
    }
};

export const RELIC_SET_TYPES = {
    RELICS: 'Relics',
    PLANARS: 'Planar Ornaments',
};

export const RELIC_SETS = {
    ICE: 'Hunter of Glacial Forest',
    WIND: 'Eagle of Twilight Line',
    PHYSICAL: 'Champion of Streetwise Boxing',
    BREAK: 'Thief of Shooting Meteor',
    HEAL: 'Passerby of Wandering Cloud',
    MUSKETEER: 'Musketeer of Wild Wheat',
    GUARD: 'Guard of Wuthering Snow',
    QUANTUM: 'Genius of Brilliant Stars',
    SHIELD: 'Knight of Purity Palace',
    LIGHTNING: 'Band of Sizzling Thunder',
    FIRE: 'Firesmith of Lava-Forging',
    IMAGINARY: 'Wastelander of Banditry Desert',
    HP: 'Longevous Disciple',
    SPEED: 'Messenger Traversing Hackerspace',
    FOLLOWUP: 'The Ashblazing Grand Duke',
    DOT: 'Prisoner in Deep Confinement',
    PIONEER: 'Pioneer Diver of Dead Waters',
    WATCHMAKER: 'Watchmaker, Master of Dream Machinations',
    CAVALRY: 'Iron Cavalry Against the Scourge',
    VALOROUS: 'The Wind-Soaring Valorous',
};

export const PLANAR_SETS = {
    SSS: 'Space Sealing Station',
    FLEET: 'Fleet of the Ageless',
    TALIA: 'Talia: Kingdom of Banditry',
    VONWACQ: 'Sprightly Vonwacq',
    PANCOSMIC: 'Pan-Cosmic Commercial Enterprise',
    CELESTIAL: 'Celestial Differentiator',
    BELEBOG: 'Belobog of the Architects',
    SALSOTTO: 'Inert Salsotto',
    RUTILANT: 'Rutilant Arena',
    KEEL: 'Broken Keel',
    GLAMOTH: 'Firmament Frontline: Glamoth',
    PENACONY: 'Penacony, Land of the Dreams',
    SIGONIA: 'Sigonia, the Unclaimed Desolation',
    IZUMO: 'Izumo Gensei and Takama Divine Realm',
    DURAN: 'Duran, Dynasty of Running Wolves',
    KALPAGNI: 'Forge of the Kalpagni Lantern',
    LUSHAKA: 'Lushaka, the Sunken Seas',
    BANANA: 'The Wondrous BananAmusement Park',
};

export const RELIC_SLOTS = {
    BODY: 'Body',
    HANDS: 'Hands',
    BOOTS: 'Boots',
    HEAD: 'Head',
    ORB: 'Orb',
    ROPE: 'Rope'
};

export const RELIC_STATS = {
    FLAT_HP: 'HP (Flat)',
    HP: 'HP%',
    FLAT_ATK: 'ATK (Flat)',
    ATK: 'ATK%',
    FLAT_DEF: 'DEF (Flat)',
    DEF: 'DEF%',
    CRIT_DMG: 'Crit DMG',
    CRIT_RATE: 'Crit Rate',
    OUTGOING_HEALING: 'Outgoing Healing',
    EFFECT_HIT_RATE: 'Effect Hit Rate',
    SPEED: 'Speed',
    BREAK_EFFECT: 'Break Effect',
    ENERGY_REGEN: 'Energy Regen',
    EFFECT_RES: 'Effect Res',
    PHYSICAL_DMG: 'Physical DMG%',
    FIRE_DMG: 'Fire DMG%',
    ICE_DMG: 'Ice DMG%',
    LIGHTNING_DMG: 'Lightning DMG%',
    WIND_DMG: 'Wind DMG%',
    QUANTUM_DMG: 'Quantum DMG%',
    IMAGINARY_DMG: 'Imaginary DMG%',
    NONE: 'N/A',
};

export const CHARACTERS = {
    ACHERON: 'Acheron',
    ARGENTI: 'Argenti',
    ARLAN: 'Arlan',
    ASTA: 'Asta',
    AVENTURINE: 'Aventurine',
    BAILU: 'Bailu',
    BLACK_SWAN: 'Black Swan',
    BLADE: 'Blade',
    BOOTHILL: 'Boothill',
    BRONYA: 'Bronya',
    CLARA: 'Clara',
    DAN_HENG: 'Dan Heng',
    DAN_HENG_IL: 'Dan Heng • Imbibitor Lunae',
    DR_RATIO: 'Dr. Ratio',
    FEIXIAO: 'Feixiao',
    FIREFLY: 'Firefly',
    FU_XUAN: 'Fu Xuan',
    GALLAGHER: 'Gallagher',
    GEPARD: 'Gepard',
    GUINAIFEN: 'Guinaifen',
    HANYA: 'Hanya',
    HERTA: 'Herta',
    HIMEKO: 'Himeko',
    HOOK: 'Hook',
    HUOHUO: 'Huohuo',
    JADE: 'Jade',
    JIAOQIU: 'Jiaoqiu',
    JING_YUAN: 'Jing Yuan',
    JINGLIU: 'Jingliu',
    KAFKA: 'Kafka',
    LINGSHA: 'Lingsha',
    LUKA: 'Luka',
    LUOCHA: 'Luocha',
    LYNX: 'Lynx',
    MARCH_7TH: 'March 7th',
    MARCH_7TH_IMA: 'March 7th • Swordmaster',
    MC_FIRE: 'Trailblazer (Fire)',
    MC_IMAGINARY: 'Trailblazer (Imaginary)',
    MC_PHYSICAL: 'Trailblazer (Physical)',
    MISHA: 'Misha',
    MOZE: 'Moze',
    NATASHA: 'Natasha',
    PELA: 'Pela',
    QINGQUE: 'Qingque',
    RAPPA: 'Rappa',
    ROBIN: 'Robin',
    RUAN_MEI: 'Ruan Mei',
    SAMPO: 'Sampo',
    SEELE: 'Seele',
    SERVAL: 'Serval',
    SILVER_WOLF: 'Silver Wolf',
    SPARKLE: 'Sparkle',
    SUSHANG: 'Sushang',
    TINGYUN: 'Tingyun',
    TOPAZ: 'Topaz & Numby',
    WELT: 'Welt',
    XUEYI: 'Xueyi',
    YANQING: 'Yanqing',
    YUKONG: 'Yukong',
    YUNLI: 'Yunli',
};

export const ELEMENTS_KEYS = {
    PHYSICAL: 'PHYSICAL',
    FIRE: 'FIRE',
    ICE: 'ICE',
    LIGHTNING: 'LIGHTNING',
    WIND: 'WIND',
    QUANTUM: 'QUANTUM',
    IMAGINARY: 'IMAGINARY',
};

export const ELEMENTS = {
    [ELEMENTS_KEYS.PHYSICAL]: 'Physical',
    [ELEMENTS_KEYS.FIRE]: 'Fire',
    [ELEMENTS_KEYS.ICE]: 'Ice',
    [ELEMENTS_KEYS.LIGHTNING]: 'Lightning',
    [ELEMENTS_KEYS.WIND]: 'Wind',
    [ELEMENTS_KEYS.QUANTUM]: 'Quantum',
    [ELEMENTS_KEYS.IMAGINARY]: 'Imaginary',
};

export const ENEMY_TYPES_KEYS = {
    ELITE_BOSS: 'ELITE_BOSS',
    NORMAL: 'NORMAL',
};

export const ENEMY_TYPES = {
    [ENEMY_TYPES_KEYS.ELITE_BOSS]: 'Elite / Boss',
    [ENEMY_TYPES_KEYS.NORMAL]: 'Normal'
};

export const DOT_TYPES_KEYS = {
    BLEED: 'BLEED',
    BURN: 'BURN',
    SHOCK: 'SHOCK',
    WIND_SHEAR: 'WIND_SHEAR',
};

export const DOT_TYPES = {
    [DOT_TYPES_KEYS.BLEED]: 'Bleed',
    [DOT_TYPES_KEYS.BURN]: 'Burn',
    [DOT_TYPES_KEYS.SHOCK]: 'Shock',
    [DOT_TYPES_KEYS.WIND_SHEAR]: 'Wind Shear',
};

export const LEVEL_DMG_MULTIPLIER = {
    1: 54.0000,
    2: 58.0000,
    3: 62.0000,
    4: 67.5264,
    5: 70.5094,
    6: 73.5228,
    7: 76.5660,
    8: 79.6385,
    9: 82.7395,
    10: 85.8684,
    11: 91.4944,
    12: 97.0680,
    13: 102.5892,
    14: 108.0579,
    15: 113.4743,
    16: 118.8383,
    17: 124.1499,
    18: 129.4091,
    19: 134.6159,
    20: 139.7703,
    21: 149.3323,
    22: 158.8011,
    23: 168.1768,
    24: 177.4594,
    25: 186.6489,
    26: 195.7452,
    27: 204.7484,
    28: 213.6585,
    29: 222.4754,
    30: 231.1992,
    31: 246.4276,
    32: 261.1810,
    33: 275.4733,
    34: 289.3179,
    35: 302.7275,
    36: 315.7144,
    37: 328.2905,
    38: 340.4671,
    39: 352.2554,
    40: 363.6658,
    41: 408.1240,
    42: 451.7883,
    43: 494.6798,
    44: 536.8188,
    45: 578.2249,
    46: 618.9172,
    47: 658.9138,
    48: 698.2325,
    49: 736.8905,
    50: 774.9041,
    51: 871.0599,
    52: 964.8705,
    53: 1056.4206,
    54: 1145.7910,
    55: 1233.0585,
    56: 1318.2965,
    57: 1401.5750,
    58: 1482.9608,
    59: 1562.5178,
    60: 1640.3068,
    61: 1752.3215,
    62: 1861.9011,
    63: 1969.1242,
    64: 2074.0659,
    65: 2176.7983,
    66: 2277.3904,
    67: 2375.9085,
    68: 2472.4160,
    69: 2566.9739,
    70: 2659.6406,
    71: 2780.3044,
    72: 2898.6022,
    73: 3014.6029,
    74: 3128.3729,
    75: 3239.9758,
    76: 3349.4730,
    77: 3456.9236,
    78: 3562.3843,
    79: 3665.9099,
    80: 3767.5533,
};

export const BREAK_DEBUFF_TURNS = {
    [ELEMENTS_KEYS.PHYSICAL]: 2,
    [ELEMENTS_KEYS.FIRE]: 2,
    [ELEMENTS_KEYS.ICE]: 1,
    [ELEMENTS_KEYS.LIGHTNING]: 2,
    [ELEMENTS_KEYS.WIND]: 2,
    [ELEMENTS_KEYS.QUANTUM]: 1,
    [ELEMENTS_KEYS.IMAGINARY]: 1,
};

export const PATHS = {
    ABUNDANCE: 'Abundance',
    DESTRUCTION: 'Destruction',
    ERUDITION: 'Erudition',
    HARMONY: 'Harmony',
    HUNT: 'Hunt',
    NIHILITY: 'Nihility',
    PRESERVATION: 'Preservation',
};
