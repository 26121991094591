import { Tooltip } from 'react-tooltip'

import classnames from 'classnames';

import HelpIcon from '../HelpIcon';

import './Input.scss';

function Input({
    hidden,
    label,
    id,
    type,
    onChange,
    onBlur,
    placeholder,
    value,
    tooltipContent,
    inputFieldExtraClasses,
}) {
    return (
        <div className={classnames({
            'calculator-input-wrapper': true,
            'hidden': hidden,
        })}>
            <label htmlFor={id}>
                {label}
            </label>
            <div className="calculator-input">
                <input
                    className={classnames({
                        "calculator-input-field": true,
                        "field-checkbox": type === 'checkbox',
                        ...inputFieldExtraClasses,
                    })}
                    id={id}
                    type={type}
                    onChange={onChange}
                    onBlur={onBlur ? onBlur : () => { }}
                    placeholder={placeholder}
                    {...(type === 'number' ? { value } : {})}
                    {...(type === 'checkbox' ? { checked: value } : {})}
                />
                {tooltipContent && (
                    <>
                        <HelpIcon
                            id={`${id}-tooltip`}
                            content={tooltipContent}
                        />
                        <Tooltip id={`${id}-tooltip`} openOnClick />
                    </>
                )}
            </div>
        </div>
    );
}

export default Input;