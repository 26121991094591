import React, { useState } from 'react';

import Calculator from '../../components/Calculator';
import CollapsibleSeparator from '../../components/CollapsibleSeparator';
import CalculatorSubmit from '../../components/Calculator/CalculatorSubmit';
import Input from '../../components/Input';

import { calculateWarpProbability } from './calculate_pull_chance.js';
import { PAGES } from '../../constants';

import './PullChanceCalculator.scss';

function PullChanceCalculator() {
    const [numWarps, setNumWarps] = useState('');
    const [charPity, setCharPity] = useState('');
    const [charCopies, setCharCopies] = useState('');
    const [charGuaranteed, setCharGuaranteed] = useState(false);
    const [lcPity, setLCPity] = useState('');
    const [lcCopies, setLCCopies] = useState('');
    const [lcGuaranteed, setLCGuaranteed] = useState(false);
    const [result, setResult] = useState(null);

    const isCalculateDisabled = () => {
        const isNumber = n => typeof n === "number" && n >= 0;

        return !isNumber(numWarps) ||
            !isNumber(charPity) ||
            !isNumber(charCopies) ||
            !isNumber(lcPity) ||
            !isNumber(lcCopies);
    }

    const calculate = () => {
        const probability = calculateWarpProbability(
            numWarps,
            charPity,
            lcPity,
            lcGuaranteed,
            charGuaranteed,
            charCopies,
            lcCopies,
            100000,
        );

        setResult((probability * 100).toFixed(1))
    }

    const resetForm = () => {
        setResult(null);
        setNumWarps('');
        setCharPity('');
        setCharCopies('');
        setCharGuaranteed(false);
        setLCPity('');
        setLCCopies('');
        setLCGuaranteed(false);
    }

    const resetResults = () => {
        setResult(null);
    }

    const onChange = (e, setFunction) => {
        resetResults();

        const val = e.target.value;
        setFunction(val.length ? Number(val) : val);
    }

    const onChangeRadio = (e, setFunction) => {
        resetResults();

        setFunction(e.target.checked);
    }

    return (
        <Calculator
            title={PAGES.PULL_CHANCE_CALCULATOR.title}
            description={
                <div>
                    Calculates your odds of getting Characters and Light Cones within a certain number of pulls.<br />
                    <br />
                    The probability calculation is used as programmed by <a target="_blank" rel="noreferrer" href="https://www.reddit.com/user/Gatuno7000/">Gatuno7000</a>'s <a target="_blank" rel="noreferrer" href="https://github.com/Jose-AE/hsr-warp-calculator">HSR Warp Calculator</a>, and is used in accordance with their <a target="_blank" rel="noreferrer" href="https://github.com/Jose-AE/hsr-warp-calculator/blob/main/LICENSE">license</a>.<br />
                </div>
            }
            descriptionListItems={[]}
            buttonLabels={[]}
            activeCalc={null}
            setActiveCalc={() => { }}
            extraWrapperClasses={{
                'calculator-padding': true,
            }}
        >
            <Input
                hidden={false}
                label="Number of Pulls"
                id='num-warps'
                type='number'
                onChange={e => { onChange(e, setNumWarps) }}
                placeholder='(0)'
                value={numWarps}
                onBlur={e => {
                    if (e.target.value < 0) {
                        onChange({ target: { value: 0 } }, setNumWarps);
                    }
                }}
                tooltipContent={`
                    <span class="tooltip">
                        The number of <span class="color-green">warps</span> that will be initiated.
                    <span>
                `}
            />

            <CollapsibleSeparator
                title="Character"
            >
                <Input
                    hidden={false}
                    label="Banner Pity"
                    id='char-banner-pity'
                    type='number'
                    onChange={e => { onChange(e, setCharPity) }}
                    placeholder='(0)'
                    value={charPity}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setCharPity);
                        }

                        if (e.target.value > 89) {
                            onChange({ target: { value: 89 } }, setCharPity);
                        }
                    }}
                    tooltipContent={`
                        <span class="tooltip">
                            The number of pulls that have been used since the last time a 5* Character was obtained on a <span class="color-green">limited Character banner</span>.<br />
                            <br />
                            This value can be checked by selecting "<span class="color-green">View Details</span>" on the banner screen in-game and counting manually.<br />
                            <br />
                            This value cannot be greater than <span class="color-green">89</span>.
                        <span>
                    `}
                />

                <Input
                    hidden={false}
                    label="Desired Copies"
                    id='char-copies'
                    type='number'
                    onChange={e => { onChange(e, setCharCopies) }}
                    placeholder='(0)'
                    value={charCopies}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setCharCopies);
                        }
                    }}
                    tooltipContent={`
                        <span class="tooltip">
                            The <span class="color-green">desired number of times</span> the Character will be obtained.
                        <span>
                    `}
                />

                <Input
                    hidden={false}
                    label="Guaranteed?"
                    id='char-guaranteed'
                    type='checkbox'
                    onChange={e => { onChangeRadio(e, setCharGuaranteed) }}
                    value={charGuaranteed}
                    tooltipContent={`
                        <span class="tooltip">
                            Whether or not the last 5* Character obtained on a limited Character banner was the <span class="color-green">featured Character</span>.<br />
                            <br />
                            If the last obtained 5* Character on the limited Character banner was <span class="color-red">not</span> the featured Character, this box should be checked.<br />
                            <br />
                            This value can be checked by selecting "<span class="color-green">View Details</span>" on the banner screen in-game.
                        <span>
                    `}
                />
            </CollapsibleSeparator>

            <CollapsibleSeparator
                title="Light Cone"
            >
                <Input
                    hidden={false}
                    label="Banner Pity"
                    id='lc-banner-pity'
                    type='number'
                    onChange={e => { onChange(e, setLCPity) }}
                    placeholder='(0)'
                    value={lcPity}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setLCPity);
                        }

                        if (e.target.value > 79) {
                            onChange({ target: { value: 79 } }, setLCPity);
                        }
                    }}
                    tooltipContent={`
                        <span class="tooltip">
                            The number of pulls that have been used since the last time a 5* Light Cone was obtained on a <span class="color-green">limited Light Cone banner</span>.<br />
                            <br />
                            This value can be checked by selecting "<span class="color-green">View Details</span>" on the banner screen in-game and counting manually.<br />
                            <br />
                            This value cannot be greater than <span class="color-green">79</span>.
                        <span>
                    `}
                />

                <Input
                    hidden={false}
                    label="Desired Copies"
                    id='lc-copies'
                    type='number'
                    onChange={e => { onChange(e, setLCCopies) }}
                    placeholder='(0)'
                    value={lcCopies}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setLCCopies);
                        }
                    }}
                    tooltipContent={`
                        <span class="tooltip">
                            The <span class="color-green">desired number of times</span> the Light Cone will be obtained.
                        <span>
                    `}
                />

                <Input
                    hidden={false}
                    label="Guaranteed?"
                    id='lc-guaranteed'
                    type='checkbox'
                    onChange={e => { onChangeRadio(e, setLCGuaranteed) }}
                    value={lcGuaranteed}
                    tooltipContent={`
                        <span class="tooltip">
                            Whether or not the last 5* Light Cone obtained on a limited Light Cone banner was the <span class="color-green">featured Light Cone</span>.<br />
                            <br />
                            If the last obtained 5* Light Cone on the limited Light Cone banner was <span class="color-red">not</span> the featured Light Cone, this box should be checked.<br />
                            <br />
                            This value can be checked by selecting "<span class="color-green">View Details</span>" on the banner screen in-game.
                        <span>
                    `}
                />
            </CollapsibleSeparator>

            <CalculatorSubmit
                calculate={calculate}
                isCalculateDisabled={isCalculateDisabled}
                resetForm={resetForm}
            />

            {result !== null && (
                <>
                    <div className="pull-calc-result">
                        The probability of obtaining <span className="color-green">{charCopies}</span> {charCopies === 1 ? 'copy' : 'copies'} of the character and <span className="color-green">{lcCopies}</span> {lcCopies === 1 ? 'copy' : 'copies'} of the Light Cone is
                    </div>
                    <div className="pull-calc-result pull-calc-result-result color-green">{result}%</div>
                    <div className="pull-calc-result pull-calc-result-context">
                        (assuming all copies of the Character are obtained before pulling on the Light Cone)
                    </div>
                </>
            )}
        </Calculator>
    );
}

export default PullChanceCalculator;
