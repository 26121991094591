
import classnames from 'classnames';

import { RELIC_SETS } from '../../../../constants.js';
import { getRelicImgById } from '../../../../helpers.js';

import '../RelicSetSelect.scss';

function RelicSetSelectRelics({
    selectedRelicSet,
    setSelectedRelicSet,
    search
}) {
    return (
        <div className='relic-set-select-relics'>
            {
                Object.keys(RELIC_SETS)
                    .filter(e => RELIC_SETS[e].toLowerCase().includes(search.toLowerCase()))
                    .map(e => (
                        <button
                            className="relic-set-select-relic-wrapper"
                            onClick={() => { setSelectedRelicSet(RELIC_SETS[e]) }}
                            key={`button-${e}`}
                        >
                            <img
                                width="100px"
                                height="100px"
                                key={`img-${e}`}
                                alt={e}
                                src={getRelicImgById(e)}
                                className={classnames({
                                    'relic-set-select-relic': true,
                                    'relic-set-select-relic-selected': selectedRelicSet === RELIC_SETS[e]
                                })}
                            />
                        </button>
                    ))
            }
        </div>
    );
}

export default RelicSetSelectRelics;