import {
    RELIC_SET_TYPES,
    RELIC_SLOTS,
} from '../../constants.js';
import { CHARACTER_BUILD_DATA, RELIC_WEIGHTS } from '../../character_build_data.js';

export const getCharactersBySet = (relicSet, relicSetType) => {
    if (relicSetType === 'Relics') {
        return CHARACTER_BUILD_DATA.filter(e => e.relicSets.includes(relicSet));
    }

    return CHARACTER_BUILD_DATA.filter(e => e.planarSets.includes(relicSet));
}

export const generateRelicResults = (relicSetType, relicSet, relicSlot, relicMainstat, relicSubstats, allowRainbow) => {
    const filteredBySet = relicSetType === RELIC_SET_TYPES.RELICS
        ? CHARACTER_BUILD_DATA.filter(e => e.relicSets.includes(relicSet))
        : CHARACTER_BUILD_DATA.filter(e => e.planarSets.includes(relicSet));

    let filteredByMainstat = allowRainbow ? CHARACTER_BUILD_DATA : filteredBySet;
    if (relicSlot !== RELIC_SLOTS.HEAD && relicSlot !== RELIC_SLOTS.HANDS) {
        filteredByMainstat = filteredBySet.filter(e => {
            const mainstat = e.mainStats.find(ms => ms.slot === relicSlot);
            return mainstat.stats.includes(relicMainstat)
        })
    }

    const withSubstatWeight = filteredByMainstat.map(e => {
        const substats = relicSubstats.filter(rs => {
            return e.substats.some(es => es.substat === rs)
        })

        const substatsWeight = substats.reduce((acc, cs) => {
            const substat = e.substats.find(es =>
                es.substat === cs
            );

            const adjustedSubstatWeight = relicSlot === RELIC_SLOTS.HEAD || relicSlot === RELIC_SLOTS.HANDS
                ? substat.weight / 1.25
                : substat.weight

            return substat ? acc + adjustedSubstatWeight : acc;
        }, 0)

        return { ...e, substatsWeight };
    });

    const filteredBySubstats = withSubstatWeight.filter(e =>
        e.substatsWeight >= RELIC_WEIGHTS.HIGHEST
    );

    const sortedResults = filteredBySubstats.sort((a, b) => (a.substatsWeight > b.substatsWeight) ? -1 : ((b.substatsWeight > a.substatsWeight) ? 1 : 0));

    return sortedResults;
}
