import { Tooltip } from 'react-tooltip';

import {
    ELEMENTS,
    ELEMENTS_KEYS,
    ENEMY_TYPES,
    ENEMY_TYPES_KEYS,
    LEVEL_DMG_MULTIPLIER,
    BREAK_DEBUFF_TURNS
} from '../../../constants';
import { numberWithCommas, countDecimals } from '../../../helpers';
import damageCalculatorTooltips from '../damageCalculatorTooltips';

import CollapsibleSeparator from '../../../components/CollapsibleSeparator';
import CalculatorSubmit from '../../../components/Calculator/CalculatorSubmit';
import Input from '../../../components/Input';
import HelpIcon from '../../../components/HelpIcon';

import '../DamageCalculator.scss';

function BreakEffectCalculator({
    element,
    setElement,
    charLevel,
    setCharLevel,
    breakEffect,
    setBreakEffect,
    enemyLevel,
    setEnemyLevel,
    enemyType,
    setEnemyType,
    dotStackCount,
    setDotStackCount,
    enemyToughness,
    setEnemyToughness,
    enemyMaxHP,
    setEnemyMaxHP,
    resPen,
    setResPen,
    defIgnore,
    setDefIgnore,
    enemyDefReduction,
    setEnemyDefReduction,
    enemyVulnerability,
    setEnemyVulnerability,
    enemyRes,
    setEnemyRes,
    formParams
}) {
    const {
        resetResults,
        resetForm,
        onChange,
        result,
        setResult,
    } = formParams

    const resetConditionalInputs = () => {
        if (element !== ELEMENTS_KEYS.PHYSICAL) {
            setEnemyType(ENEMY_TYPES_KEYS.ELITE_BOSS);
            setEnemyMaxHP('');
        }

        if (element !== ELEMENTS_KEYS.WIND && element !== ELEMENTS_KEYS.QUANTUM) {
            setDotStackCount('');
        }
    }

    const isCalculateDisabled = () => {
        if (element === ELEMENTS_KEYS.PHYSICAL && enemyMaxHP === '') {
            return true;
        }

        if (element === ELEMENTS_KEYS.QUANTUM && dotStackCount === '') {
            return true;
        }

        return charLevel === '' ||
            breakEffect === '' ||
            resPen === '' ||
            defIgnore === '' ||
            enemyDefReduction === '' ||
            enemyLevel === '' ||
            enemyVulnerability === '' ||
            enemyRes === '' ||
            enemyToughness === '';
    }

    const calculate = () => {
        // BREAK BASE DMG
        const toughnessMultiplier = 0.5 + (enemyToughness / 120);
        let breakBaseDMGFactor = 1.5; // Wind
        if (element === ELEMENTS_KEYS.PHYSICAL || element === ELEMENTS_KEYS.FIRE) {
            breakBaseDMGFactor = 2;
        } else if (element === ELEMENTS_KEYS.ICE || element === ELEMENTS_KEYS.LIGHTNING) {
            breakBaseDMGFactor = 1;
        } else if (element === ELEMENTS_KEYS.QUANTUM || element === ELEMENTS_KEYS.IMAGINARY) {
            breakBaseDMGFactor = 0.5;
        }
        const breakBaseDMG = breakBaseDMGFactor * LEVEL_DMG_MULTIPLIER[charLevel] * toughnessMultiplier;

        // DEBUFF BASE DMG
        const levelMultiplier = LEVEL_DMG_MULTIPLIER[charLevel];
        let debuffBaseDMG = 0; // Imaginary
        if (element === ELEMENTS_KEYS.PHYSICAL) {
            const bleedScalingDamageMultiplier = enemyType === ENEMY_TYPES_KEYS.ELITE_BOSS ? 0.07 : 0.16;
            const bleedScalingDamage = bleedScalingDamageMultiplier * enemyMaxHP;
            const maxBleedDamage = 2 * levelMultiplier * toughnessMultiplier;
            debuffBaseDMG = Math.min(bleedScalingDamage, maxBleedDamage);
        } else if (element === ELEMENTS_KEYS.FIRE) {
            debuffBaseDMG = levelMultiplier;
        } else if (element === ELEMENTS_KEYS.ICE) {
            debuffBaseDMG = levelMultiplier;
        } else if (element === ELEMENTS_KEYS.LIGHTNING) {
            debuffBaseDMG = 2 * levelMultiplier;
        } else if (element === ELEMENTS_KEYS.WIND) {
            const windStackCount = enemyType === ENEMY_TYPES_KEYS.ELITE_BOSS ? 3 : 1;
            debuffBaseDMG = windStackCount * levelMultiplier;
        } else if (element === ELEMENTS_KEYS.QUANTUM) {
            debuffBaseDMG = 0.6 * dotStackCount * levelMultiplier * toughnessMultiplier;
        }

        let defReductionParam = 1 - (enemyDefReduction / 100) - (defIgnore / 100)
        defReductionParam = Math.max(0, defReductionParam);
        const defMultiplier = (charLevel + 20) / ((enemyLevel + 20) * defReductionParam + charLevel + 20);
        let resMultiplier = 1 - ((enemyRes / 100) - (resPen / 100));
        resMultiplier = Math.max(0, resMultiplier);
        const vulnerabilityMultiplier = 1 + (enemyVulnerability / 100);

        const mostOfTheFormula = (1 + (breakEffect / 100)) * defMultiplier * resMultiplier * vulnerabilityMultiplier;

        const finalBreakDMG = Math.round(breakBaseDMG * mostOfTheFormula * 0.9);
        let finalDebuffDMG = debuffBaseDMG * mostOfTheFormula * 0.9;
        if (BREAK_DEBUFF_TURNS[element] === 2) {
            finalDebuffDMG += debuffBaseDMG * mostOfTheFormula;
        }
        finalDebuffDMG = Math.round(finalDebuffDMG);

        let actionDelay = null;
        let actionDelayMultiplier = null;
        if (element === ELEMENTS_KEYS.IMAGINARY) {
            actionDelayMultiplier = 0.3;
        }
        if (element === ELEMENTS_KEYS.QUANTUM) {
            actionDelayMultiplier = 0.2;
        }
        if (actionDelayMultiplier !== null) {
            actionDelay = actionDelayMultiplier * (1 + (breakEffect / 100)) * 100;
            actionDelay = Math.round(actionDelay * 10) / 10;
        }

        setResult({
            breakDMG: finalBreakDMG,
            debuffDMG: finalDebuffDMG,
            actionDelay,
        });
    }

    return (
        <>
            <CollapsibleSeparator
                title={<><span className="color-green">Character</span> Info</>}
            >
                <div className="calculator-input-wrapper">
                    <label htmlFor='element'>
                        Element
                    </label>
                    <div className="calculator-input">
                        <select
                            className="calculator-input-field element-input-field"
                            name='element'
                            id='element'
                            value={element}
                            onChange={e => {
                                resetResults();
                                resetConditionalInputs();
                                setElement(e.target.value);
                            }}
                        >
                            {Object.keys(ELEMENTS_KEYS).map(
                                e => (
                                    <option key={`element-${e}`} value={e}>
                                        {ELEMENTS[e]}
                                    </option>
                                )
                            )}
                        </select>
                        <HelpIcon
                            id={`element-tooltip`}
                            content={damageCalculatorTooltips.element}
                        />
                        <Tooltip id={`element-tooltip`} openOnClick />
                    </div>
                </div>

                <Input
                    label='Level'
                    id='character-level'
                    type='number'
                    onChange={e => {
                        onChange(e, setCharLevel)
                    }}
                    onBlur={e => {
                        if (e.target.value < 1) {
                            onChange({ target: { value: 1 } }, setCharLevel);
                        }

                        if (e.target.value > 80) {
                            onChange({ target: { value: 80 } }, setCharLevel);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setCharLevel);
                        }
                    }}
                    placeholder='(80)'
                    value={charLevel}
                    tooltipContent={damageCalculatorTooltips.characterLevel}
                />

                <Input
                    label='Break Effect (%)'
                    id='break-effect'
                    type='number'
                    onChange={e => { onChange(e, setBreakEffect) }}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setBreakEffect);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setBreakEffect);
                        }
                    }}
                    placeholder=''
                    value={breakEffect}
                    tooltipContent={damageCalculatorTooltips.breakEffect}
                />

                <Input
                    label='RES PEN (%)'
                    id='res-pen'
                    type='number'
                    onChange={e => { onChange(e, setResPen) }}
                    placeholder='(0)'
                    value={resPen}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setResPen);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setResPen);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setResPen);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.resPen}
                />

                <Input
                    label='DEF Ignore (%)'
                    id='def-ignore'
                    type='number'
                    onChange={e => { onChange(e, setDefIgnore) }}
                    placeholder='(0)'
                    value={defIgnore}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setDefIgnore);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setDefIgnore);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setDefIgnore);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.defIgnore}
                />
            </CollapsibleSeparator>

            <CollapsibleSeparator
                title={<><span className="color-red">Enemy</span> Info</>}
            >
                <Input
                    label='Level'
                    id='enemy-level'
                    type='number'
                    onChange={e => { onChange(e, setEnemyLevel) }}
                    placeholder='(95)'
                    value={enemyLevel}
                    onBlur={e => {
                        if (e.target.value < 1) {
                            onChange({ target: { value: 1 } }, setEnemyLevel);
                        }

                        if (e.target.value > 95) {
                            onChange({ target: { value: 95 } }, setEnemyLevel);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setEnemyLevel);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyLevel}
                />

                <Input
                    label='DEF Reduction (%)'
                    id='enemy-def-reduction'
                    type='number'
                    onChange={e => { onChange(e, setEnemyDefReduction) }}
                    placeholder='(0)'
                    value={enemyDefReduction}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyDefReduction);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyDefReduction);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyDefReduction);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyDefReduction}
                />

                <Input
                    label='Vulnerability (%)'
                    id='enemy-vulnerability'
                    type='number'
                    onChange={e => { onChange(e, setEnemyVulnerability) }}
                    placeholder='(0)'
                    value={enemyVulnerability}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyVulnerability);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyVulnerability);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyVulnerability);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyVulnerability}
                />

                <Input
                    label='Element RES (%)'
                    id='enemy-res'
                    type='number'
                    onChange={e => { onChange(e, setEnemyRes) }}
                    placeholder='(0)'
                    value={enemyRes}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyRes);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyRes);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyRes);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyRes}
                />

                <Input
                    label='Max Toughness'
                    id='enemy-toughness'
                    type='number'
                    onChange={e => { onChange(e, setEnemyToughness) }}
                    placeholder=''
                    value={enemyToughness}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyToughness);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setEnemyToughness);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyToughness}
                />

                {(element === ELEMENTS_KEYS.PHYSICAL || element === ELEMENTS_KEYS.WIND) && (
                    <div className="calculator-input-wrapper">
                        <label htmlFor='enemy-type'>
                            Type
                        </label>
                        <div className="calculator-input">
                            <select
                                className="calculator-input-field element-input-field"
                                name='element'
                                id='enemy-type'
                                value={enemyType}
                                onChange={e => {
                                    resetResults();
                                    setEnemyType(e.target.value);
                                }}
                            >
                                {Object.keys(ENEMY_TYPES_KEYS).map(
                                    e => (
                                        <option key={`enemy-type-${e}`} value={e}>
                                            {ENEMY_TYPES[e]}
                                        </option>
                                    )
                                )}
                            </select>
                            <HelpIcon
                                id={`enemy-type-tooltip`}
                                content={damageCalculatorTooltips.enemyType}
                            />
                            <Tooltip id={`enemy-type-tooltip`} openOnClick />
                        </div>
                    </div>
                )}
                {element === ELEMENTS_KEYS.PHYSICAL && (
                    <Input
                        label='Max HP'
                        id='enemy-max-hp'
                        type='number'
                        onChange={e => { onChange(e, setEnemyMaxHP) }}
                        placeholder=''
                        value={enemyMaxHP}
                        inputFieldExtraClasses={{ 'calculator-input-large': true }}
                        tooltipContent={damageCalculatorTooltips.enemyMaxHP}
                        onBlur={e => {
                            if (e.target.value < 1) {
                                onChange({ target: { value: 1 } }, setEnemyMaxHP);
                            }

                            if (!Number.isInteger(e.target.value)) {
                                onChange({ target: { value: Math.floor(e.target.value) } }, setEnemyMaxHP);
                            }
                        }}
                    />
                )}

                {element === ELEMENTS_KEYS.QUANTUM && (
                    <Input
                        label='Entanglement Stacks'
                        id='stack-count'
                        type='number'
                        onChange={e => { onChange(e, setDotStackCount) }}
                        placeholder=''
                        value={dotStackCount}
                        onBlur={e => {
                            if (e.target.value < 1) {
                                onChange({ target: { value: 1 } }, setDotStackCount);
                            }

                            if (e.target.value > 5) {
                                onChange({ target: { value: 5 } }, setDotStackCount);
                            }

                            if (!Number.isInteger(e.target.value)) {
                                onChange({ target: { value: Math.floor(e.target.value) } }, setDotStackCount);
                            }
                        }}
                        tooltipContent={damageCalculatorTooltips.entanglementStackCount}
                    />
                )}
            </CollapsibleSeparator>
            <CalculatorSubmit
                calculate={calculate}
                isCalculateDisabled={isCalculateDisabled}
                resetForm={resetForm}
            />

            {result && (
                <div className='dmg-calculate-result'>
                    <span className='dmg-calculate-result-total'>
                        Total Damage:<br />
                        <span className="color-green">{numberWithCommas(result.debuffDMG + result.breakDMG)}</span>
                    </span>
                    <span className='dmg-calculate-result-total'>{result.actionDelay === null ? '' : <>Action Delay: <span className="color-green">{result.actionDelay}%</span></>}</span>
                    <span>--</span>
                    <span>Break Damage: <span className="color-green">{numberWithCommas(result.breakDMG)}</span></span>
                    <span>Total DoT Damage: <span className="color-green">{numberWithCommas(result.debuffDMG)}</span></span>
                </div>
            )}
        </>
    );
}

export default BreakEffectCalculator;