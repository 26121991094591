import ABUNDANCE_BAILU from './abundance_bailu.webp';
import ABUNDANCE_CORN from './abundance_corn.webp';
import ABUNDANCE_FRUIT from './abundance_fruit.webp';
import ABUNDANCE_HHEVENT from './abundance_hhevent.webp';
import ABUNDANCE_HUOHUO from './abundance_huohuo.webp';
import ABUNDANCE_LUOCHA from './abundance_luocha.webp';
import ABUNDANCE_MULTIPLICATION from './abundance_multiplication.webp';
import ABUNDANCE_PERFECTTIMING from './abundance_perfecttiming.webp';
import ABUNDANCE_POSTOP from './abundance_postop.webp';
import ABUNDANCE_QPQ from './abundance_qpq.webp';
import ABUNDANCE_SHARED from './abundance_shared.webp';
import ABUNDANCE_WARMTH from './abundance_warmth.webp';
import ABUNDANCE_WHATISREAL from './abundance_whatisreal.webp';

import DESTRUCTION_AEON from './destruction_aeon.webp';
import DESTRUCTION_BLADE from './destruction_blade.webp';
import DESTRUCTION_CLARA from './destruction_clara.webp';
import DESTRUCTION_DEMISE from './destruction_demise.webp';
import DESTRUCTION_DHIL from './destruction_dhil.webp';
import DESTRUCTION_FIREFLY from './destruction_firefly.webp';
import DESTRUCTION_FLAMESAFAR from './destruction_flamesafar.webp';
import DESTRUCTION_HOME from './destruction_home.webp';
import DESTRUCTION_JINGLIU from './destruction_jingliu.webp';
import DESTRUCTION_MOLES from './destruction_moles.webp';
import DESTRUCTION_NOWHERETORUN from './destruction_nowheretorun.webp';
import DESTRUCTION_PROMISE from './destruction_promise.webp';
import DESTRUCTION_SECRETVOW from './destruction_secretvow.webp';
import DESTRUCTION_SKY3 from './destruction_sky3.webp';
import DESTRUCTION_SKY4 from './destruction_sky4.webp';
import DESTRUCTION_WOOF from './destruction_woof.webp';
import DESTRUCTION_YUNLI from './destruction_yunli.webp';

import ERUDITION_ARGENTI from './erudition_argenti.webp';
import ERUDITION_BIRTH from './erudition_birth.webp';
import ERUDITION_BREAKFAST from './erudition_breakfast.webp';
import ERUDITION_CALCULUS from './erudition_calculus.webp';
import ERUDITION_CHARMONY from './erudition_charmony.webp';
import ERUDITION_CLAMOR from './erudition_clamor.webp';
import ERUDITION_COSMOS from './erudition_cosmos.webp';
import ERUDITION_DATABANK from './erudition_databank.webp';
import ERUDITION_GENIUS from './erudition_genius.webp';
import ERUDITION_HIMEKO from './erudition_himeko.webp';
import ERUDITION_JADE from './erudition_jade.webp';
import ERUDITION_JY from './erudition_jy.webp';
import ERUDITION_PASSKEY from './erudition_passkey.webp';
import ERUDITION_PEACEFULDAY from './erudition_peacefulday.webp';
import ERUDITION_SAGACITY from './erudition_sagacity.webp';

import HARMONY_BLOOM from './harmony_bloom.webp';
import HARMONY_BRONYA from './harmony_bronya.webp';
import HARMONY_CARVETHEMOON from './harmony_carvethemoon.webp';
import HARMONY_CHORUS from './harmony_chorus.webp';
import HARMONY_COGS from './harmony_cogs.webp';
import HARMONY_DDD from './harmony_ddd.webp';
import HARMONY_DREAMVILLE from './harmony_dreamville.webp';
import HARMONY_MEDIATION from './harmony_mediation.webp';
import HARMONY_MOTP from './harmony_motp.webp';
import HARMONY_PLANETARY from './harmony_planetary.webp';
import HARMONY_PNF from './harmony_pnf.webp';
import HARMONY_RM from './harmony_rm.webp';
import HARMONY_ROBIN from './harmony_robin.webp';
import HARMONY_ROBINEVENT from './harmony_robinevent.webp';
import HARMONY_SPARKLE from './harmony_sparkle.webp';

import HUNT_ADVERSARIAL from './hunt_adversarial.webp';
import HUNT_ARROW from './hunt_arrow.webp';
import HUNT_ARROWS from './hunt_arrows.webp';
import HUNT_BOOTHILL from './hunt_boothill.webp';
import HUNT_CRUISING from './hunt_cruising.webp';
import HUNT_FEIXIAO from './hunt_feixiao.webp';
import HUNT_FV from './hunt_fv.webp';
import HUNT_RATIO from './hunt_ratio.webp';
import HUNT_RETURN from './hunt_return.webp';
import HUNT_RIVER from './hunt_river.webp';
import HUNT_SEELE from './hunt_seele.webp';
import HUNT_SILENCE from './hunt_silence.webp';
import HUNT_SUBSCRIBE from './hunt_subscribe.webp';
import HUNT_SWORDPLAY from './hunt_swordplay.webp';
import HUNT_TOPAZ from './hunt_topaz.webp';
import HUNT_YANQING from './hunt_yanqing.webp';

import NIHILITY_ACHERON from './nihility_acheron.webp';
import NIHILITY_BS from './nihility_bs.webp';
import NIHILITY_CHOREO from './nihility_choreo.webp';
import NIHILITY_EOTP from './nihility_eotp.webp';
import NIHILITY_FERMATA from './nihility_fermata.webp';
import NIHILITY_GNSW from './nihility_gnsw.webp';
import NIHILITY_KAFKA from './nihility_kafka.webp';
import NIHILITY_JIAOQIU from './nihility_jiaoqiu.webp';
import NIHILITY_LOOP from './nihility_loop.webp';
import NIHILITY_PEARLS from './nihility_pearls.webp';
import NIHILITY_SHADOW from './nihility_shadow.webp';
import NIHILITY_SHOWTIME from './nihility_showtime.webp';
import NIHILITY_SOLITARY from './nihility_solitary.webp';
import NIHILITY_SW from './nihility_sw.webp';
import NIHILITY_SWEVENT from './nihility_swevent.webp';
import NIHILITY_VOID from './nihility_void.webp';
import NIHILITY_WELT from './nihility_welt.webp';
import NIHILITY_WWMA from './nihility_wwma.webp';

import PRESERVATION_AMBER from './preservation_amber.webp';
import PRESERVATION_AVENTURINE from './preservation_aventurine.webp';
import PRESERVATION_CONCERT from './preservation_concert.webp';
import PRESERVATION_D1 from './preservation_d1.webp';
import PRESERVATION_DEFENSE from './preservation_defense.webp';
import PRESERVATION_DESTINY from './preservation_destiny.webp';
import PRESERVATION_FX from './preservation_fx.webp';
import PRESERVATION_GEPARD from './preservation_gepard.webp';
import PRESERVATION_LANDAUS from './preservation_landaus.webp';
import PRESERVATION_PIONEERING from './preservation_pioneering.webp';
import PRESERVATION_THISISME from './preservation_thisisme.webp';
import PRESERVATION_TOM from './preservation_tom.webp';
import PRESERVATION_TREND from './preservation_trend.webp';
import PRESERVATION_WAW from './preservation_waw.webp';

export const IMG_LIGHTCONES = {
    ABUNDANCE_BAILU,
    ABUNDANCE_CORN,
    ABUNDANCE_FRUIT,
    ABUNDANCE_HHEVENT,
    ABUNDANCE_HUOHUO,
    ABUNDANCE_LUOCHA,
    ABUNDANCE_MULTIPLICATION,
    ABUNDANCE_PERFECTTIMING,
    ABUNDANCE_POSTOP,
    ABUNDANCE_QPQ,
    ABUNDANCE_SHARED,
    ABUNDANCE_WARMTH,
    ABUNDANCE_WHATISREAL,

    DESTRUCTION_AEON,
    DESTRUCTION_BLADE,
    DESTRUCTION_CLARA,
    DESTRUCTION_DEMISE,
    DESTRUCTION_DHIL,
    DESTRUCTION_FIREFLY,
    DESTRUCTION_FLAMESAFAR,
    DESTRUCTION_HOME,
    DESTRUCTION_JINGLIU,
    DESTRUCTION_MOLES,
    DESTRUCTION_NOWHERETORUN,
    DESTRUCTION_PROMISE,
    DESTRUCTION_SECRETVOW,
    DESTRUCTION_SKY3,
    DESTRUCTION_SKY4,
    DESTRUCTION_WOOF,
    DESTRUCTION_YUNLI,

    ERUDITION_ARGENTI,
    ERUDITION_BIRTH,
    ERUDITION_BREAKFAST,
    ERUDITION_CALCULUS,
    ERUDITION_CHARMONY,
    ERUDITION_CLAMOR,
    ERUDITION_COSMOS,
    ERUDITION_DATABANK,
    ERUDITION_GENIUS,
    ERUDITION_HIMEKO,
    ERUDITION_JADE,
    ERUDITION_JY,
    ERUDITION_PASSKEY,
    ERUDITION_PEACEFULDAY,
    ERUDITION_SAGACITY,

    HARMONY_BLOOM,
    HARMONY_BRONYA,
    HARMONY_CARVETHEMOON,
    HARMONY_CHORUS,
    HARMONY_COGS,
    HARMONY_DDD,
    HARMONY_DREAMVILLE,
    HARMONY_MEDIATION,
    HARMONY_MOTP,
    HARMONY_PLANETARY,
    HARMONY_PNF,
    HARMONY_RM,
    HARMONY_ROBIN,
    HARMONY_ROBINEVENT,
    HARMONY_SPARKLE,

    HUNT_ADVERSARIAL,
    HUNT_ARROW,
    HUNT_ARROWS,
    HUNT_BOOTHILL,
    HUNT_CRUISING,
    HUNT_FEIXIAO,
    HUNT_FV,
    HUNT_RATIO,
    HUNT_RETURN,
    HUNT_RIVER,
    HUNT_SEELE,
    HUNT_SILENCE,
    HUNT_SUBSCRIBE,
    HUNT_SWORDPLAY,
    HUNT_TOPAZ,
    HUNT_YANQING,

    NIHILITY_ACHERON,
    NIHILITY_BS,
    NIHILITY_CHOREO,
    NIHILITY_EOTP,
    NIHILITY_FERMATA,
    NIHILITY_GNSW,
    NIHILITY_KAFKA,
    NIHILITY_JIAOQIU,
    NIHILITY_LOOP,
    NIHILITY_PEARLS,
    NIHILITY_SHADOW,
    NIHILITY_SHOWTIME,
    NIHILITY_SOLITARY,
    NIHILITY_SW,
    NIHILITY_SWEVENT,
    NIHILITY_VOID,
    NIHILITY_WELT,
    NIHILITY_WWMA,

    PRESERVATION_AMBER,
    PRESERVATION_AVENTURINE,
    PRESERVATION_CONCERT,
    PRESERVATION_D1,
    PRESERVATION_DEFENSE,
    PRESERVATION_DESTINY,
    PRESERVATION_FX,
    PRESERVATION_GEPARD,
    PRESERVATION_LANDAUS,
    PRESERVATION_PIONEERING,
    PRESERVATION_THISISME,
    PRESERVATION_TOM,
    PRESERVATION_TREND,
    PRESERVATION_WAW,
}