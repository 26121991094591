import classnames from 'classnames';

import './RelicPieceSelectOption.scss';

function RelicPieceSelect({
    setSelectedRelicSlot,
    piece,
    getImgFunc,
    selectedRelicSet,
    selectedRelicSlot,
}) {
    return (
        <button
            className="relic-piece-select-option-wrapper"
            onClick={() => { setSelectedRelicSlot(piece) }}
        >
            <img
                src={getImgFunc(selectedRelicSet)}
                alt={piece}
                width='78px'
                height='78px'
                onClick={() => { setSelectedRelicSlot(piece) }}
                className={classnames({
                    'relic-piece-select-option': true,
                    'relic-piece-select-option-selected': selectedRelicSlot === piece,
                })}
            />
        </button>
    );
}

export default RelicPieceSelect;