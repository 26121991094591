import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import classnames from 'classnames';

import { CHARACTERS, PAGES } from '../../constants.js';
import { getCharacterPortaitById, getKey } from '../../helpers.js';
import { CHARACTER_BUILD_DATA } from '../../character_build_data.js';

import CharacterInfo from './CharacterInfo';

import './CharacterBuilds.scss';

function CharacterBuilds() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');

    const getCharacterInfoByCharId = charId => {
        return CHARACTER_BUILD_DATA.find(e => e.character === CHARACTERS[charId]);
    }

    return (
        <div className="app-page">
            <div className="app-page-left">
                <div className="character-search-input-wrapper">
                    <input
                        className="character-search-input"
                        type="search"
                        placeholder="Search..."
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </div>
                <div className="character-select">
                    {CHARACTER_BUILD_DATA
                        .filter(e =>
                            e.character.toLowerCase().includes(search.toLowerCase()) ||
                            e.searchTerms.some(st => st.toLowerCase().includes(search.toLowerCase())) ||
                            e.path.toLowerCase().includes(search.toLowerCase()) ||
                            e.element.toLowerCase().includes(search.toLowerCase())
                        ).map(e => (
                            <button
                                key={`char-div-${e.character}`}
                                onClick={() => { navigate(`/${PAGES.CHARACTER_BUILDS.link}/${getKey(CHARACTERS, e.character).toLowerCase()}`, { replace: true }) }}
                                className={classnames({
                                    'character-select-character': true,
                                    'character-select-character-selected': id === getKey(CHARACTERS, e.character).toLowerCase(),
                                    'character-select-character-disabled': e.disabled,
                                })}
                                {...(e.disabled ? { disabled: true } : {})}
                            >
                                <img
                                    width="100px"
                                    height="100px"
                                    key={`char-image-${e.character}`}
                                    src={getCharacterPortaitById(getKey(CHARACTERS, e.character))}
                                    alt={e.character}
                                />
                            </button>
                        ))
                    }
                </div>
            </div>
            <div className="app-page-right">
                {id && (
                    <CharacterInfo
                        data={getCharacterInfoByCharId(id.toUpperCase())}
                    />
                )}
            </div>
        </div>
    );
}

export default CharacterBuilds;
