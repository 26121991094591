import React, { useState } from 'react';
import { Outlet } from "react-router-dom";

import AppHeader from './AppHeader';
import SidePanel from './SidePanel';

import './Layout.scss';

function Layout() {
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

    return (
        <div className="App">
            {isSidePanelOpen && (
                <SidePanel
                    setIsSidePanelOpen={setIsSidePanelOpen}
                />
            )}
            <AppHeader
                setIsSidePanelOpen={setIsSidePanelOpen}
            />
            <Outlet />
        </div>
    );
}

export default Layout;