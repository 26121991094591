import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";

import Layout from './components/Layout';

import Home from './pages/Home';
import CharacterBuilds from './pages/CharacterBuilds';
import RelicSalvage from './pages/RelicSalvage';
import SetAssigner from './pages/RelicSalvage/SetAssigner.jsx';
import EHRCalculator from './pages/EHRCalculator';
import About from './pages/About';
import BronyaSpeedCalculator from './pages/BronyaSpeedCalculator';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DamageCalculator from './pages/DamageCalculator';
import SpeedDecimalChecker from './pages/SpeedDecimalChecker';
import PullChanceCalculator from './pages/PullChanceCalculator';
import LightconeAssigner from "./pages/LightconeAssigner";

import { PAGES } from './constants.js';

import './common.scss';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Navigate to={PAGES.HOME.link} />} />
                    <Route path={PAGES.HOME.link} element={<Home />} />
                    <Route path={PAGES.RELIC_SALVAGE.link} element={<RelicSalvage />} />
                    <Route path={`${PAGES.CHARACTER_BUILDS.link}`} element={<Navigate to={`/${PAGES.CHARACTER_BUILDS.link}/acheron`} />} />
                    <Route path={`${PAGES.CHARACTER_BUILDS.link}/:id`} element={<CharacterBuilds />} />
                    <Route path={PAGES.ABOUT.link} element={<About />} />
                    <Route path={PAGES.EHR_CALCULATOR.link} element={<EHRCalculator />} />
                    <Route path={PAGES.BRONYA_SPEED_CALCULATOR.link} element={<BronyaSpeedCalculator />} />
                    <Route path={PAGES.PRIVACY_POLICY.link} element={<PrivacyPolicy />} />
                    <Route path={PAGES.DAMAGE_CALCULATOR.link} element={<DamageCalculator />} />
                    <Route path={PAGES.SPEED_DECIMAL_CHECKER.link} element={<SpeedDecimalChecker />} />
                    <Route path={PAGES.PULL_CHANCE_CALCULATOR.link} element={<PullChanceCalculator />} />
                    <Route path={PAGES.LIGHTCONE_ASSIGNER.link} element={<LightconeAssigner />} />
                    <Route path={PAGES.SET_ASSIGNER.link} element={<SetAssigner />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
