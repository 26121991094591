import './HelpIcon.scss';

function HelpIcon({ id, content }) {
    return (
        <div className="help-icon">
            <button
                data-tooltip-id={id}
                data-tooltip-html={content}
                data-tooltip-place="left-start"
            >
                ?
            </button>
        </div>
    );
}

export default HelpIcon;
