import { LIGHTCONE_IDS, LIGHTCONE_TYPES } from './lightcone_helpers.js';
import { IMG_LIGHTCONES } from '../../assets/lightcones';

export const LIGHTCONES_PRESERVATION = [
    {
        name: LIGHTCONE_IDS.PRESERVATION_AMBER,
        image: IMG_LIGHTCONES.PRESERVATION_AMBER,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_AVENTURINE,
        image: IMG_LIGHTCONES.PRESERVATION_AVENTURINE,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_CONCERT,
        image: IMG_LIGHTCONES.PRESERVATION_CONCERT,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_D1,
        image: IMG_LIGHTCONES.PRESERVATION_D1,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_DEFENSE,
        image: IMG_LIGHTCONES.PRESERVATION_DEFENSE,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_DESTINY,
        image: IMG_LIGHTCONES.PRESERVATION_DESTINY,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_FX,
        image: IMG_LIGHTCONES.PRESERVATION_FX,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_LANDAUS,
        image: IMG_LIGHTCONES.PRESERVATION_LANDAUS,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_GEPARD,
        image: IMG_LIGHTCONES.PRESERVATION_GEPARD,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_STARLIGHT,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_PIONEERING,
        image: IMG_LIGHTCONES.PRESERVATION_PIONEERING,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_THISISME,
        image: IMG_LIGHTCONES.PRESERVATION_THISISME,
        rarity: 4,
        type: LIGHTCONE_TYPES.BATTLEPASS,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_TOM,
        image: IMG_LIGHTCONES.PRESERVATION_TOM,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_HERTA,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_TREND,
        image: IMG_LIGHTCONES.PRESERVATION_TREND,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.PRESERVATION_WAW,
        image: IMG_LIGHTCONES.PRESERVATION_WAW,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
];
