import damageCalculatorTooltips from '../damageCalculatorTooltips';
import { numberWithCommas, countDecimals } from '../../../helpers';

import Input from '../../../components/Input';
import CollapsibleSeparator from '../../../components/CollapsibleSeparator';
import CalculatorSubmit from '../../../components/Calculator/CalculatorSubmit';

import '../DamageCalculator.scss';

function CritCalculator({
    charLevel,
    setCharLevel,
    atk,
    setAtk,
    critRate,
    setCritRate,
    critDMG,
    setCritDMG,
    skillMultiplier,
    setSkillMultiplier,
    elementalDMG,
    setElementalDMG,
    resPen,
    setResPen,
    defIgnore,
    setDefIgnore,
    enemyDefReduction,
    setEnemyDefReduction,
    enemyVulnerability,
    setEnemyVulnerability,
    enemyRes,
    setEnemyRes,
    weaknessBroken,
    setWeaknessBroken,
    enemyLevel,
    setEnemyLevel,
    charWeaken,
    setCharWeaken,
    formParams
}) {
    const {
        resetForm,
        onChange,
        onChangeRadio,
        result,
        setResult,
    } = formParams

    const isCalculateDisabled = () => {
        return charLevel === '' ||
            enemyLevel === '' ||
            atk === '' ||
            critRate === '' ||
            critDMG === '' ||
            skillMultiplier === '' ||
            elementalDMG === '' ||
            resPen === '' ||
            defIgnore === '' ||
            enemyDefReduction === '' ||
            enemyVulnerability === '' ||
            enemyRes === '' ||
            charWeaken === '';
    };

    const calculate = () => {
        const baseDMGMultiplier = (skillMultiplier / 100) * atk;
        const critMultiplier = 1 + (critDMG / 100);
        const dmgBoostMultiplier = 1 + (elementalDMG / 100);
        let defReductionParam = 1 - (enemyDefReduction / 100) - (defIgnore / 100)
        defReductionParam = Math.max(0, defReductionParam);
        const defMultiplier = (charLevel + 20) / ((enemyLevel + 20) * defReductionParam + charLevel + 20);
        let resMultiplier = 1 - ((enemyRes / 100) - (resPen / 100));
        resMultiplier = Math.max(0, resMultiplier);
        const weakenMultiplier = 1 - (charWeaken / 100);
        const brokenMultiplier = weaknessBroken ? 1 : 0.9;
        const vulnerabilityMultiplier = 1 + (enemyVulnerability / 100);
        const dmgMitigationMultiplier = 1;

        const finalNonCritDMG = baseDMGMultiplier * dmgBoostMultiplier *
            weakenMultiplier *
            defMultiplier * resMultiplier *
            vulnerabilityMultiplier * dmgMitigationMultiplier *
            brokenMultiplier;
        const finalCritDMG = finalNonCritDMG * critMultiplier;
        const finalExpectedDMG = (finalCritDMG * (critRate / 100)) + (finalNonCritDMG * (1 - (critRate / 100)));

        setResult({
            nonCritDMG: Math.round(finalNonCritDMG),
            critDMG: Math.round(finalCritDMG),
            expectedDMG: Math.round(finalExpectedDMG),
        });
    };

    return (
        <>
            <CollapsibleSeparator
                title={<><span className="color-green">Character</span> Info</>}
            >
                <Input
                    label='Level'
                    id='character-level'
                    type='number'
                    onChange={e => {
                        onChange(e, setCharLevel)
                    }}
                    onBlur={e => {
                        if (e.target.value < 1) {
                            onChange({ target: { value: 1 } }, setCharLevel);
                        }

                        if (e.target.value > 80) {
                            onChange({ target: { value: 80 } }, setCharLevel);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setCharLevel);
                        }
                    }}
                    placeholder='(80)'
                    value={charLevel}
                    tooltipContent={damageCalculatorTooltips.characterLevel}
                />

                <Input
                    label='Multiplier Stat Value'
                    id='atk'
                    type='number'
                    onChange={e => { onChange(e, setAtk) }}
                    placeholder=''
                    value={atk}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setAtk);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setAtk);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.multiplierStat}
                />

                <Input
                    label='Crit Rate (%)'
                    id='crit-rate'
                    type='number'
                    onChange={e => { onChange(e, setCritRate) }}
                    placeholder=''
                    value={critRate}
                    onBlur={e => {
                        if (e.target.value < 5) {
                            onChange({ target: { value: 5 } }, setCritRate);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setCritRate);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setCritRate);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.critRate}
                />

                <Input
                    label='Crit DMG (%)'
                    id='crit-damage'
                    type='number'
                    onChange={e => { onChange(e, setCritDMG) }}
                    placeholder=''
                    value={critDMG}
                    onBlur={e => {
                        if (e.target.value < 50) {
                            onChange({ target: { value: 50 } }, setCritDMG);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setCritDMG);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.critDMG}
                />

                <Input
                    label='Elemental Damage (%)'
                    id='element-dmg'
                    type='number'
                    onChange={e => { onChange(e, setElementalDMG) }}
                    placeholder=''
                    value={elementalDMG}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setElementalDMG);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setElementalDMG);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.elementalDMG}
                />

                <Input
                    label='Ability Multiplier (%)'
                    id='skill-multiplier'
                    type='number'
                    onChange={e => { onChange(e, setSkillMultiplier) }}
                    placeholder=''
                    value={skillMultiplier}
                    onBlur={e => {
                        if (e.target.value < 1) {
                            onChange({ target: { value: 1 } }, setSkillMultiplier);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setSkillMultiplier);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.skillMultiplier}
                />

                <Input
                    label='RES PEN (%)'
                    id='res-pen'
                    type='number'
                    onChange={e => { onChange(e, setResPen) }}
                    placeholder='(0)'
                    value={resPen}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setResPen);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setResPen);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setResPen);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.resPen}
                />

                <Input
                    label='DEF Ignore (%)'
                    id='def-ignore'
                    type='number'
                    onChange={e => { onChange(e, setDefIgnore) }}
                    placeholder='(0)'
                    value={defIgnore}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setDefIgnore);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setDefIgnore);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setDefIgnore);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.defIgnore}
                />

                <Input
                    label='Weaken (%)'
                    id='weaken'
                    type='number'
                    onChange={e => { onChange(e, setCharWeaken) }}
                    placeholder='(0)'
                    value={charWeaken}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setCharWeaken);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setCharWeaken);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setCharWeaken);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.charWeaken}
                />
            </CollapsibleSeparator>
            <CollapsibleSeparator
                title={<><span className="color-red">Enemy</span> Info</>}
            >
                <Input
                    label='Level'
                    id='enemy-level'
                    type='number'
                    onChange={e => { onChange(e, setEnemyLevel) }}
                    placeholder='(95)'
                    value={enemyLevel}
                    onBlur={e => {
                        if (e.target.value < 1) {
                            onChange({ target: { value: 1 } }, setEnemyLevel);
                        }

                        if (e.target.value > 95) {
                            onChange({ target: { value: 95 } }, setEnemyLevel);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setEnemyLevel);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyLevel}
                />

                <Input
                    label='DEF Reduction (%)'
                    id='enemy-def-reduction'
                    type='number'
                    onChange={e => { onChange(e, setEnemyDefReduction) }}
                    placeholder='(0)'
                    value={enemyDefReduction}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyDefReduction);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyDefReduction);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyDefReduction);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyDefReduction}
                />

                <Input
                    label='Vulnerability (%)'
                    id='enemy-vulnerability'
                    type='number'
                    onChange={e => { onChange(e, setEnemyVulnerability) }}
                    placeholder='(0)'
                    value={enemyVulnerability}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyVulnerability);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyVulnerability);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyVulnerability);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyVulnerability}
                />

                <Input
                    label='RES (%)'
                    id='enemy-res'
                    type='number'
                    onChange={e => { onChange(e, setEnemyRes) }}
                    placeholder='(0)'
                    value={enemyRes}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyRes);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyRes);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyRes);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyRes}
                />

                <Input
                    hidden={false}
                    label='Weakness Broken?'
                    id='weakness-broken'
                    type='checkbox'
                    onChange={e => { onChangeRadio(e, setWeaknessBroken) }}
                    value={weaknessBroken}
                    tooltipContent={damageCalculatorTooltips.weaknessBroken}
                />
            </CollapsibleSeparator>
            <CalculatorSubmit
                calculate={calculate}
                isCalculateDisabled={isCalculateDisabled}
                resetForm={resetForm}
            />

            {result && (
                <div className='dmg-calculate-result'>
                    <span className='dmg-calculate-result-total'>Average Damage: <span className="color-green">{numberWithCommas(result.expectedDMG)}</span></span>
                    <span>--</span>
                    <span>Damage on Crit: <span className="color-green">{numberWithCommas(result.critDMG)}</span></span>
                    <span>Damage without Crit: <span className="color-green">{numberWithCommas(result.nonCritDMG)}</span></span>
                </div>
            )}
        </>
    )
}

export default CritCalculator;