import { Tooltip } from 'react-tooltip';

import damageCalculatorTooltips from '../damageCalculatorTooltips';
import {
    DOT_TYPES,
    DOT_TYPES_KEYS,
} from '../../../constants';
import { numberWithCommas, countDecimals } from '../../../helpers';

import CollapsibleSeparator from '../../../components/CollapsibleSeparator';
import CalculatorSubmit from '../../../components/Calculator/CalculatorSubmit';
import HelpIcon from '../../../components/HelpIcon';
import Input from '../../../components/Input';

import '../DamageCalculator.scss';

function DOTCalculator({
    dotType,
    setDotType,
    charLevel,
    setCharLevel,
    atk,
    setAtk,
    skillMultiplier,
    setSkillMultiplier,
    elementalDMG,
    setElementalDMG,
    resPen,
    setResPen,
    defIgnore,
    setDefIgnore,
    enemyLevel,
    setEnemyLevel,
    enemyDefReduction,
    setEnemyDefReduction,
    enemyVulnerability,
    setEnemyVulnerability,
    enemyRes,
    setEnemyRes,
    dotStackCount,
    setDotStackCount,
    weaknessBroken,
    setWeaknessBroken,
    formParams
}) {
    const {
        resetResults,
        resetForm,
        onChange,
        onChangeRadio,
        result,
        setResult,
    } = formParams

    const isCalculateDisabled = () => {
        if (dotType === DOT_TYPES_KEYS.WIND_SHEAR && dotType === '') {
            return true;
        }

        return charLevel === '' ||
            atk === '' ||
            skillMultiplier === '' ||
            elementalDMG === '' ||
            resPen === '' ||
            defIgnore === '' ||
            enemyLevel === '' ||
            enemyDefReduction === '' ||
            enemyVulnerability === '' ||
            enemyRes === '';
    };

    const resetConditionalInputs = () => {
        if (dotType !== DOT_TYPES_KEYS.WIND_SHEAR) {
            setDotStackCount('');
        }
    }

    const calculate = () => {
        let defReductionParam = 1 - (enemyDefReduction / 100) - (defIgnore / 100)
        defReductionParam = Math.max(0, defReductionParam);
        const defMultiplier = (charLevel + 20) / ((enemyLevel + 20) * defReductionParam + charLevel + 20);
        let resMultiplier = 1 - ((enemyRes / 100) - (resPen / 100));
        resMultiplier = Math.max(0, resMultiplier);
        const brokenMultiplier = weaknessBroken ? 1 : 0.9;
        const vulnerabilityMultiplier = 1 + (enemyVulnerability / 100);

        let finalDotDamage = atk *
            (skillMultiplier / 100) *
            (1 + (elementalDMG / 100)) *
            defMultiplier *
            resMultiplier *
            brokenMultiplier *
            vulnerabilityMultiplier;

        if (dotType === DOT_TYPES_KEYS.WIND_SHEAR) {
            finalDotDamage = finalDotDamage * dotStackCount;
        }

        setResult(Math.round(finalDotDamage));
    };

    return (
        <>
            <CollapsibleSeparator
                title={<><span className="color-green">Character</span> Info</>}
            >
                <div className="calculator-input-wrapper">
                    <label htmlFor='dot-type'>
                        DoT Type
                    </label>
                    <div className="calculator-input">
                        <select
                            className="calculator-input-field element-input-field"
                            name='dot-type'
                            id='dot-type'
                            value={dotType}
                            onChange={e => {
                                resetResults();
                                resetConditionalInputs();
                                setDotType(e.target.value);
                            }}
                        >
                            {Object.keys(DOT_TYPES_KEYS).filter(e => e !== 'BLEED').map(
                                e => (
                                    <option key={`dot-type-${e}`} value={e}>
                                        {DOT_TYPES[e]}
                                    </option>
                                )
                            )}
                        </select>
                        <HelpIcon
                            id={`dot-type-tooltip`}
                            content={damageCalculatorTooltips.dotType}
                        />
                        <Tooltip id={`dot-type-tooltip`} openOnClick />
                    </div>
                </div>

                <Input
                    label='Level'
                    id='character-level'
                    type='number'
                    onChange={e => {
                        onChange(e, setCharLevel)
                    }}
                    onBlur={e => {
                        if (e.target.value < 1) {
                            onChange({ target: { value: 1 } }, setCharLevel);
                        }

                        if (e.target.value > 80) {
                            onChange({ target: { value: 80 } }, setCharLevel);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setCharLevel);
                        }
                    }}
                    placeholder='(80)'
                    value={charLevel}
                    tooltipContent={damageCalculatorTooltips.characterLevel}
                />

                <Input
                    label='ATK'
                    id='atk'
                    type='number'
                    onChange={e => { onChange(e, setAtk) }}
                    placeholder=''
                    value={atk}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setAtk);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setAtk);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.atk}
                />

                <Input
                    label='Elemental Damage (%)'
                    id='element-dmg'
                    type='number'
                    onChange={e => { onChange(e, setElementalDMG) }}
                    placeholder=''
                    value={elementalDMG}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setElementalDMG);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setElementalDMG);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.elementalDMG}
                />

                <Input
                    label='Ability Multiplier (%)'
                    id='skill-multiplier'
                    type='number'
                    onChange={e => { onChange(e, setSkillMultiplier) }}
                    placeholder=''
                    value={skillMultiplier}
                    onBlur={e => {
                        if (e.target.value < 1) {
                            onChange({ target: { value: 1 } }, setSkillMultiplier);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setSkillMultiplier);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.skillMultiplier}
                />

                <Input
                    label='RES PEN (%)'
                    id='res-pen'
                    type='number'
                    onChange={e => { onChange(e, setResPen) }}
                    placeholder='(0)'
                    value={resPen}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setResPen);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setResPen);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setResPen);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.resPen}
                />

                <Input
                    label='DEF Ignore (%)'
                    id='def-ignore'
                    type='number'
                    onChange={e => { onChange(e, setDefIgnore) }}
                    placeholder='(0)'
                    value={defIgnore}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setDefIgnore);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setDefIgnore);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setDefIgnore);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.defIgnore}
                />
            </CollapsibleSeparator>
            <CollapsibleSeparator
                title={<><span className="color-red">Enemy</span> Info</>}
            >
                <Input
                    label='Level'
                    id='enemy-level'
                    type='number'
                    onChange={e => { onChange(e, setEnemyLevel) }}
                    placeholder='(95)'
                    value={enemyLevel}
                    onBlur={e => {
                        if (e.target.value < 1) {
                            onChange({ target: { value: 1 } }, setEnemyLevel);
                        }

                        if (e.target.value > 95) {
                            onChange({ target: { value: 95 } }, setEnemyLevel);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setEnemyLevel);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyLevel}
                />

                <Input
                    label='DEF Reduction (%)'
                    id='enemy-def-reduction'
                    type='number'
                    onChange={e => { onChange(e, setEnemyDefReduction) }}
                    placeholder='(0)'
                    value={enemyDefReduction}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyDefReduction);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyDefReduction);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyDefReduction);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyDefReduction}
                />

                <Input
                    label='Vulnerability (%)'
                    id='enemy-vulnerability'
                    type='number'
                    onChange={e => { onChange(e, setEnemyVulnerability) }}
                    placeholder='(0)'
                    value={enemyVulnerability}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyVulnerability);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyVulnerability);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyVulnerability);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyVulnerability}
                />

                <Input
                    label='RES (%)'
                    id='enemy-res'
                    type='number'
                    onChange={e => { onChange(e, setEnemyRes) }}
                    placeholder='(0)'
                    value={enemyRes}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyRes);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyRes);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyRes);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyRes}
                />

                <Input
                    hidden={false}
                    label='Weakness Broken?'
                    id='weakness-broken'
                    type='checkbox'
                    onChange={e => { onChangeRadio(e, setWeaknessBroken) }}
                    value={weaknessBroken}
                    tooltipContent={damageCalculatorTooltips.weaknessBroken}
                />

                {dotType === DOT_TYPES_KEYS.WIND_SHEAR && (
                    <Input
                        label='Wind Shear Stacks'
                        id='stack-count'
                        type='number'
                        onChange={e => { onChange(e, setDotStackCount) }}
                        placeholder=''
                        value={dotStackCount}
                        onBlur={e => {
                            if (e.target.value < 1) {
                                onChange({ target: { value: 1 } }, setDotStackCount);
                            }

                            if (e.target.value > 5) {
                                onChange({ target: { value: 5 } }, setDotStackCount);
                            }

                            if (!Number.isInteger(e.target.value)) {
                                onChange({ target: { value: Math.floor(e.target.value) } }, setDotStackCount);
                            }
                        }}
                        tooltipContent={damageCalculatorTooltips.windShearStackCount}
                    />
                )}
            </CollapsibleSeparator>
            <CalculatorSubmit
                calculate={calculate}
                isCalculateDisabled={isCalculateDisabled}
                resetForm={resetForm}
            />

            {result !== null && (
                <div className='dmg-calculate-result'>
                    <span className='dmg-calculate-result-total'>
                        Damage per Proc:<br />
                        <span className="color-green">{numberWithCommas(result)}</span>
                    </span>
                </div>
            )}
        </>
    )
}

export default DOTCalculator;