import Obfuscate from 'react-obfuscate';

import './PrivacyPolicy.scss';

function PrivacyPolicy() {
    return (
        <div className="privacy-policy">
            <div>
                <h1>Privacy Policy for HSR Tools</h1>
                <p>This Privacy Policy outlines how HSR Tools ("we," "us," or "our") collects, processes, and protects your information, in compliance with the General Data Protection Regulation (GDPR). By using our website and services, you consent to the practices described in this Privacy Policy.</p>
                <h2>1. Data Controller:</h2>
                <ul>
                    <li>HSR Tools</li>
                    <li><Obfuscate email="iciewinde@gmail.com" /></li>
                </ul>
                <h2>2. Purpose of Data Processing:</h2>
                <h3>a. Collection of Location and Device Data:</h3>
                <ul>
                    <li>We collect and process location and device data to enhance your experience on our website, provide personalized content, and improve our services. This includes your IP address, approximate geographical location, device information, and browsing behavior.</li>
                </ul>
                <h3>b. Google AdSense:</h3>
                <ul>
                    <li>Our website uses Google AdSense, a third-party advertising service provided by Google Inc. Google AdSense uses cookies to display personalized ads based on your browsing behavior. For more information on how Google AdSense handles your data, please refer to Google's Privacy Policy.</li>
                </ul>
                <h3>c. Google Analytics:</h3>
                <ul>
                    <li>Our website utilizes Google Analytics, a third-party web analytics service provided by Google Inc. Google Analytics uses cookies to help the website analyze how users use the site.</li>
                </ul>
                <h3>d. Donations through Stripe:</h3>
                <ul>
                    <li>When you make a donation through our website using Stripe, we collect and process the necessary information to facilitate and secure transactions. This may include your name, email address, billing information, and donation amount.</li>
                </ul>
                <h2>3. Legal Basis for Processing:</h2>
                <ul>
                    <li>We process your data based on your explicit consent for the collection of location data and the necessity of processing for the performance of a contract when processing donation information.</li>
                </ul>
                <h2>4. Data Sharing:</h2>
                <ul>
                    <li>We may share your information with third-party service providers, such as Stripe, for the purpose of processing donations securely. These providers adhere to their own privacy policies, and we recommend reviewing them.</li>
                </ul>
                <h2>5. International Data Transfers:</h2>
                <ul>
                    <li>Some of our third-party service providers may operate outside the European Economic Area (EEA). In such cases, we ensure that appropriate safeguards are in place to protect your data.</li>
                </ul>
                <h2>6. Data Security:</h2>
                <ul>
                    <li>We implement technical and organizational measures to ensure the security of your data, including encryption, access controls, and regular security assessments.</li>
                </ul>
                <h2>7. Data Retention:</h2>
                <ul>
                    <li>We retain your data only for as long as necessary to fulfill the purposes for which it was collected or as required by applicable laws.</li>
                </ul>
                <h2>8. Your Rights:</h2>
                <ul>
                    <li>You have the right to access, rectify, or erase your personal data. You can also object to or restrict processing and request data portability. To exercise these rights or for any inquiries, please contact us at <Obfuscate email="iciewinde@gmail.com" />.</li>
                </ul>
                <h2>9. Updates to this Privacy Policy:</h2>
                <ul>
                    <li>We may update this Privacy Policy to reflect changes in our practices or legal requirements. Please check this page periodically for updates.</li>
                </ul>
                <h2>10. Contact Information:</h2>
                <ul>
                    <li>If you have any questions, concerns, or requests regarding your information or this Privacy Policy, please contact us at <Obfuscate email="iciewinde@gmail.com" /></li>
                </ul>
                <p>This Privacy Policy was last updated on February 8th, 2024.</p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
