import {
    PAGES,
    CHARACTERS,
    RELIC_SLOTS,
    RELIC_STATS,
    RELIC_SETS,
    PLANAR_SETS
} from "./constants";
import { IMG_CHARACTERS } from './assets/characters';
import {
    IMG_RELICS,
    IMG_RELICS_HEAD,
    IMG_RELICS_HANDS,
    IMG_RELICS_BODY,
    IMG_RELICS_BOOTS
} from './assets/relics';
import {
    IMG_PLANARS,
    IMG_PLANARS_ORBS,
    IMG_PLANARS_ROPES
} from './assets/planars';

export const getRelicMainStatsBySlot = relicSlot => {
    if (relicSlot === RELIC_SLOTS.HEAD) {
        return [RELIC_STATS.FLAT_HP];
    }

    if (relicSlot === RELIC_SLOTS.HANDS) {
        return [RELIC_STATS.FLAT_ATK];
    }

    if (relicSlot === RELIC_SLOTS.BODY) {
        return [
            RELIC_STATS.CRIT_DMG,
            RELIC_STATS.CRIT_RATE,
            RELIC_STATS.ATK,
            RELIC_STATS.OUTGOING_HEALING,
            RELIC_STATS.EFFECT_HIT_RATE,
            RELIC_STATS.DEF,
            RELIC_STATS.HP,
        ];
    }

    if (relicSlot === RELIC_SLOTS.BOOTS) {
        return [
            RELIC_STATS.SPEED,
            RELIC_STATS.ATK,
            RELIC_STATS.DEF,
            RELIC_STATS.HP,
        ]
    }

    if (relicSlot === RELIC_SLOTS.ORB) {
        return [
            RELIC_STATS.PHYSICAL_DMG,
            RELIC_STATS.FIRE_DMG,
            RELIC_STATS.ICE_DMG,
            RELIC_STATS.LIGHTNING_DMG,
            RELIC_STATS.WIND_DMG,
            RELIC_STATS.QUANTUM_DMG,
            RELIC_STATS.IMAGINARY_DMG,
            RELIC_STATS.HP,
            RELIC_STATS.DEF,
            RELIC_STATS.ATK,
        ];
    }

    if (relicSlot === RELIC_SLOTS.ROPE) {
        return [
            RELIC_STATS.ENERGY_REGEN,
            RELIC_STATS.ATK,
            RELIC_STATS.BREAK_EFFECT,
            RELIC_STATS.HP,
            RELIC_STATS.DEF,
        ];
    }
}

export const getRelicSubstats = () => [
    RELIC_STATS.FLAT_HP,
    RELIC_STATS.FLAT_ATK,
    RELIC_STATS.FLAT_DEF,
    RELIC_STATS.HP,
    RELIC_STATS.ATK,
    RELIC_STATS.DEF,
    RELIC_STATS.CRIT_RATE,
    RELIC_STATS.CRIT_DMG,
    RELIC_STATS.EFFECT_HIT_RATE,
    RELIC_STATS.EFFECT_RES,
    RELIC_STATS.BREAK_EFFECT,
    RELIC_STATS.SPEED,
    'N/A'
];

export const getPageNameByPath = (path) => {
    const splitPath = path.split('/');
    if (splitPath.length <= 1) {
        return '';
    }

    const pageKey = Object.keys(PAGES).find(e => PAGES[e].link === splitPath[1]);
    return PAGES[pageKey]?.title ?? '';
}

export const getKey = (SET, name) => Object.keys(SET).find(k => SET[k] === name)

// Characters
export const getCharacterPortraitByName = charName => {
    return IMG_CHARACTERS[getKey(CHARACTERS, charName)];
}
export const getCharacterPortaitById = charId => IMG_CHARACTERS[charId]

// Relics
export const getRelicImgById = relicId => IMG_RELICS[relicId];
export const getRelicImgByName = relicName => {
    return IMG_RELICS[getKey(RELIC_SETS, relicName)];
}
export const getRelicHeadImgByName = relicName => {
    return IMG_RELICS_HEAD[getKey(RELIC_SETS, relicName)];
}
export const getRelicHandsImgByName = relicName => {
    return IMG_RELICS_HANDS[getKey(RELIC_SETS, relicName)];
}
export const getRelicBodyImgByName = relicName => {
    return IMG_RELICS_BODY[getKey(RELIC_SETS, relicName)];
}
export const getRelicBootsImgByName = relicName => {
    return IMG_RELICS_BOOTS[getKey(RELIC_SETS, relicName)];
}

// Planars
export const getPlanarImgById = planarId => IMG_PLANARS[planarId];
export const getPlanarImgByName = planarName => {
    return IMG_PLANARS[getKey(PLANAR_SETS, planarName)];
}
export const getPlanarOrbImgByName = planarName => {
    return IMG_PLANARS_ORBS[getKey(PLANAR_SETS, planarName)];
}
export const getPlanarRopeImgByName = planarName => {
    return IMG_PLANARS_ROPES[getKey(PLANAR_SETS, planarName)];
}

// Helpers
export const numberWithCommas = x =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const countDecimals = x => {
    if (Math.floor(x.valueOf()) === x.valueOf()) return 0;

    var str = x.toString();
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
        return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
        return str.split(".")[1].length || 0;
    }

    return str.split("-")[1] || 0;
}
