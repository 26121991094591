import ACHERON from './char_acheron.webp';
import ARGENTI from './char_argenti.webp';
import ARLAN from './char_arlan.webp';
import ASTA from './char_asta.webp';
import AVENTURINE from './char_aventurine.webp';
import BAILU from './char_bailu.webp';
import BLACK_SWAN from './char_blackswan.webp';
import BLADE from './char_blade.webp';
import BOOTHILL from './char_boothill.webp';
import BRONYA from './char_bronya.webp';
import CLARA from './char_clara.webp';
import DAN_HENG from './char_dh.webp';
import DAN_HENG_IL from './char_dhil.webp';
import DR_RATIO from './char_drratio.webp';
import FEIXIAO from './char_feixiao.webp';
import FIREFLY from './char_firefly.webp';
import FU_XUAN from './char_fuxuan.webp';
import GALLAGHER from './char_gallagher.webp';
import GEPARD from './char_gepard.webp';
import GUINAIFEN from './char_guinaifen.webp';
import HANYA from './char_hanya.webp';
import HERTA from './char_herta.webp';
import HIMEKO from './char_himeko.webp';
import HOOK from './char_hook.webp';
import HUOHUO from './char_huohuo.webp';
import JADE from './char_jade.webp';
import JIAOQIU from './char_jiaoqiu.webp';
import JING_YUAN from './char_jingyuan.webp';
import JINGLIU from './char_jingliu.webp';
import KAFKA from './char_kafka.webp';
import LINGSHA from './char_lingsha.webp';
import LUKA from './char_luka.webp';
import LUOCHA from './char_luocha.webp';
import LYNX from './char_lynx.webp';
import MARCH_7TH from './char_march7.webp';
import MARCH_7TH_IMA from './char_march7_ima.webp';
import MC_FIRE from './char_fmc.webp';
import MC_IMAGINARY from './char_hmc.webp';
import MC_PHYSICAL from './char_pmc.webp';
import MISHA from './char_misha.webp';
import MOZE from './char_moze.webp';
import NATASHA from './char_natasha.webp';
import PELA from './char_pela.webp';
import QINGQUE from './char_qingque.webp';
import RAPPA from './char_rappa.webp';
import ROBIN from './char_robin.webp';
import RUAN_MEI from './char_ruanmei.webp';
import SAMPO from './char_sampo.webp';
import SEELE from './char_seele.webp';
import SERVAL from './char_serval.webp';
import SILVER_WOLF from './char_silverwolf.webp';
import SPARKLE from './char_sparkle.webp';
import SUSHANG from './char_sushang.webp';
import TINGYUN from './char_tingyun.webp';
import TOPAZ from './char_topaz.webp';
import WELT from './char_welt.webp';
import XUEYI from './char_xueyi.webp';
import YANQING from './char_yanqing.webp';
import YUKONG from './char_yukong.webp';
import YUNLI from './char_yunli.webp';

export const IMG_CHARACTERS = {
    ACHERON,
    ARGENTI,
    ARLAN,
    ASTA,
    AVENTURINE,
    BAILU,
    BLACK_SWAN,
    BLADE,
    BOOTHILL,
    BRONYA,
    CLARA,
    DAN_HENG,
    DAN_HENG_IL,
    DR_RATIO,
    FEIXIAO,
    FIREFLY,
    FU_XUAN,
    GALLAGHER,
    GEPARD,
    GUINAIFEN,
    HANYA,
    HERTA,
    HIMEKO,
    HOOK,
    HUOHUO,
    JADE,
    JIAOQIU,
    JING_YUAN,
    JINGLIU,
    KAFKA,
    LINGSHA,
    LUKA,
    LUOCHA,
    LYNX,
    MARCH_7TH,
    MARCH_7TH_IMA,
    MC_FIRE,
    MC_IMAGINARY,
    MC_PHYSICAL,
    MISHA,
    MOZE,
    NATASHA,
    PELA,
    QINGQUE,
    RAPPA,
    ROBIN,
    RUAN_MEI,
    SAMPO,
    SEELE,
    SERVAL,
    SILVER_WOLF,
    SPARKLE,
    SUSHANG,
    TINGYUN,
    TOPAZ,
    WELT,
    XUEYI,
    YANQING,
    YUKONG,
    YUNLI,
};
