import React, { useEffect, useRef } from 'react';

import { RELIC_SLOTS, RELIC_STATS } from '../../../constants.js';
import { getRelicMainStatsBySlot, getRelicSubstats } from '../../../helpers.js';

import './RelicStatInput.scss';
import Input from '../../../components/Input';

function RelicStatInput({
    selectedRelicSet,
    selectedRelicSlot,
    selectedMainstat,
    setSelectedMainstat,
    selectedSubstats,
    setSelectedSubstats,
    allowRainbow,
    setAllowRainbow,
    onSubmit,
    validationErrors
}) {
    const mainstatDropdown = useRef(selectedMainstat);

    useEffect(() => {
        setSelectedMainstat(mainstatDropdown.current.value);
    }, [selectedRelicSlot, setSelectedMainstat]);

    const handleSubstatChange = (value, index) => {
        const newSelectedSubstats = [...selectedSubstats];
        newSelectedSubstats[index] = value;
        setSelectedSubstats(newSelectedSubstats);
    };

    return (
        <div className="relic-stat-input-wrapper">
            <div className="relic-stat-input">
                <div className="relic-stat-input-text">
                    {`${selectedRelicSet} - ${selectedRelicSlot}`}
                </div>
                <div className="relic-stat-input-inputs">
                    <div className="relic-stat-input-input">
                        <label htmlFor="relic-stat-input-mainstat">Main Stat:</label>
                        <select
                            name="relic-stat-input-mainstat"
                            id="relic-stat-input-mainstat"
                            disabled={selectedRelicSlot === RELIC_SLOTS.HEAD || selectedRelicSlot === RELIC_SLOTS.HANDS}
                            ref={mainstatDropdown}
                            onChange={e => setSelectedMainstat(e.target.value)}
                            defaultValue={getRelicMainStatsBySlot(selectedRelicSlot)[0]}
                            tabIndex="0"
                        >
                            {getRelicMainStatsBySlot(selectedRelicSlot).map(
                                e => (<option key={`mainstat_${e}`} value={e}>{e}</option>)
                            )}
                        </select>
                    </div>
                    {selectedSubstats.map((e1, i) => (
                        <div key={`relic-stat-input-input-${i}`} className="relic-stat-input-input">
                            <label
                                key={`relic-stat-input-substat${i + 1}-label`}
                                htmlFor={`relic-stat-input-substat${i + 1}`}
                            >
                                Substat {i + 1}:
                            </label>
                            <select
                                key={`relic-stat-input-substat-dropdown-${i + 1}`}
                                name={`relic-stat-input-substat${i + 1}`}
                                id={`relic-stat-input-substat${i + 1}`}
                                onChange={val => handleSubstatChange(val.target.value, i)}
                                defaultValue={RELIC_STATS.NONE}
                                tabIndex="0"
                            >
                                {getRelicSubstats().map(
                                    e2 => (<option key={`substat${i + 1}_${e2}`} value={e2}>{e2}</option>)
                                )}
                            </select>
                        </div>
                    ))}
                </div>

                <div className="checkbox-wrapper">
                    <Input
                        hidden={false}
                        label='Allow Off-Pieces?'
                        id='allow-off-pieces'
                        type='checkbox'
                        onChange={e => { setAllowRainbow(e.target.checked) }}
                        value={allowRainbow}
                        tooltipContent={`
                            <span class="tooltip">
                                Whether or not to assign this relic to characters who <span class="color-green">do not normally use this set</span>.
                            </span>
                        `}
                    />
                </div>

                <button className="submit" onClick={onSubmit}>
                    Submit
                </button>
                <div className="relic-stat-input-error">
                    {validationErrors.map(e => (<div key={e}>{e}</div>))}
                </div>
            </div>
        </div>
    );
}

export default RelicStatInput;