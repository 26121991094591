import { Link } from "react-router-dom";
import classnames from 'classnames';

import './Tool.scss';

function Tool({ link, gradient, title, description, disabled = false }) {
    return (
        <Link
            to={link}
            className="tool"
        >
            <button
                className={classnames({
                    "tool-button": true,
                    "disabled": disabled,
                })}
                style={{ backgroundImage: `radial-gradient(${gradient[0]}, ${gradient[1]})` }}
                disabled={disabled}
            >
                <div className="tool-title">{title}</div>
                <div className="tool-description">{description}</div>
            </button>
        </Link>
    );
}

export default Tool;