import { LEVEL_DMG_MULTIPLIER } from '../../../constants';
import { numberWithCommas, countDecimals } from '../../../helpers';
import damageCalculatorTooltips from '../damageCalculatorTooltips';

import CollapsibleSeparator from '../../../components/CollapsibleSeparator';
import CalculatorSubmit from '../../../components/Calculator/CalculatorSubmit';
import Input from '../../../components/Input';

import '../DamageCalculator.scss';

function SuperBreakCalculator({
    charLevel,
    setCharLevel,
    breakEffect,
    setBreakEffect,
    enemyLevel,
    setEnemyLevel,
    resPen,
    setResPen,
    defIgnore,
    setDefIgnore,
    enemyDefReduction,
    setEnemyDefReduction,
    enemyVulnerability,
    setEnemyVulnerability,
    enemyRes,
    setEnemyRes,
    toughnessDamage,
    setToughnessDamage,
    numEnemies,
    setNumEnemies,
    superBreakDMGPct,
    setSuperBreakDMGPct,
    formParams
}) {
    const {
        resetForm,
        onChange,
        result,
        setResult,
    } = formParams

    const isCalculateDisabled = () => {
        return charLevel === '' ||
            breakEffect === '' ||
            enemyLevel === '' ||
            resPen === '' ||
            defIgnore === '' ||
            enemyDefReduction === '' ||
            enemyVulnerability === '' ||
            enemyRes === '' ||
            toughnessDamage === '' ||
            numEnemies === '';
    }

    const calculate = () => {
        const levelMultiplier = LEVEL_DMG_MULTIPLIER[charLevel];
        const toughnessMultiplier = toughnessDamage / 10;
        const brokenMultiplier = 1;
        let a2Multiplier = 1;
        if (numEnemies >= 5) {
            a2Multiplier = 1.2;
        } else if (numEnemies === 4) {
            a2Multiplier = 1.3;
        } else if (numEnemies === 3) {
            a2Multiplier = 1.4;
        } else if (numEnemies === 2) {
            a2Multiplier = 1.5;
        } else if (numEnemies === 1) {
            a2Multiplier = 1.6;
        }

        let defReductionParam = 1 - (enemyDefReduction / 100) - (defIgnore / 100)
        defReductionParam = Math.max(0, defReductionParam);
        const defMultiplier = (charLevel + 20) / ((enemyLevel + 20) * defReductionParam + charLevel + 20);
        let resMultiplier = 1 - ((enemyRes / 100) - (resPen / 100));
        resMultiplier = Math.max(0, resMultiplier);
        const vulnerabilityMultiplier = 1 + (enemyVulnerability / 100);

        const result = levelMultiplier *
            toughnessMultiplier *
            (1 + (breakEffect / 100)) *
            a2Multiplier *
            defMultiplier *
            resMultiplier *
            vulnerabilityMultiplier *
            brokenMultiplier *
            (superBreakDMGPct / 100);

        setResult(Math.round(result));
    }

    return (
        <>
            <CollapsibleSeparator
                title={<><span className="color-green">Character</span> Info</>}
            >
                <Input
                    label='Level'
                    id='character-level'
                    type='number'
                    onChange={e => {
                        onChange(e, setCharLevel)
                    }}
                    onBlur={e => {
                        if (e.target.value < 1) {
                            onChange({ target: { value: 1 } }, setCharLevel);
                        }

                        if (e.target.value > 80) {
                            onChange({ target: { value: 80 } }, setCharLevel);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setCharLevel);
                        }
                    }}
                    placeholder='(80)'
                    value={charLevel}
                    tooltipContent={damageCalculatorTooltips.characterLevel}
                />

                <Input
                    label={`Attack's Toughness Damage`}
                    id='toughness-damage'
                    type='number'
                    onChange={e => {
                        onChange(e, setToughnessDamage)
                    }}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setToughnessDamage);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setToughnessDamage);
                        }
                    }}
                    placeholder='(10)'
                    value={toughnessDamage}
                    tooltipContent={damageCalculatorTooltips.toughnessDamage}
                />

                <Input
                    label='Break Effect (%)'
                    id='break-effect'
                    type='number'
                    onChange={e => { onChange(e, setBreakEffect) }}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setBreakEffect);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setBreakEffect);
                        }
                    }}
                    placeholder=''
                    value={breakEffect}
                    tooltipContent={damageCalculatorTooltips.breakEffect}
                />

                <Input
                    label='RES PEN (%)'
                    id='res-pen'
                    type='number'
                    onChange={e => { onChange(e, setResPen) }}
                    placeholder='(0)'
                    value={resPen}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setResPen);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setResPen);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setResPen);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.resPen}
                />

                <Input
                    label='DEF Ignore (%)'
                    id='def-ignore'
                    type='number'
                    onChange={e => { onChange(e, setDefIgnore) }}
                    placeholder='(0)'
                    value={defIgnore}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setDefIgnore);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setDefIgnore);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setDefIgnore);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.defIgnore}
                />

                <Input
                    label='Super Break DMG%'
                    id='super-break-dmg-percent'
                    type='number'
                    onChange={e => { onChange(e, setSuperBreakDMGPct) }}
                    placeholder='(100)'
                    value={superBreakDMGPct}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setSuperBreakDMGPct);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setSuperBreakDMGPct);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.superBreakDMGPct}
                />
            </CollapsibleSeparator>

            <CollapsibleSeparator
                title={<><span className="color-red">Enemy</span> Info</>}
            >
                <Input
                    label='Level'
                    id='enemy-level'
                    type='number'
                    onChange={e => { onChange(e, setEnemyLevel) }}
                    placeholder='(95)'
                    value={enemyLevel}
                    onBlur={e => {
                        if (e.target.value < 1) {
                            onChange({ target: { value: 1 } }, setEnemyLevel);
                        }

                        if (e.target.value > 95) {
                            onChange({ target: { value: 95 } }, setEnemyLevel);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setEnemyLevel);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyLevel}
                />

                <Input
                    label='Number of Enemies'
                    id='num-enemies'
                    type='number'
                    onChange={e => { onChange(e, setNumEnemies) }}
                    placeholder='(1)'
                    value={numEnemies}
                    onBlur={e => {
                        if (e.target.value < 1) {
                            onChange({ target: { value: 1 } }, setNumEnemies);
                        }

                        if (!Number.isInteger(e.target.value)) {
                            onChange({ target: { value: Math.floor(e.target.value) } }, setNumEnemies);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.numEnemies}
                />

                <Input
                    label='DEF Reduction (%)'
                    id='enemy-def-reduction'
                    type='number'
                    onChange={e => { onChange(e, setEnemyDefReduction) }}
                    placeholder='(0)'
                    value={enemyDefReduction}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyDefReduction);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyDefReduction);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyDefReduction);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyDefReduction}
                />

                <Input
                    label='Vulnerability (%)'
                    id='enemy-vulnerability'
                    type='number'
                    onChange={e => { onChange(e, setEnemyVulnerability) }}
                    placeholder='(0)'
                    value={enemyVulnerability}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyVulnerability);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyVulnerability);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyVulnerability);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyVulnerability}
                />

                <Input
                    label='Element RES (%)'
                    id='enemy-res'
                    type='number'
                    onChange={e => { onChange(e, setEnemyRes) }}
                    placeholder='(0)'
                    value={enemyRes}
                    onBlur={e => {
                        if (e.target.value < 0) {
                            onChange({ target: { value: 0 } }, setEnemyRes);
                        }

                        if (e.target.value > 100) {
                            onChange({ target: { value: 100 } }, setEnemyRes);
                        }

                        if (countDecimals(e.target.value) > 1) {
                            onChange({ target: { value: Math.floor(e.target.value * 10) / 10 } }, setEnemyRes);
                        }
                    }}
                    tooltipContent={damageCalculatorTooltips.enemyRes}
                />
            </CollapsibleSeparator>
            <CalculatorSubmit
                calculate={calculate}
                isCalculateDisabled={isCalculateDisabled}
                resetForm={resetForm}
            />

            {result && (
                <div className='dmg-calculate-result'>
                    <span className='dmg-calculate-result-total'>
                        Super Break Damage:<br />
                        <span className="color-green">{numberWithCommas(result)}</span>
                    </span>
                </div>
            )}
        </>
    );
}

export default SuperBreakCalculator;