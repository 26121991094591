import React, { useState } from 'react';

import classnames from 'classnames';

import Calculator from '../../components/Calculator';
import CalculatorSubmit from '../../components/Calculator/CalculatorSubmit';
import Input from '../../components/Input';

import './EHRCalculator.scss';

function EHRCalculator() {
    const [ehr, setEHR] = useState('');
    const [baseChance, setBaseChance] = useState('');
    const [enemyEffRes, setEnemyEffRes] = useState('');
    const [enemyDebuffRes, setEnemyDebuffRes] = useState('');
    const [finalChance, setFinalChance] = useState('');
    const [numHits, setNumHits] = useState('');
    const [result, setResult] = useState(null);
    const [resultSub, setResultSub] = useState(null);
    const [activeCalc, _setActiveCalc] = useState(0);

    const calculate = () => {
        const _baseChance = baseChance / 100;
        const _enemyEffRes = enemyEffRes / 100;
        const _enemyDebuffRes = enemyDebuffRes / 100;
        const _finalChance = finalChance / 100;
        const _ehr = ehr / 100;

        if (activeCalc === 0) {
            const a = _finalChance === 1 ? 0.999 : _finalChance;
            const b = _baseChance;
            // c is the EHR
            const d = _enemyEffRes;
            const e = _enemyDebuffRes;
            const f = numHits;

            const numerator = 1 - Math.pow((1 - a), (1 / f));
            const denominator = b * (1 - d) * (1 - e);

            const ehrPercentage = (numerator / denominator) - 1;
            const roundedEHRPercentage = (ehrPercentage * 100).toFixed(1);

            setResult(roundedEHRPercentage);
            setResultSub(finalChance);

            return;
        }

        const finalChanceResult = _baseChance * (1 + _ehr) * (1 - _enemyEffRes) * (1 - _enemyDebuffRes);
        const finalChanceResultWithMultihit = (1 - Math.pow((1 - finalChanceResult), numHits)) * 100;

        const roundedFinalChanceResult = finalChanceResultWithMultihit.toFixed(1);
        setResult(roundedFinalChanceResult);
        setResultSub(ehr);
    }

    const resetForm = () => {
        setEHR('');
        setBaseChance('');
        setFinalChance('');
        setEnemyDebuffRes('');
        setEnemyEffRes('');
        setNumHits('');
        setResult(null);
        setResultSub(null);
    }

    const setActiveCalc = val => {
        resetForm();
        _setActiveCalc(val);
    }

    const isCalculateDisabled = () => {
        if (activeCalc === 0) {
            return !(
                finalChance !== '' &&
                baseChance !== '' &&
                enemyEffRes !== '' &&
                enemyDebuffRes !== '' &&
                numHits !== ''
            );
        }

        return !(
            ehr !== '' &&
            baseChance !== '' &&
            enemyEffRes !== '' &&
            enemyDebuffRes !== '' &&
            numHits !== ''
        );
    }

    const onChange = (e, setFunction) => {
        setResult(null);
        setResultSub(null);

        const val = e.target.value;
        setFunction(val.length ? Number(val) : val);
    }

    const getDisplayableEHRResult = () => {
        if (result < 0) {
            return 0;
        }

        return result;
    }

    const getDisplayableActualChanceResult = () => {
        if (result > 100) {
            return 100;
        }

        if (result < 0) {
            return 0;
        }

        return result;
    }

    return (
        <Calculator
            title='Effect Hit Rate Calculator'
            description='Please choose whether to calculate the:'
            descriptionListItems={[
                'EHR Required to land a debuff x% of the time',
                `Actual Chance to land a debuff given a character's EHR`
            ]}
            buttonLabels={[
                'Calculate EHR Required',
                'Calculate Actual Chance'
            ]}
            activeCalc={activeCalc}
            setActiveCalc={setActiveCalc}
        >
            <Input
                hidden={activeCalc === 1}
                label='Desired Actual Chance (%)'
                id='desired-chance'
                type='number'
                onChange={e => { onChange(e, setFinalChance) }}
                placeholder='(100)'
                value={finalChance}
                tooltipContent={`
                    <span class="tooltip">
                        The desired <span class="color-green">Actual Chance</span> of the debuff landing.<br />
                        <br />
                        If you want the debuff to land 100% of the time, enter <span class="color-green">100</span>.
                    </span>
                `}
            />

            <Input
                hidden={activeCalc === 0}
                label='Effect Hit Rate (%)'
                id='ehr'
                type='number'
                onChange={e => { onChange(e, setEHR) }}
                placeholder=''
                value={ehr}
                tooltipContent={`
                    <span class="tooltip">
                        The character's <span class="color-green">Effect Hit Rate.</span>
                    </span>
                `}
            />

            <Input
                hidden={false}
                label='Debuff Base Chance (%)'
                id='base-chance'
                type='number'
                onChange={e => { onChange(e, setBaseChance) }}
                placeholder=''
                value={baseChance}
                tooltipContent={`
                    <span class="tooltip">
                        The <span class="color-green">Base Chance</span> of the debuff.<br />
                        <br />
                        This value is explicitly listed as<br /><span class="color-green">"Base Chance"</span> in the effect description.
                    </span>
                `}
            />

            <Input
                hidden={false}
                label={<><span className="color-red">Enemy</span> Effect RES (%)</>}
                id='enemy-eff-res'
                type='number'
                onChange={e => { onChange(e, setEnemyEffRes) }}
                placeholder='(40)'
                value={enemyEffRes}
                tooltipContent={`
                    <span class="tooltip">
                        The enemy's generic <span class="color-green">Effect RES</span> stat. This varies per enemy, but the value is always <span class="color-green">0-40%</span>.<br />
                        <br />
                        You can look up the Effect RES of an enemy by clicking <a href="https://honkai-star-rail.fandom.com/wiki/Effect_RES#Enemy_Effect_RES" target="_blank">here</a>.<br />
                        <br />
                        Many endgame enemies have 40% Effect RES, so enter <span class="color-green">40</span> if you aren't sure what the exact value is.
                    </span>
                `}
            />

            <Input
                hidden={false}
                label={<><span className="color-red">Enemy</span> Debuff RES (%)</>}
                id='enemy-debuff-res'
                type='number'
                onChange={e => { onChange(e, setEnemyDebuffRes) }}
                placeholder='(0)'
                value={enemyDebuffRes}
                tooltipContent={`
                    <span class="tooltip">
                        The enemy's <span class="color-green">resistance to the specific debuff effect</span>.<br />
                        <br />
                        You can look up the Debuff RES of an enemy by clicking <a href="https://honkai-star-rail.fandom.com/wiki/Debuff_RES#Enemy_Debuff_RES" target="_blank">here</a>.<br />
                        <br />
                        The enemy's resistance to one specific effect is most likely 0%, so enter <span class="color-green">0</span> if you aren't sure what the enemy's debuff RES to the effect is.
                    </span>
                `}
            />

            <Input
                hidden={false}
                label='Number of Chances'
                id='num-hits'
                type='number'
                onChange={e => { onChange(e, setNumHits) }}
                placeholder='(1)'
                value={numHits}
                tooltipContent={`
                    <span class="tooltip">
                        The <span class="color-green">number of chances</span> the debuff has to land.<br />
                        <br />
                        Some attacks (e.g. Welt's skill) have multiple hits, and therefore multiple chances to inflict a debuff.<br />
                        <br />
                        Most abilities do not have multiple proc chances, so this value should likely be <span class="color-green">1</span>.
                    </span>
                `}
            />

            <CalculatorSubmit
                calculate={calculate}
                isCalculateDisabled={isCalculateDisabled}
                resetForm={resetForm}
            />

            {result !== null && (
                <>
                    <div className={classnames({
                        "ehr-calculate-result": true,
                        "hidden": activeCalc === 1
                    })}>
                        <span>EHR Required for {numHits > 1 && finalChance === 100 ? '99.9' : resultSub}% Success Rate:</span>
                        <span className="ehr-calculate-result-percent color-green">{getDisplayableEHRResult()}%</span>
                    </div>
                    <div className={classnames({
                        "ehr-calculate-result": true,
                        "hidden": activeCalc === 0
                    })}>
                        <span>Actual Chance to debuff with {resultSub}% EHR:</span>
                        <span className="ehr-calculate-result-percent color-green">{getDisplayableActualChanceResult()}%</span>
                    </div>
                </>
            )}
        </Calculator>
    );
}

export default EHRCalculator;
