import { Link, useLocation } from "react-router-dom";
import classnames from 'classnames';
import { getPageNameByPath } from "../../../../helpers";

import './SidePanelNavItem.scss';

function SidePanelNavItem({
    page,
    setIsSidePanelOpen,
    isFirst,
}) {
    const location = useLocation();

    return (
        <Link to={page.link}
            className={classnames({
                "sidePanel-nav-item": true,
                "sidePanel-nav-item-selected": getPageNameByPath(location.pathname) === page.title,
                "sidePanel-nav-item-first": isFirst
            })}
            onClick={() => {
                setIsSidePanelOpen(false);
            }}
            tabIndex="0"
        >
            <div className="sidePanel-nav-item-text">
                {page.title}
            </div>
        </Link>
    );
}

export default SidePanelNavItem;
