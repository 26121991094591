import { useLocation, Link } from 'react-router-dom'

import { PAGES } from '../../../constants';
import { getPageNameByPath } from '../../../helpers';
import { ReactComponent as IconHamburger } from '../../../assets/icons/hamburger.svg';

import './AppHeader.scss';

function AppHeader({
    setIsSidePanelOpen,
}) {
    const location = useLocation();

    return (
        <div className="app-header">
            <button
                aria-label="hamburger"
                className="app-header-hamburger"
                onClick={() => setIsSidePanelOpen(true)}
            >
                <IconHamburger fill='#ddd' />
            </button>
            <div className="app-header-branding">
                <Link
                    to={PAGES.HOME.link}
                    style={{ textDecoration: 'none', color: '#ddd' }}
                >
                    <span className="app-header-logo">
                        HSR Tools
                    </span>
                </Link>
                <span className="app-header-page">
                    {`/ ${getPageNameByPath(location.pathname)}`}
                </span>
            </div>
        </div>
    );
}

export default AppHeader;
