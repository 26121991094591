import classnames from 'classnames';

import './Calculator.scss';

function Calculator({
    title,
    description,
    descriptionListItems,
    buttonLabels,
    setActiveCalc,
    activeCalc,
    children,
    extraWrapperClasses = {},
}) {
    return (
        <div className={classnames({
            "calculator-wrapper": true,
            ...extraWrapperClasses
        })}>
            <div className="calculator">
                <div className="calculator-info">
                    <h1 className="calculator-info-title">
                        {title}
                    </h1>
                    <div className="calculator-info-description">
                        <div className="calculator-info-description-sub">
                            {description}
                            <ul>
                                {descriptionListItems.map((e, i) => <li key={`calcDescriptionListItem_${i}`}>{e}</li>)}
                            </ul>
                        </div>
                    </div>
                    <div className="calculator-select">
                        {buttonLabels.map((e, i) => (
                            <button
                                key={`calculator-select-${i}`}
                                className={classnames({
                                    'calculator-select-button': true,
                                    'active': activeCalc === i
                                })}
                                onClick={() => { setActiveCalc(i); }}
                            >
                                {e}
                            </button>
                        ))}
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Calculator;