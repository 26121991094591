export const LIGHTCONE_IDS = {
    ABUNDANCE_CORN: 'Cornucopia',
    ABUNDANCE_LUOCHA: 'Echoes of the Coffin',
    ABUNDANCE_FRUIT: 'Fine Fruit',
    ABUNDANCE_HHEVENT: 'Hey, Over Here',
    ABUNDANCE_MULTIPLICATION: 'Multiplication',
    ABUNDANCE_HUOHUO: 'Night of Fright',
    ABUNDANCE_QPQ: 'Quid Pro Quo',
    ABUNDANCE_PERFECTTIMING: 'Perfect Timing',
    ABUNDANCE_POSTOP: 'Post-Op Conversation',
    ABUNDANCE_SHARED: 'Shared Feeling',
    ABUNDANCE_BAILU: 'Time Waits for No One',
    ABUNDANCE_WARMTH: 'Warmth Shortens Cold Nights',
    ABUNDANCE_WHATISREAL: 'What is Real?',

    DESTRUCTION_SECRETVOW: 'A Secret Vow',
    DESTRUCTION_DHIL: 'Brighter Than the Sun',
    DESTRUCTION_SKY3: 'Collapsing Sky',
    DESTRUCTION_YUNLI: 'Dance at Sunset',
    DESTRUCTION_FLAMESAFAR: 'Flames Afar',
    DESTRUCTION_JINGLIU: 'I Shall Be My Own Sword',
    DESTRUCTION_PROMISE: 'Indelible Promise',
    DESTRUCTION_DEMISE: 'Mutual Demise',
    DESTRUCTION_NOWHERETORUN: 'Nowhere to Run',
    DESTRUCTION_AEON: 'On the Fall of an Aeon',
    DESTRUCTION_HOME: 'Shattered Home',
    DESTRUCTION_CLARA: 'Something Irreplaceable',
    DESTRUCTION_MOLES: 'The Moles Welcome You',
    DESTRUCTION_BLADE: 'The Unreachable Side',
    DESTRUCTION_SKY4: 'Under the Blue Sky',
    DESTRUCTION_FIREFLY: 'Whereabouts Should Dreams Rest',
    DESTRUCTION_WOOF: 'Woof! Walk Time',

    ERUDITION_CHARMONY: 'After the Charmony Fall',
    ERUDITION_ARGENTI: 'An Instant Before a Gaze',
    ERUDITION_JY: 'Before Dawn',
    ERUDITION_BIRTH: 'Birth of the Self',
    ERUDITION_DATABANK: 'Data Bank',
    ERUDITION_CALCULUS: 'Eternal Calculus',
    ERUDITION_GENIUS: 'Geniuses\' Repose',
    ERUDITION_CLAMOR: 'Make the World Clamor',
    ERUDITION_HIMEKO: 'Night on the Milky Way',
    ERUDITION_PASSKEY: 'Passkey',
    ERUDITION_SAGACITY: 'Sagacity',
    ERUDITION_COSMOS: 'The Day the Cosmos Fell',
    ERUDITION_BREAKFAST: 'The Seriousness of Breakfast',
    ERUDITION_PEACEFULDAY: 'Today is Another Peaceful Day',
    ERUDITION_JADE: 'Yet Hope is Priceless',

    HARMONY_BRONYA: 'But the Battle Isn\'t Over',
    HARMONY_CARVETHEMOON: 'Carve the Moon, Weave the Clouds',
    HARMONY_CHORUS: 'Chorus',
    HARMONY_DDD: 'Dance! Dance! Dance!',
    HARMONY_DREAMVILLE: 'Dreamville Adventure',
    HARMONY_SPARKLE: 'Earthly Escapade',
    HARMONY_ROBIN: 'Flowing Nightglow',
    HARMONY_ROBINEVENT: 'For Tomorrow\'s Journey',
    HARMONY_MEDIATION: 'Mediation',
    HARMONY_MOTP: 'Memories of the Past',
    HARMONY_COGS: 'Meshing Cogs',
    HARMONY_PNF: 'Past and Future',
    HARMONY_RM: 'Past Self in the Mirror',
    HARMONY_PLANETARY: 'Planetary Rendezvous',
    HARMONY_BLOOM: 'Poised to Bloom',

    HUNT_ADVERSARIAL: 'Adversarial',
    HUNT_ARROWS: 'Arrows',
    HUNT_RATIO: 'Baptism of Pure Thought',
    HUNT_CRUISING: 'Cruising in the Stellar Sea',
    HUNT_ARROW: 'Darting Arrow',
    HUNT_FV: 'Final Victor',
    HUNT_FEIXIAO: 'I Venture Forth To Hunt',
    HUNT_SEELE: 'In the Night',
    HUNT_SILENCE: 'Only Silence Remains',
    HUNT_RETURN: 'Return to Darkness',
    HUNT_RIVER: 'River Flows in Spring',
    HUNT_BOOTHILL: 'Sailing Towards a Second Life',
    HUNT_YANQING: 'Sleep Like the Dead',
    HUNT_SUBSCRIBE: 'Subscribe for More!',
    HUNT_SWORDPLAY: 'Swordplay',
    HUNT_TOPAZ: 'Worrisome, Blissful',

    NIHILITY_ACHERON: 'Along the Passing Shore',
    NIHILITY_SWEVENT: 'Before the Tutorial Mission Starts',
    NIHILITY_CHOREO: 'Boundless Choreo',
    NIHILITY_EOTP: 'Eyes of the Prey',
    NIHILITY_FERMATA: 'Fermata',
    NIHILITY_GNSW: 'Good Night and Sleep Well',
    NIHILITY_SHADOW: 'Hidden Shadow',
    NIHILITY_WELT: 'In the Name of the World',
    NIHILITY_SW: 'Incessant Rain',
    NIHILITY_SHOWTIME: 'It\'s Showtime',
    NIHILITY_LOOP: 'Loop',
    NIHILITY_KAFKA: 'Patience is All You Need',
    NIHILITY_BS: 'Reforged Remembrance',
    NIHILITY_PEARLS: 'Resolution Shines as Pearls of Sweat',
    NIHILITY_SOLITARY: 'Solitary Healing',
    NIHILITY_JIAOQIU: 'Those Many Springs',
    NIHILITY_VOID: 'Void',
    NIHILITY_WWMA: 'We Will Meet Again',

    PRESERVATION_AMBER: 'Amber',
    PRESERVATION_CONCERT: 'Concert for Two',
    PRESERVATION_D1: 'Day One of My New Life',
    PRESERVATION_DEFENSE: 'Defense',
    PRESERVATION_DESTINY: 'Destiny\'s Threads Forewoven',
    PRESERVATION_AVENTURINE: 'Inherently Unjust Destiny',
    PRESERVATION_LANDAUS: 'Landau\'s Choice',
    PRESERVATION_GEPARD: 'Moment of Victory',
    PRESERVATION_PIONEERING: 'Pioneering',
    PRESERVATION_FX: 'She Already Shut Her Eyes',
    PRESERVATION_TOM: 'Texture of Memories',
    PRESERVATION_THISISME: 'This is Me!',
    PRESERVATION_TREND: 'Trend of the Universal Market',
    PRESERVATION_WAW: 'We are Wildfire',
};

export const LIGHTCONE_TYPES = {
    BATTLEPASS: 'Nameless Honor',
    EVENT: 'Event',
    GACHA: 'Gacha',
    SIGNATURE: 'Gacha (Limited Banner)',
    STORE_HERTA: 'Herta Shop',
    STORE_MOC: 'Forgotten Hall Shop',
    STORE_STARLIGHT: 'Starlight Exchange / Gacha',
};
