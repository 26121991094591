import BELEBOG from './planars_belebog.webp';
import CELESTIAL from './planars_celestial.webp';
import FLEET from './planars_fleet.webp';
import GLAMOTH from './planars_glamoth.webp';
import KEEL from './planars_keel.webp';
import PANCOSMIC from './planars_pancosmic.webp';
import PENACONY from './planars_penacony.webp';
import RUTILANT from './planars_rutilant.webp';
import SALSOTTO from './planars_salsotto.webp';
import SSS from './planars_sss.webp';
import TALIA from './planars_talia.webp';
import VONWACQ from './planars_vonwacq.webp';
import IZUMO from './planars_izumo.webp';
import SIGONIA from './planars_sigonia.webp';
import DURAN from './planars_duran.webp';
import KALPAGNI from './planars_kalpagni.webp';
import LUSHAKA from './planars_lushaka.webp';
import BANANA from './planars_banana.webp';

import BELEBOG_ORB from './planars_belebog_orb.webp';
import CELESTIAL_ORB from './planars_celestial_orb.webp';
import FLEET_ORB from './planars_fleet_orb.webp';
import GLAMOTH_ORB from './planars_glamoth_orb.webp';
import KEEL_ORB from './planars_keel_orb.webp';
import PANCOSMIC_ORB from './planars_pancosmic_orb.webp';
import PENACONY_ORB from './planars_penacony_orb.webp';
import RUTILANT_ORB from './planars_rutilant_orb.webp';
import SALSOTTO_ORB from './planars_salsotto_orb.webp';
import SSS_ORB from './planars_sss_orb.webp';
import TALIA_ORB from './planars_talia_orb.webp';
import VONWACQ_ORB from './planars_vonwacq_orb.webp';
import IZUMO_ORB from './planars_izumo_orb.webp';
import SIGONIA_ORB from './planars_sigonia_orb.webp';
import DURAN_ORB from './planars_duran_orb.webp';
import KALPAGNI_ORB from './planars_kalpagni_orb.webp';
import LUSHAKA_ORB from './planars_lushaka.webp';
import BANANA_ORB from './planars_banana.webp';

import BELEBOG_ROPE from './planars_belebog_rope.webp';
import CELESTIAL_ROPE from './planars_celestial_rope.webp';
import FLEET_ROPE from './planars_fleet_rope.webp';
import GLAMOTH_ROPE from './planars_glamoth_rope.webp';
import KEEL_ROPE from './planars_keel_rope.webp';
import PANCOSMIC_ROPE from './planars_pancosmic_rope.webp';
import PENACONY_ROPE from './planars_penacony_rope.webp';
import RUTILANT_ROPE from './planars_rutilant_rope.webp';
import SALSOTTO_ROPE from './planars_salsotto_rope.webp';
import SSS_ROPE from './planars_sss_rope.webp';
import TALIA_ROPE from './planars_talia_rope.webp';
import VONWACQ_ROPE from './planars_vonwacq_rope.webp';
import IZUMO_ROPE from './planars_izumo_rope.webp';
import SIGONIA_ROPE from './planars_sigonia_rope.webp';
import DURAN_ROPE from './planars_duran_rope.webp';
import KALPAGNI_ROPE from './planars_kalpagni_rope.webp';
import LUSHAKA_ROPE from './planars_lushaka.webp';
import BANANA_ROPE from './planars_banana.webp';

export const IMG_PLANARS = {
    BELEBOG,
    CELESTIAL,
    FLEET,
    GLAMOTH,
    KEEL,
    PANCOSMIC,
    PENACONY,
    RUTILANT,
    SALSOTTO,
    SSS,
    TALIA,
    VONWACQ,
    IZUMO,
    SIGONIA,
    DURAN,
    KALPAGNI,
    LUSHAKA,
    BANANA,
};

export const IMG_PLANARS_ORBS = {
    BELEBOG: BELEBOG_ORB,
    CELESTIAL: CELESTIAL_ORB,
    FLEET: FLEET_ORB,
    GLAMOTH: GLAMOTH_ORB,
    KEEL: KEEL_ORB,
    PANCOSMIC: PANCOSMIC_ORB,
    PENACONY: PENACONY_ORB,
    RUTILANT: RUTILANT_ORB,
    SALSOTTO: SALSOTTO_ORB,
    SSS: SSS_ORB,
    TALIA: TALIA_ORB,
    VONWACQ: VONWACQ_ORB,
    IZUMO: IZUMO_ORB,
    SIGONIA: SIGONIA_ORB,
    DURAN: DURAN_ORB,
    KALPAGNI: KALPAGNI_ORB,
    LUSHAKA: LUSHAKA_ORB,
    BANANA: BANANA_ORB,
}

export const IMG_PLANARS_ROPES = {
    BELEBOG: BELEBOG_ROPE,
    CELESTIAL: CELESTIAL_ROPE,
    FLEET: FLEET_ROPE,
    GLAMOTH: GLAMOTH_ROPE,
    KEEL: KEEL_ROPE,
    PANCOSMIC: PANCOSMIC_ROPE,
    PENACONY: PENACONY_ROPE,
    RUTILANT: RUTILANT_ROPE,
    SALSOTTO: SALSOTTO_ROPE,
    SSS: SSS_ROPE,
    TALIA: TALIA_ROPE,
    VONWACQ: VONWACQ_ROPE,
    IZUMO: IZUMO_ROPE,
    SIGONIA: SIGONIA_ROPE,
    DURAN: DURAN_ROPE,
    KALPAGNI: KALPAGNI_ROPE,
    LUSHAKA: LUSHAKA_ROPE,
    BANANA: BANANA_ROPE,
}