import classnames from 'classnames';

import { RELIC_STATS } from '../../../constants.js';
import { getCharacterPortraitByName } from '../../../helpers.js';

import './Results.scss';

function Results({
    results,
    selectedMainstat,
    selectedSubstats
}) {
    if (!Array.isArray(results)) {
        return null;
    }

    const getListedSubstats = characterData => {
        let listedSubstats = '';
        const sortedSubstats = characterData.substats.sort((a, b) => (a.weight > b.weight) ? -1 : ((b.weight > a.weight) ? 1 : 0));
        sortedSubstats.forEach((e, i) => {
            if (i === 0) {
                listedSubstats += e.substat;
                return;
            }

            if (e.weight === sortedSubstats[i - 1].weight) {
                listedSubstats += ` / ${e.substat}`;
                return;
            }

            if (e.weight < sortedSubstats[i - 1].weight) {
                listedSubstats += `|${e.substat}`;
            }
        })

        return listedSubstats.split('|');
    }

    const getRankByWeight = weight => {
        if (weight >= 24) {
            return 'SSS';
        }

        if (weight >= 21) {
            return 'SS';
        }

        if (weight >= 16) {
            return 'S';
        }

        if (weight >= 12) {
            return 'A';
        }

        return 'B';
    }

    const containsEveryElement = (ass, ufc) => ufc.every(e => ass.includes(e));

    const getNoResultsText = () => {
        const usefulFutureCombinations = [
            [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            [RELIC_STATS.CRIT_RATE, RELIC_STATS.SPEED],
            [RELIC_STATS.CRIT_DMG, RELIC_STATS.SPEED],
            [RELIC_STATS.ATK, RELIC_STATS.SPEED],
            [RELIC_STATS.ATK, RELIC_STATS.EFFECT_HIT_RATE],
            [RELIC_STATS.DEF, RELIC_STATS.HP, RELIC_STATS.EFFECT_RES],
            [RELIC_STATS.DEF, RELIC_STATS.HP, RELIC_STATS.SPEED],
            [RELIC_STATS.DEF, RELIC_STATS.SPEED, RELIC_STATS.EFFECT_RES],
            [RELIC_STATS.SPEED, RELIC_STATS.HP, RELIC_STATS.EFFECT_RES]
        ];

        const allSelectedStats = [...selectedSubstats, selectedMainstat];
        const relicUFC = usefulFutureCombinations.filter(e => containsEveryElement(allSelectedStats, e));

        if (!relicUFC.length) {
            return 'No results. Consider salvaging this relic.'
        }

        return (
            <div>
                <div>
                    No results. However, this relic could be useful on a future character due to these useful stat combinations:
                </div>
                <ul>
                    {relicUFC.map(e => (
                        <li key={e}>
                            {e.join(', ')}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    return (
        <div className="relic-salvage-results">
            {!results.length && (
                <div className="relic-salvage-result">
                    {getNoResultsText()}
                </div>
            )}
            {results.length > 0 && (
                <table className="relic-salvage-result-table">
                    <thead>
                        <tr className="relic-salvage-result-row-header">
                            <th className={classnames([
                                'relic-salvage-result-cell',
                            ])}>
                                Character
                            </th>
                            <th className={classnames([
                                'relic-salvage-result-cell',
                            ])}>
                                Relic Rank
                            </th>
                            <th className={classnames([
                                'relic-salvage-result-cell',
                            ])}>
                                Substat Priority
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((e, i) => (
                            <tr
                                key={`relic-salvage-result-row-${i}`}
                                className="relic-salvage-result-row"
                            >
                                <td
                                    key={`relic-salvage-result-row-${i}-cell-1`}
                                    className="relic-salvage-result-cell"
                                >
                                    <div className="relic-salvage-result-cell-character">
                                        <img
                                            width="60px"
                                            height="60px"
                                            src={getCharacterPortraitByName(e.character)}
                                            alt={e.character}
                                        />
                                        <div>{e.character}</div>
                                    </div>
                                </td>
                                <td
                                    key={`relic-salvage-result-row-${i}-cell-2`}
                                    className="relic-salvage-result-cell"
                                >
                                    <span className="relic-salvage-result-cell-relic-rank">
                                        <span className={`relic-salvage-result-cell-relic-rank-${getRankByWeight(e.substatsWeight)}`}>
                                            {getRankByWeight(e.substatsWeight)}
                                        </span>
                                    </span>
                                </td>
                                <td
                                    key={`relic-salvage-result-row-${i}-cell-3`}
                                    className={classnames(["relic-salvage-result-cell", "relic-salvage-result-cell-last-td"])}
                                >
                                    {getListedSubstats(e).map((e, i) => (
                                        <div>
                                            {`${i + 1}. ${e}`}
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default Results;
