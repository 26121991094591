import classnames from 'classnames';

import './CalculatorSubmit.scss';

function CalculatorSubmit({
    calculate,
    isCalculateDisabled,
    resetForm
}) {
    return (
        <div className="calculator-button-wrapper">
            <button className="calculator-button-reset" onClick={resetForm}>
                Reset
            </button>
            <button
                className={classnames({
                    "calculator-button-calculate": true,
                    "pointer": !isCalculateDisabled()
                })}
                onClick={calculate}
                disabled={isCalculateDisabled()}
            >
                Calculate
            </button>
        </div>
    );
}

export default CalculatorSubmit;