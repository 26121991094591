import React, { useState } from 'react';

import './CollapsibleSeparator.scss';

function CollapsibleSeparator({
    title,
    isExpanded: propIsExpanded = true,
    children,
}) {
    const [isExpanded, setIsExpanded] = useState(propIsExpanded);

    return (
        <div className="calculator-separator">
            <button
                className="calculator-separator-button"
                onClick={() => { setIsExpanded(!isExpanded) }}
            >
                <div className="calculator-separator-button-content">
                    <div>{isExpanded ? '▼' : '►'}</div>
                    <div className="calculator-separator-button-content-right">{title}</div>
                </div>
            </button>
            {isExpanded && (<>{children}</>)}
        </div>
    );
}

export default CollapsibleSeparator;