import { LIGHTCONE_IDS, LIGHTCONE_TYPES } from './lightcone_helpers.js';
import { IMG_LIGHTCONES } from '../../assets/lightcones';

export const LIGHTCONES_NIHILITY = [
    {
        name: LIGHTCONE_IDS.NIHILITY_ACHERON,
        image: IMG_LIGHTCONES.NIHILITY_ACHERON,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_BS,
        image: IMG_LIGHTCONES.NIHILITY_BS,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_CHOREO,
        image: IMG_LIGHTCONES.NIHILITY_CHOREO,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_EOTP,
        image: IMG_LIGHTCONES.NIHILITY_EOTP,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_FERMATA,
        image: IMG_LIGHTCONES.NIHILITY_FERMATA,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_GNSW,
        image: IMG_LIGHTCONES.NIHILITY_GNSW,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_JIAOQIU,
        image: IMG_LIGHTCONES.NIHILITY_JIAOQIU,
        rarity: 5,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_KAFKA,
        image: IMG_LIGHTCONES.NIHILITY_KAFKA,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_LOOP,
        image: IMG_LIGHTCONES.NIHILITY_LOOP,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_PEARLS,
        image: IMG_LIGHTCONES.NIHILITY_PEARLS,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_SHADOW,
        image: IMG_LIGHTCONES.NIHILITY_SHADOW,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_SHOWTIME,
        image: IMG_LIGHTCONES.NIHILITY_SHOWTIME,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_SOLITARY,
        image: IMG_LIGHTCONES.NIHILITY_SOLITARY,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_HERTA,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_SW,
        image: IMG_LIGHTCONES.NIHILITY_SW,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_SWEVENT,
        image: IMG_LIGHTCONES.NIHILITY_SWEVENT,
        rarity: 4,
        type: LIGHTCONE_TYPES.EVENT,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_VOID,
        image: IMG_LIGHTCONES.NIHILITY_VOID,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_WELT,
        image: IMG_LIGHTCONES.NIHILITY_WELT,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_STARLIGHT,
    },
    {
        name: LIGHTCONE_IDS.NIHILITY_WWMA,
        image: IMG_LIGHTCONES.NIHILITY_WWMA,
        rarity: 4,
        type: LIGHTCONE_TYPES.BATTLEPASS,
    },
];
