import { RELIC_SET_TYPES } from '../../../constants.js';

import RelicSetSelectRelics from './RelicSetSelectRelics';
import RelicSetSelectPlanars from './RelicSetSelectPlanars';

import './RelicSetSelect.scss';

function RelicSetSelect({
    selectedRelicSetType,
    selectedRelicSet,
    setSelectedRelicSet,
    search
}) {
    return (
        <div className="relic-set-select">
            {selectedRelicSetType === RELIC_SET_TYPES.RELICS && (
                <RelicSetSelectRelics
                    selectedRelicSet={selectedRelicSet}
                    setSelectedRelicSet={setSelectedRelicSet}
                    search={search}
                />
            )}
            {selectedRelicSetType === RELIC_SET_TYPES.PLANARS && (
                <RelicSetSelectPlanars
                    selectedRelicSet={selectedRelicSet}
                    setSelectedRelicSet={setSelectedRelicSet}
                    search={search}
                />
            )}
        </div>
    );
}

export default RelicSetSelect;
