import React, { useState } from 'react';
import classnames from 'classnames';

import { PAGES } from '../../constants';
import { getCharacterPortraitByName, getCharacterPortaitById } from '../../helpers';

import './SpeedDecimalChecker.scss';

function SpeedDecimalChecker() {
    const [input, setInput] = useState('');
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [disabled, setIsDisabled] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    const isNumeric = v => /^\d+$/.test(v);

    const handleInputChange = e => {
        setError(null);
        setInput(e.target.value);
    }

    const getStringifiedSpeed = speed => {
        let stringifiedSpeed = speed.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
        if (!stringifiedSpeed.includes('.')) {
            stringifiedSpeed += '.0';
        }

        return stringifiedSpeed;
    }

    const calculateSpeed = (attributes, additions, relics, relicSets) => {
        let ret = {
            total: '0.0',
            head: '0.0',
            hands: '0.0',
            body: '0.0',
            boots: '0.0',
            orb: '0.0',
            rope: '0.0',
            setBonus: '0.0',
        }

        const baseSpeed = attributes.find(a => a.field === "spd")?.value ?? 0;
        const addedSpeed = additions.find(a => a.field === "spd")?.value ?? 0;
        ret.total = getStringifiedSpeed(baseSpeed + addedSpeed);

        const getRelicPieceSpeed = (retPiece, index) => {
            const piece = relics.find(r => r.id.endsWith(`${index}`))
            if (!piece) {
                return;
            }

            const speedSub = piece.sub_affix.find(sa => sa.field === 'spd');
            if (!speedSub) {
                return;
            }

            ret[retPiece] = getStringifiedSpeed(speedSub.value);
        }

        getRelicPieceSpeed('head', '1');
        getRelicPieceSpeed('hands', '2');
        getRelicPieceSpeed('body', '3');
        const boots = relics.find(r => r.id.endsWith('4'));
        if (boots) {
            if (boots.main_affix.field === 'spd') {
                ret.boots = getStringifiedSpeed(boots.main_affix.value);
            } else {
                const bootSpeedSub = boots.sub_affix.find(sa => sa.field === 'spd')
                if (bootSpeedSub) {
                    ret.boots = getStringifiedSpeed(bootSpeedSub.value);
                }
            }
        }
        getRelicPieceSpeed('orb', '5');
        getRelicPieceSpeed('rope', '6');

        if (relicSets.find(rs => rs.id === '114' && rs.num === 2) || relicSets.find(rs => rs.id === '102' && rs.num === 4)) {
            ret.setBonus = getStringifiedSpeed(baseSpeed * 0.06);
        }

        return ret;
    };

    const getData = async () => {
        setIsDisabled(true);
        setData(null);
        setError(null);
        setIsSearching(true);

        fetch(`https://asia-southeast1-hsr-statistics.cloudfunctions.net/app/api/search_v2/${input}`)
            .then(r => r.json())
            .then(d => {
                setIsSearching(false);
                setIsDisabled(false);
                if (d.detail && d.detail.toLowerCase().includes('queue timeout')) {
                    setError('Server timeout; please try again');

                    return;
                }

                if (d.detail && d.detail.toLowerCase().includes('user not found')) {
                    setError('Player not found');

                    return;
                }

                if (!d.player || !d.characters) {
                    setError('Unknown error');

                    return;
                }

                const mappedCharacters = d.characters.map(c => ({
                    name: c.name,
                    id: c.id,
                    speed: calculateSpeed(c.attributes, c.additions, c.relics, c.relic_sets),
                }));

                setData({ ...d, characters: mappedCharacters });
            });
    }

    const isButtonDisabled = () => {
        return !isNumeric(input) || input.length !== 9 || disabled;
    }

    const handleKeyDown = e => {
        if (e.keyCode === 13 && !isButtonDisabled()) {
            getData();
        }
    }

    const getCharacterPortrait = (character) => {
        // MC
        if (character.id === "8001" || character.id === "8002") {
            return getCharacterPortaitById('MC_PHYSICAL');
        }

        if (character.id === "8003" || character.id === "8004") {
            return getCharacterPortaitById('MC_FIRE');
        }

        if (character.id === "8005" || character.id === "8006") {
            return getCharacterPortaitById('MC_IMAGINARY');
        }

        // March 7th
        if (character.id === "1224") {
            return getCharacterPortaitById('MARCH_7TH_IMA');
        }

        return getCharacterPortraitByName(character.name)
    }

    return (
        <div className="speed-decimal-checker-wrapper">
            <div className="speed-decimal-checker">
                <div>
                    <h1 className="speed-decimal-checker-title">
                        {PAGES.SPEED_DECIMAL_CHECKER.title}
                    </h1>
                    <div className="speed-decimal-checker-description">
                        Collects character data from your in-game <b>Trailblazer Profile</b>.<br /><br />
                        Please note that it may take a few minutes for data to be updated if changes were recently made in-game.
                    </div>
                    <div className="speed-decimal-checker-spacer-1"></div>
                    <div className="speed-decimal-checker-msb center">
                        <span style={{ color: '#6ded85' }}>Major Speed Breakpoints:</span><br />
                        <div className="speed-decimal-checker-breakpoints">
                            <div>120.1</div>
                            <div>133.4</div>
                            <div>146.7</div>
                            <div>160.1</div>
                            <div>173.4</div>
                        </div>
                    </div>
                    <div className="speed-decimal-checker-spacer-2"></div>
                    <div className="speed-decimal-checker-input-wrapper">
                        <div>UID:</div>
                        <input
                            className="speed-decimal-checker-input-field"
                            type="number"
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            disabled={isSearching}
                        />
                        <button
                            className={classnames({
                                'speed-decimal-checker-input-button': true,
                                'pointer': !isButtonDisabled(),
                            })}
                            onClick={getData}
                            disabled={isButtonDisabled()}
                        >
                            Search
                        </button>
                    </div>
                </div>
                {error && (
                    <div className="speed-decimal-checker-result-error">
                        {error}
                    </div>
                )}
                {isSearching && (
                    <div className="speed-decimal-checker-result-loading">
                        Loading...
                    </div>
                )}
                {data && (
                    <div className="speed-decimal-checker-result">
                        <div className="speed-decimal-checker-result-player">
                            {data.player.nickname}
                        </div>
                        <div>
                            {data.characters.map((d, i) => (
                                <div className="speed-decimal-checker-result-character" key={`character-${i}`}>
                                    <img
                                        width="120px"
                                        height="120px"
                                        src={getCharacterPortrait(d)}
                                        alt={d.name}
                                    />
                                    <div className="speed-decimal-checker-result-speed">
                                        <div className="speed-decimal-checker-result-speed-sub-wrap">
                                            <div className="speed-decimal-checker-result-speed-sub-sm">Head: {d.speed.head}</div>
                                            <div className="speed-decimal-checker-result-speed-sub-sm">Hands: {d.speed.hands}</div>
                                            <div className="speed-decimal-checker-result-speed-sub-sm">Body: {d.speed.body}</div>
                                            <div className="speed-decimal-checker-result-speed-sub-sm">Boots: {d.speed.boots}</div>
                                            <div className="speed-decimal-checker-result-speed-sub-sm">Orb: {d.speed.orb}</div>
                                            <div className="speed-decimal-checker-result-speed-sub-sm">Rope: {d.speed.rope}</div>
                                            <div className="speed-decimal-checker-result-speed-sub-sm">Set Bonus: {d.speed.setBonus}</div>
                                        </div>
                                        <div className="speed-decimal-checker-result-speed-sub">Total: <span className="color-green">{d.speed.total}</span></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SpeedDecimalChecker;
