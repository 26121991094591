import { Link } from "react-router-dom";

import { PAGES } from '../../constants';

import Tool from './Tool'

import './Home.scss';

function Home() {
    return (
        <div className="home-wrapper">
            <div className="home">
                <div className="home-content">
                    <h1 className="home-title">
                        Welcome to<br /><b>HSR Tools</b>!
                    </h1>
                    <h2 className="home-subtitle">
                        An unofficial collection of helpful tools for <b>Honkai: Star Rail</b>.
                    </h2>
                    <h4 className="home-subtitle-2">
                        Updated for <span class="color-green">Version 2.5</span>
                    </h4>
                    <div className="home-section">
                        <div className="home-section-title color-purple">
                            What's New?
                        </div>
                        <div className="home-section-description">
                            <ul>
                                <li>
                                    2024-09-07
                                    <ul>
                                        <li>Added <Link to={`/${PAGES.CHARACTER_BUILDS.link}/feixiao`}>Feixiao</Link> and <Link to={`/${PAGES.CHARACTER_BUILDS.link}/moze`}>Moze</Link></li>
                                        <li>Added Rappa's character portrait</li>
                                        <li>Added the new 2.5 Planar Sets (Lushaka, the Sunken Seans + The Wondrous BananAmusement Park) to the <Link to={`/${PAGES.SET_ASSIGNER.link}/`}>Set Assigner</Link></li>
                                        <li>Updated Planar Set recommendations in the <Link to={`/${PAGES.CHARACTER_BUILDS.link}/`}>Character Builds</Link> section</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    2024-08-14
                                    <ul>
                                        <li>
                                            Added <Link to={`/${PAGES.CHARACTER_BUILDS.link}/jiaoqiu`}>Jiaoqiu</Link>
                                        </li>
                                        <li>
                                            Added Those Many Springs (Jiaoqiu's Signature LC) as a recommendation for <Link to={`/${PAGES.CHARACTER_BUILDS.link}/black_swan`}>Black Swan</Link>
                                        </li>
                                        <li>
                                            Added the Poised to Bloom LC as a recommendation for <Link to={`/${PAGES.CHARACTER_BUILDS.link}/robin`}>Robin</Link>, <Link to={`/${PAGES.CHARACTER_BUILDS.link}/tingyun`}>Tingyun</Link>, and <Link to={`/${PAGES.CHARACTER_BUILDS.link}/yukong`}>Yukong</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    2024-08-04
                                    <ul>
                                        <li>
                                            Corrected Hunt March 7th's character portrait in the <Link to={`/${PAGES.SPEED_DECIMAL_CHECKER.link}`}>Speed Decimal Checker</Link>
                                        </li>
                                        <li>
                                            Updated Search Terms in the <Link to={`/${PAGES.CHARACTER_BUILDS.link}`}>Character Builds</Link> section
                                        </li>
                                        <li>
                                            Fixed an issue in the Character Builds section where incorrect sets could appear after switching characters
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="home-section">
                        <div className="home-section-title color-purple">
                            Tools
                        </div>
                        <div className="home-section-tools">
                            <Tool
                                link={`/${PAGES.CHARACTER_BUILDS.link}`}
                                gradient={['#0f8551', '#084a2d']}
                                title={PAGES.CHARACTER_BUILDS.title}
                                description='How should I build this character?'
                            />
                            <Tool
                                link={`/${PAGES.RELIC_SALVAGE.link}`}
                                gradient={['#530f7d', '#27073b']}
                                title={PAGES.RELIC_SALVAGE.title}
                                description='Which character can use this piece?'
                            />
                            <Tool
                                link={`/${PAGES.LIGHTCONE_ASSIGNER.link}`}
                                gradient={['#7f4f9e', '#5a3770']}
                                title={PAGES.LIGHTCONE_ASSIGNER.title}
                                description='Which character can use this Light Cone?'
                            />
                            <Tool
                                link={`/${PAGES.SET_ASSIGNER.link}`}
                                gradient={['#0f4c8c', '#08294d']}
                                title={PAGES.SET_ASSIGNER.title}
                                description='Which character can use this Set?'
                            />
                            <Tool
                                link={`/${PAGES.EHR_CALCULATOR.link}`}
                                gradient={['#0f8585', '#094a4a']}
                                title={PAGES.EHR_CALCULATOR.title}
                                description='What is the Actual Chance of landing a debuff?'
                            />
                            <Tool
                                link={`/${PAGES.PULL_CHANCE_CALCULATOR.link}`}
                                gradient={['#7e52f7', '#5336a3']}
                                title={PAGES.PULL_CHANCE_CALCULATOR.title}
                                description='How likely am I to get a character or lightcone within x pulls?'
                            />
                            <Tool
                                link={`/${PAGES.DAMAGE_CALCULATOR.link}`}
                                gradient={['#2f0f80', '#1c094d']}
                                title={PAGES.DAMAGE_CALCULATOR.title}
                                description='How much damage can I expect?'
                            />
                            <Tool
                                link={`/${PAGES.BRONYA_SPEED_CALCULATOR.link}`}
                                gradient={['#11a63d', '#0a6325']}
                                title={PAGES.BRONYA_SPEED_CALCULATOR.title}
                                description='What Speeds do you need on Bronya and your DPS?'
                            />
                            <Tool
                                link={`/${PAGES.SPEED_DECIMAL_CHECKER.link}`}
                                gradient={['#1368c2', '#0d4582']}
                                title={PAGES.SPEED_DECIMAL_CHECKER.title}
                                description='Exactly how much speed do your units have?'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Home;