import { LIGHTCONE_IDS, LIGHTCONE_TYPES } from './lightcone_helpers.js';
import { IMG_LIGHTCONES } from '../../assets/lightcones';

export const LIGHTCONES_ERUDITION = [
    {
        name: LIGHTCONE_IDS.ERUDITION_ARGENTI,
        image: IMG_LIGHTCONES.ERUDITION_ARGENTI,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_BIRTH,
        image: IMG_LIGHTCONES.ERUDITION_BIRTH,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_BREAKFAST,
        image: IMG_LIGHTCONES.ERUDITION_BREAKFAST,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_CALCULUS,
        image: IMG_LIGHTCONES.ERUDITION_CALCULUS,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_HERTA,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_CHARMONY,
        image: IMG_LIGHTCONES.ERUDITION_CHARMONY,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_CLAMOR,
        image: IMG_LIGHTCONES.ERUDITION_CLAMOR,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_COSMOS,
        image: IMG_LIGHTCONES.ERUDITION_COSMOS,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_DATABANK,
        image: IMG_LIGHTCONES.ERUDITION_DATABANK,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_GENIUS,
        image: IMG_LIGHTCONES.ERUDITION_GENIUS,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_HIMEKO,
        image: IMG_LIGHTCONES.ERUDITION_HIMEKO,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_STARLIGHT,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_JADE,
        image: IMG_LIGHTCONES.ERUDITION_JADE,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_JY,
        image: IMG_LIGHTCONES.ERUDITION_JY,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_PASSKEY,
        image: IMG_LIGHTCONES.ERUDITION_PASSKEY,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_PEACEFULDAY,
        image: IMG_LIGHTCONES.ERUDITION_PEACEFULDAY,
        rarity: 4,
        type: LIGHTCONE_TYPES.BATTLEPASS,
    },
    {
        name: LIGHTCONE_IDS.ERUDITION_SAGACITY,
        image: IMG_LIGHTCONES.ERUDITION_SAGACITY,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
];
