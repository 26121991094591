import { LIGHTCONE_IDS, LIGHTCONE_TYPES } from './lightcone_helpers.js';
import { IMG_LIGHTCONES } from '../../assets/lightcones';

export const LIGHTCONES_DESTRUCTION = [
    {
        name: LIGHTCONE_IDS.DESTRUCTION_AEON,
        image: IMG_LIGHTCONES.DESTRUCTION_AEON,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_HERTA,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_BLADE,
        image: IMG_LIGHTCONES.DESTRUCTION_BLADE,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_CLARA,
        image: IMG_LIGHTCONES.DESTRUCTION_CLARA,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_STARLIGHT,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_DEMISE,
        image: IMG_LIGHTCONES.DESTRUCTION_DEMISE,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_DHIL,
        image: IMG_LIGHTCONES.DESTRUCTION_DHIL,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_FIREFLY,
        image: IMG_LIGHTCONES.DESTRUCTION_FIREFLY,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_FLAMESAFAR,
        image: IMG_LIGHTCONES.DESTRUCTION_FLAMESAFAR,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_HOME,
        image: IMG_LIGHTCONES.DESTRUCTION_HOME,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_JINGLIU,
        image: IMG_LIGHTCONES.DESTRUCTION_JINGLIU,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_MOLES,
        image: IMG_LIGHTCONES.DESTRUCTION_MOLES,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_NOWHERETORUN,
        image: IMG_LIGHTCONES.DESTRUCTION_NOWHERETORUN,
        rarity: 4,
        type: LIGHTCONE_TYPES.BATTLEPASS,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_PROMISE,
        image: IMG_LIGHTCONES.DESTRUCTION_PROMISE,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_SECRETVOW,
        image: IMG_LIGHTCONES.DESTRUCTION_SECRETVOW,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_SKY3,
        image: IMG_LIGHTCONES.DESTRUCTION_SKY3,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_SKY4,
        image: IMG_LIGHTCONES.DESTRUCTION_SKY4,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_WOOF,
        image: IMG_LIGHTCONES.DESTRUCTION_WOOF,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.DESTRUCTION_YUNLI,
        image: IMG_LIGHTCONES.DESTRUCTION_YUNLI,
        rarity: 5,
        type: LIGHTCONE_TYPES.GACHA,
    },

];
