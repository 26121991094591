import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { getCharactersBySet } from './helpers.js';
import {
    getCharacterPortraitByName,
    getKey,
    getRelicImgByName,
    getPlanarImgByName
} from '../../helpers.js';
import {
    CHARACTERS,
    RELIC_SET_TYPES,
    RELIC_SETS,
    PLANAR_SETS,
    PAGES
} from '../../constants.js';

import RelicSetTypeSelect from './RelicSetTypeSelect/index.js';
import RelicSetSelect from './RelicSetSelect/index.js';

import './RelicSalvage.scss';
import './SetAssigner.scss';
import '../LightconeAssigner/LightconeAssigner.scss';

function SetAssigner() {
    const navigate = useNavigate();

    const [selectedRelicSetType, setSelectedRelicSetType] = useState(RELIC_SET_TYPES.RELICS);
    const [selectedRelicSet, setSelectedRelicSet] = useState(RELIC_SETS.ICE);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (selectedRelicSetType === RELIC_SET_TYPES.RELICS) {
            setSelectedRelicSet(RELIC_SETS.ICE);
        }

        if (selectedRelicSetType === RELIC_SET_TYPES.PLANARS) {
            setSelectedRelicSet(PLANAR_SETS.SSS);
        }

        setLoading(false);
    }, [selectedRelicSetType])

    const getResults = () => {
        if (loading) {
            return;
        }

        const charResults = getCharactersBySet(selectedRelicSet, selectedRelicSetType);
        if (!charResults.length) {
            return (<div className="lc-assigner-no-result">This Set is not recommended on any character.</div>);
        }

        return getCharactersBySet(selectedRelicSet, selectedRelicSetType).map(e => {
            const characterKey = getKey(CHARACTERS, e.character).toLowerCase();

            return (
                <button
                    className="relic-set-select-relic-wrapper"
                    style={{ cursor: 'pointer' }}
                    onClick={() => { navigate(`/${PAGES.CHARACTER_BUILDS.link}/${characterKey}`) }}
                    key={`lc-assigner-result-${characterKey}`}
                >
                    <img
                        width="100px"
                        height="100px"
                        src={getCharacterPortraitByName(e.character)}
                        alt={e.character}
                    />
                    <div className="lc-assigner-result-name-wrapper">
                        <div className="lc-assigner-result-name">{e.character}</div>
                        <div>{selectedRelicSetType === 'Relics' && !e['2pc']?.includes(selectedRelicSet) && !e['4pc']?.includes(selectedRelicSet) ? '(4pc / 2pc)' : ''}{e['2pc']?.includes(selectedRelicSet) ? '(2pc)' : ''}{e['4pc']?.includes(selectedRelicSet) ? '(4pc)' : ''}</div>
                    </div>
                </button>
            )
        })
    }

    return (
        <div className="app-page">
            <div className="app-page-left">
                <RelicSetTypeSelect
                    selectedRelicSetType={selectedRelicSetType}
                    setSelectedRelicSetType={setSelectedRelicSetType}
                    search={search}
                    setSearch={setSearch}
                    setLoading={setLoading}
                />
                <RelicSetSelect
                    selectedRelicSetType={selectedRelicSetType}
                    selectedRelicSet={selectedRelicSet}
                    setSelectedRelicSet={setSelectedRelicSet}
                    search={search}
                />
            </div>
            <div className="app-page-right">
                <h1 className="relic-salvage-title">{PAGES.SET_ASSIGNER.title}</h1>
                <h3 className="relic-salvage-subtitle">Which characters could use this set?</h3>
                {!loading && (<div className="lc-assigner-wrapper" style={{ margin: 0, padding: 0 }}>
                    <div>
                        <img
                            width="100px"
                            height="100px"
                            key={`img-${selectedRelicSet}`}
                            alt={selectedRelicSet}
                            src={
                                selectedRelicSetType === 'Relics'
                                    ? getRelicImgByName(selectedRelicSet)
                                    : getPlanarImgByName(selectedRelicSet)
                            }
                            className='relic-set-select-relic'
                            style={{ cursor: 'default' }}
                        />
                    </div>
                    <div>{selectedRelicSet}</div>
                    <div className="set-assigner-results">
                        {getResults()}
                    </div>
                </div>)}
            </div>
        </div>
    );
}

export default SetAssigner;
