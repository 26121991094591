import { LIGHTCONE_IDS, LIGHTCONE_TYPES } from './lightcone_helpers.js';
import { IMG_LIGHTCONES } from '../../assets/lightcones';

export const LIGHTCONES_ABUNDANCE = [
    {
        name: LIGHTCONE_IDS.ABUNDANCE_BAILU,
        image: IMG_LIGHTCONES.ABUNDANCE_BAILU,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_STARLIGHT,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_CORN,
        image: IMG_LIGHTCONES.ABUNDANCE_CORN,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_FRUIT,
        image: IMG_LIGHTCONES.ABUNDANCE_FRUIT,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_HHEVENT,
        image: IMG_LIGHTCONES.ABUNDANCE_HHEVENT,
        rarity: 4,
        type: LIGHTCONE_TYPES.EVENT,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_HUOHUO,
        image: IMG_LIGHTCONES.ABUNDANCE_HUOHUO,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_LUOCHA,
        image: IMG_LIGHTCONES.ABUNDANCE_LUOCHA,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_MULTIPLICATION,
        image: IMG_LIGHTCONES.ABUNDANCE_MULTIPLICATION,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_PERFECTTIMING,
        image: IMG_LIGHTCONES.ABUNDANCE_PERFECTTIMING,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_POSTOP,
        image: IMG_LIGHTCONES.ABUNDANCE_POSTOP,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_QPQ,
        image: IMG_LIGHTCONES.ABUNDANCE_QPQ,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_SHARED,
        image: IMG_LIGHTCONES.ABUNDANCE_SHARED,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_WARMTH,
        image: IMG_LIGHTCONES.ABUNDANCE_WARMTH,
        rarity: 4,
        type: LIGHTCONE_TYPES.BATTLEPASS,
    },
    {
        name: LIGHTCONE_IDS.ABUNDANCE_WHATISREAL,
        image: IMG_LIGHTCONES.ABUNDANCE_WHATISREAL,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
];
