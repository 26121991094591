import { RELIC_SET_TYPES, RELIC_SLOTS } from '../../../constants.js'
import {
    getRelicHeadImgByName,
    getRelicHandsImgByName,
    getRelicBodyImgByName,
    getRelicBootsImgByName,
    getPlanarOrbImgByName,
    getPlanarRopeImgByName
} from '../../../helpers.js';

import RelicPieceSelectOption from './RelicPieceSelectOption/index.js';

import './RelicPieceSelect.scss';

function RelicPieceSelect({
    selectedRelicSetType,
    selectedRelicSlot,
    setSelectedRelicSlot,
    selectedRelicSet
}) {
    return (
        <div className="relic-piece-select">
            {selectedRelicSetType === RELIC_SET_TYPES.RELICS && (
                <div className="relic-piece-select-options">
                    <div className="relic-piece-select-options-row">
                        <RelicPieceSelectOption
                            setSelectedRelicSlot={setSelectedRelicSlot}
                            piece={RELIC_SLOTS.HEAD}
                            getImgFunc={getRelicHeadImgByName}
                            selectedRelicSet={selectedRelicSet}
                            selectedRelicSlot={selectedRelicSlot}
                        />
                        <RelicPieceSelectOption
                            setSelectedRelicSlot={setSelectedRelicSlot}
                            piece={RELIC_SLOTS.HANDS}
                            getImgFunc={getRelicHandsImgByName}
                            selectedRelicSet={selectedRelicSet}
                            selectedRelicSlot={selectedRelicSlot}
                        />
                    </div>
                    <div className="relic-piece-select-options-row">
                        <RelicPieceSelectOption
                            setSelectedRelicSlot={setSelectedRelicSlot}
                            piece={RELIC_SLOTS.BODY}
                            getImgFunc={getRelicBodyImgByName}
                            selectedRelicSet={selectedRelicSet}
                            selectedRelicSlot={selectedRelicSlot}
                        />
                        <RelicPieceSelectOption
                            setSelectedRelicSlot={setSelectedRelicSlot}
                            piece={RELIC_SLOTS.BOOTS}
                            getImgFunc={getRelicBootsImgByName}
                            selectedRelicSet={selectedRelicSet}
                            selectedRelicSlot={selectedRelicSlot}
                        />
                    </div>
                </div>
            )}
            {selectedRelicSetType === RELIC_SET_TYPES.PLANARS && (
                <div className="relic-piece-select-options">
                    <div className="relic-piece-select-options-row">
                        <RelicPieceSelectOption
                            setSelectedRelicSlot={setSelectedRelicSlot}
                            piece={RELIC_SLOTS.ORB}
                            getImgFunc={getPlanarOrbImgByName}
                            selectedRelicSet={selectedRelicSet}
                            selectedRelicSlot={selectedRelicSlot}
                        />
                        <RelicPieceSelectOption
                            setSelectedRelicSlot={setSelectedRelicSlot}
                            piece={RELIC_SLOTS.ROPE}
                            getImgFunc={getPlanarRopeImgByName}
                            selectedRelicSet={selectedRelicSet}
                            selectedRelicSlot={selectedRelicSlot}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default RelicPieceSelect;