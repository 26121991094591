const element = `
    <span class="tooltip">
        Your character's <span class="color-green">Element</span>.<br />
        <br />
        All instances of damage caused by your character will count as damage of their Element.
    </span>
`;

const characterLevel = `
    <span class="tooltip">
        Your character's <span class="color-green">Level</span>.<br />
        <br />
        This is a value between <span class="color-green">1</span> and <span class="color-green">80</span>.
    </span>
`;

const breakEffect = `
    <span class="tooltip">
        Your character's <span class="color-green">Break Effect</span>.<br />
        <br />
        This is the <span class="color-green">sum</span> of all Break Effect-increasing modifiers, <span class="color-green">including buffs</span>.
    </span>
`;

const resPen = `
    <span class="tooltip">
        Your character's <span class="color-green">Resistance Penetration %</span>.<br />
        <br />
        This is the <span class="color-green">sum</span> of all RES PEN-increasing modifiers, which are provided by certain abilities (e.g. Ruan Mei's ultimate).<br />
        <br />
        This value is between <span class="color-green">0%</span> and <span class="color-green">100%</span>.
    </span>
`;

const defIgnore = `
    <span class="tooltip">
        Your character's <span class="color-green">Defense Ignore %</span>.<br />
        <br />
        This is the <span class="color-green">sum</span> of all DEF Ignore-increasing modifiers, which are provided by certain abilities (e.g. Black Swan's talent).<br />
        <br />
        This value is between <span class="color-green">0%</span> and <span class="color-green">100%</span>.
    </span>
`;

const enemyLevel = `
    <span class="tooltip">
        The enemy's <span class="color-green">Level</span>.<br />
        <br />
        This is a value between <span class="color-green">1</span> and <span class="color-green">95</span>.
    </span>
`;

const enemyDefReduction = `
    <span class="tooltip">
        The enemy's <span class="color-green">Defense Reduction %</span>.<br />
        <br />
        This is the <span class="color-green">sum</span> of all DEF Reduction-increasing modifiers, which are debuffs provided by certain abilities (e.g. Pela's ultimate).<br />
        <br />
        This value is between <span class="color-green">0%</span> and <span class="color-green">100%</span>.
    </span>
`;

const enemyVulnerability = `
    <span class="tooltip">
        The enemy's <span class="color-green">Vulnerability</span>, which is also referred to as <span class="color-green">DMG Taken</span>.<br />
        <br />
        This is the <span class="color-green">sum</span> of all Vulnerability-increasing  modifiers, which are debuffs provided by certain abilities (e.g. Guinaifen's Talent and Technique).
    </span>
`;

const enemyRes = `
    <span class="tooltip">
        The enemy's <span class="color-green">Elemental Resistance</span>.<br />
        <br />
        Many Elites and Bosses are resistant to a certain percentage of Element-specific damage (e.g. Cocolia is resistant to Ice).<br />
        <br />
        Enemies that are <span class="color-red">not</span> weak to the selected Element usually have a natural 20% Resistance to it.
    </span>
`;

const enemyToughness = `
    <span class="tooltip">
        The amount of Toughness Damage required to inflict <span class="color-green">Weakness Break</span> on the enemy.<br />
        <br />
        Max Toughness should always be a <span class="color-green">multiple of 30</span>.<br />
        <br />
        This value can be obtained from the <a href="https://srl.keqingmains.com/enemies" target="_blank">KQM Star Rail Library</a>.
    </span>
`;

const enemyType = `
    <span class="tooltip">
        What <span class="color-green">type</span> of enemy you are  attacking.<br />
        <br />
        Normal enemies are sometimes referred to as "Trash Mobs".
    </span>
`;

const enemyMaxHP = `
    <span class="tooltip">
        The enemy's <span class="color-green">Maximum HP</span>.
    </span>
`;

const entanglementStackCount = `
    <span class="tooltip">
        The number of <span class="color-green">Entanglement</span> stacks on the enemy.<br />
        <br />
        <span class="color-green">Entanglement</span> is a debuff inflicted by Quantum Break. Hitting an enemy while they are broken as a result of Quantum damage will add stacks of Entanglement.<br />
        <br />
        This value is between <span class="color-green">1</span> and <span class="color-green">5</span>.
    </span>
`;

const windShearStackCount = `
    <span class="tooltip">
        The number of <span class="color-green">Wind Shear</span> stacks on the enemy.<br />
        <br />
        <span class="color-green">Wind Shear</span> is a debuff inflicted by certain abilities (e.g. Sampo's talent).<br />
        <br />
        This value is between <span class="color-green">1</span> and <span class="color-green">5</span>.
    </span>
`;

const atk = `
    <span class="tooltip">
        Your character's <span class="color-green">ATK</span>.<br />
        <br />
        This is value should be provided <span class="color-green">after accounting for buffs</span>.
    </span>
`;

const elementalDMG = `
    <span class="tooltip">
        Your character's <span class="color-green">Elemental Damage</span>.<br />
        <br />
        This is the <span class="color-green">sum</span> of all Elemental Damage and DMG%-increasing modifiers (e.g. Good Night and Sleep Well's Light Cone effect).<br />
        <br />
        This is value should be provided <span class="color-green">after accounting for buffs</span>.
    </span>
`;

const skillMultiplier = `
    <span class="tooltip">
        The <span class="color-green">Scaling</span> provided by the ability being used.<br />
        <br />
        For example, a Basic Attack might say "does <span class="color-green">80%</span> of character's ATK to a single enemy".<br />
        <br />
        In that case, the Ability Multiplier would be <span class="color-green">80%</span>.
    </span>
`;

const weaknessBroken = `
    <span class="tooltip">
        Whether or not the enemy has  their <span class="color-green">Weakness Broken</span>.<br />
        <br />
        That is to say, their <span class="color-green">Toughness Bar</span> has been depleted.
    </span>
`;

const multiplierStat = `
    <span class="tooltip">
        The <span class="color-green">value of the stat(s)</span> that an ability's damage scales off of.<br />
        <br />
        For example, a Basic Attack might say "does 80% of character's <span class="color-green">ATK</span> to a single enemy". In that case, the Multiplier Stat Value would be the character's <span class="color-green">ATK stat</span>.<br />
        <br />
        Some characters (e.g. Blade) scale off multiple stats. In those cases, calculate the percentage of each value and input their <span class="color-green">sum</span>.<br />
        <br />
        This value should be provided <span class="color-green">including buffs</span>.
    </span>
`;

const critRate = `
    <span class="tooltip">
        Your character's <span class="color-green">Crit Rate</span>.<br />
        <br />
        This is a value between <span class="color-green">5%</span> and <span class="color-green">100%</span>.
    </span>
`;

const critDMG = `
    <span class="tooltip">
        Your character's <span class="color-green">Crit DMG</span>.<br />
        <br />
        This is a value has no limit, but cannot be below <span class="color-green">50%</span>.
    </span>
`;

const charWeaken = `
    <span class="tooltip">
        <span class="color-green">Weaken</span> is a debuff applied by certain enemies (e.g. Auromaton Gatekeeper).<br />
        <br />
        This value is between <span class="color-green">0%</span> and <span class="color-green">100%</span>.
    </span>
`;

const dotType = `
    <span class="tooltip">
        The <span class="color-green">type</span> of DoT debuff applied to the enemy.<br />
        <br />
        <span class="color-green">Bleed</span> is not included because it can only by applied by Weakness Breaks.<br />
        <br />
        To calculate Bleed damage, please use the <span class="color-green">Break Effect DMG Calculator</span>.
    </span>
`;

const numEnemies = `
    <span class="tooltip">
        The <span class="color-green">number of enemies</span> currently on the field.
    </span>
`;

const toughnessDamage = `
    <span class="tooltip">
        How much of the enemy's <span class="color-green">toughness bar</span> is depleted by the attack being used.<br />
        <br />
        This value should be a <span class="color-green">multiple of 5</span> and can be checked either in-game or in the <a href="https://honkai-star-rail.fandom.com/wiki/Toughness/Data" target="_blank">HSR Wiki</a>.<br />
        <br />
        This value should <span class="color-green">include Weakness Break Efficiency multipliers</span> (e.g. Ruan Mei's skill)
    </span>
`;

const superBreakDMGPct = `
    <span class="tooltip">
        The <span class="color-green">percentage of Super Break Damage</span> dealt by this instance.<br />
        <br />
        Some instances of Super Break can do more or less damage than the default amount (e.g. Firefly's A4 Trace reads "does 1 instance of <span class="color-green">35% Super Break DMG</span>").<br />
        <br />
        This value should be <span class="color-green">100%</span> unless otherwise specified by the specific instance of Super Break.
    </span>
`;

const damageCalculatorTooltips = {
    element,
    characterLevel,
    breakEffect,
    resPen,
    defIgnore,
    enemyLevel,
    enemyDefReduction,
    enemyVulnerability,
    enemyRes,
    enemyToughness,
    enemyType,
    enemyMaxHP,
    entanglementStackCount,
    atk,
    elementalDMG,
    skillMultiplier,
    weaknessBroken,
    windShearStackCount,
    multiplierStat,
    critRate,
    critDMG,
    charWeaken,
    dotType,
    numEnemies,
    toughnessDamage,
    superBreakDMGPct,
};

export default damageCalculatorTooltips;