import { LIGHTCONE_IDS, LIGHTCONE_TYPES } from './lightcone_helpers.js';
import { IMG_LIGHTCONES } from '../../assets/lightcones';

export const LIGHTCONES_HARMONY = [
    {
        name: LIGHTCONE_IDS.HARMONY_BLOOM,
        image: IMG_LIGHTCONES.HARMONY_BLOOM,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_BRONYA,
        image: IMG_LIGHTCONES.HARMONY_BRONYA,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_STARLIGHT,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_CARVETHEMOON,
        image: IMG_LIGHTCONES.HARMONY_CARVETHEMOON,
        rarity: 4,
        type: LIGHTCONE_TYPES.BATTLEPASS,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_CHORUS,
        image: IMG_LIGHTCONES.HARMONY_CHORUS,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_COGS,
        image: IMG_LIGHTCONES.HARMONY_COGS,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_DDD,
        image: IMG_LIGHTCONES.HARMONY_DDD,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_DREAMVILLE,
        image: IMG_LIGHTCONES.HARMONY_DREAMVILLE,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_MEDIATION,
        image: IMG_LIGHTCONES.HARMONY_MEDIATION,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_MOTP,
        image: IMG_LIGHTCONES.HARMONY_MOTP,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_PLANETARY,
        image: IMG_LIGHTCONES.HARMONY_PLANETARY,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_PNF,
        image: IMG_LIGHTCONES.HARMONY_PNF,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_RM,
        image: IMG_LIGHTCONES.HARMONY_RM,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_ROBIN,
        image: IMG_LIGHTCONES.HARMONY_ROBIN,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_ROBINEVENT,
        image: IMG_LIGHTCONES.HARMONY_ROBINEVENT,
        rarity: 4,
        type: LIGHTCONE_TYPES.EVENT,
    },
    {
        name: LIGHTCONE_IDS.HARMONY_SPARKLE,
        image: IMG_LIGHTCONES.HARMONY_SPARKLE,
        rarity: 5,
        type: LIGHTCONE_TYPES.GACHA,
    },
];
